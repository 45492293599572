import React from 'react';
import PropTypes from 'prop-types';
import { Container, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import Card from './Card/Card';
import Instruction from './Instruction';
import PageTitle from './PageTitle';

const NotFound = ({ linkTo, linkText }) => (
  <Container maxWidth="xs">
    <PageTitle>Page Not Found</PageTitle>
    <Card>
      <Instruction>The page you are looking for is not here.</Instruction>
      <Instruction>
        <Link component={RouterLink} to={linkTo} underline="hover">
          {linkText}
        </Link>
      </Instruction>
    </Card>
  </Container>
);

NotFound.propTypes = {
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

export default NotFound;

import React from 'react';
import { Box } from '@mui/material';
import { SubscribeButton } from '../../../../common';
import { user as UserType } from '../../../../../domain/PropTypes';
import { formatDateAsWords } from '../../../../../utils/dateTime';

const StatusTrialEnded = ({ user }) => {
  const { statusDate } = user;
  return (
    <Box>
      <Box>Your trial ended on {formatDateAsWords(statusDate)}.</Box>
      <Box mt={2}>
        <SubscribeButton>Subscribe Now</SubscribeButton>
      </Box>
    </Box>
  );
};

StatusTrialEnded.propTypes = {
  user: UserType.isRequired,
};

export default StatusTrialEnded;

import React from 'react';
import { user as UserType } from '../../../../domain/PropTypes';
import { SubscribeButton } from '../../../common';
import { formatDateAsWords } from '../../../../utils/dateTime';
import DashboardNoticeWrapper from './DashboardNoticeWrapper';

const AccountTrialEnded = ({ user }) => (
  <>
    <DashboardNoticeWrapper
      message={`Your trial ended on ${formatDateAsWords(user.statusDate)}.`}
      cta={<SubscribeButton>Subscribe Now</SubscribeButton>}
    />
  </>
);

AccountTrialEnded.propTypes = {
  user: UserType.isRequired,
};

export default AccountTrialEnded;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { List, ListItem, ListItemText, ListItemIcon, Box, Typography } from '@mui/material';
import Done from '@mui/icons-material/Done';
import Clear from '@mui/icons-material/Clear';
import Circle from '@mui/icons-material/Circle';
import { palette } from '../../../theme';

const lenRule = /^(?=.{12,})/;
const numRule = /^(?=.*[0-9])/;
const capRule = /^(?=.*[A-Z])/;
const specRule = /^(?=.*[!@#$%^&*])/;

const Rules = styled(Box)`
  margin: 0 20px 24px;

  & ul {
    margin-bottom: 10px;
    background: none;

    p {
      margin: 0 0 6px;
    }

    & .MuiListItemIcon-root {
      min-width: 20px;
      font-size: 14px;
    }

    & .MuiListItemIcon-root .MuiSvgIcon-root {
      font-size: 14px;
    }

    & .default .MuiListItemIcon-root .MuiSvgIcon-root {
      font-size: 10px;
    }

    & .MuiListItem-dense,
    .MuiListItemText-dense {
      padding: 0px;
      margin: 0px;
    }
  }

  .error * {
    color: ${palette.error.main};
  }

  .success .MuiSvgIcon-root {
    color: ${palette.success.main};
  }
`;

const isMatching = (password, passwordConfirmation) =>
  password &&
  password.length > 0 &&
  passwordConfirmation &&
  passwordConfirmation.length > 0 &&
  password === passwordConfirmation;

const evaluatePassword = (password, passwordConfirmation) => {
  const initial = password.length === 0 && passwordConfirmation.length === 0;

  return {
    len: initial ? undefined : lenRule.test(password),
    num: initial ? undefined : numRule.test(password),
    cap: initial ? undefined : capRule.test(password),
    spec: initial ? undefined : specRule.test(password),
    conf: initial ? undefined : isMatching(password, passwordConfirmation),
  };
};

const getRuleIcon = (result) => (result === undefined ? <Circle /> : result ? <Done /> : <Clear />);

const RuleListItem = ({ text, result }) => (
  <ListItem className={result === undefined ? 'default' : result ? 'success' : 'error'}>
    <ListItemIcon>{getRuleIcon(result)}</ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);

const hasError = (results) => Object.values(results).some((result) => result === false);

const PasswordNoteCard = ({ password, passwordConfirmation }) => {
  const rules = useMemo(
    () => evaluatePassword(password, passwordConfirmation),
    [password, passwordConfirmation]
  );

  return (
    <Rules>
      <Typography variant="p" className={`label${hasError(rules) ? ' error' : ''}`}>
        Passwords must meet the following requirements:
      </Typography>
      <List dense disablePadding>
        <RuleListItem result={rules.len} text="At least 12 characters long." />
        <RuleListItem result={rules.num} text="At least one number." />
        <RuleListItem result={rules.cap} text="At least one capital letter." />
        <RuleListItem result={rules.spec} text="At least one special character." />
        <RuleListItem result={rules.conf} text="Password and password confirmation match." />
      </List>
    </Rules>
  );
};

RuleListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  result: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
};

PasswordNoteCard.propTypes = {
  password: PropTypes.string.isRequired,
  passwordConfirmation: PropTypes.string.isRequired,
};

export default PasswordNoteCard;

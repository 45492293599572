import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const Icon = ({ sx, style }) => (
  <SvgIcon sx={sx} style={style} viewBox="0 0 24 24">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M12.58,14.88l5.86-2.59v3.05c0,.21-.12,.39-.31,.48l-5.55,2.46c-.37,.16-.78,.16-1.15,0l-5.56-2.46c-.19-.08-.31-.27-.31-.48v-3.04l5.87,2.59c.37,.16,.78,.16,1.15,0ZM3.85,11.84l1.55-1.03,6.03,2.66c.37,.16,.78,.16,1.15,0l9.81-4.33c.24-.1,.24-.44,0-.54L12.58,4.25c-.37-.16-.78-.16-1.15,0L1.62,8.59c-.24,.1-.24,.44,0,.54l1.91,.85-1.02,.68c-.24,.16-.38,.43-.38,.71v2.58l-.86,2.57v3.43l1.72-1.69,1.72,1.69v-3.43l-.86-2.58v-2.11Z"
    />
  </SvgIcon>
);

Icon.propTypes = {
  sx: PropTypes.shape({
    fontSize: PropTypes.string,
    color: PropTypes.string,
  }),
  style: PropTypes.shape({}),
};

Icon.defaultProps = {
  sx: {},
  style: {},
};

export default Icon;

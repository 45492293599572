const sendConversionGA = ({ cbSubscriptionId, price }) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      'event': 'subscribed',
      'value': price,
      'currency': 'USD',
      'transaction_id': cbSubscriptionId,
    });
  }
};

export default sendConversionGA;

/* eslint-disable no-lone-blocks */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton, Button, Alert } from '@mui/material';
import { useQuery } from 'react-query';
import styled from '@emotion/styled';

import config from '../../../config';
import { getContactMethods } from '../../../api';
import { Instruction, When, Link } from '../../common';
import SMSContactMethod from './SMSContactMethod';
import TermsDialog from './SMSContactMethod/TermsDialog';
import AddNumberForm from './SMSContactMethod/AddNumberForm';
import { CONTACTMETHODS } from '../../../constants/queryData';
import Links from '../../../constants/links';

const LoadingView = ({ maxNumbersAllowed }) => (
  <>
    {Array.from({ length: maxNumbersAllowed }).map((v, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Box key={index}>
        <Skeleton />
      </Box>
    ))}
  </>
);

const SMSContactViews = ({ methods }) => (
  <>
    {methods.map((method) => (
      <SMSContactMethod key={method.id} method={method} />
    ))}
  </>
);

const AddSMSBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const SMSContactMethods = ({ smsLimit = 2 }) => {
  const {
    data: methods = [],
    isLoading,
    isError,
    isSuccess,
  } = useQuery(CONTACTMETHODS, getContactMethods, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    staleTime: Infinity,
  });
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [agreedToTerms, agreeWithTerms] = useState(false);

  return (
    <>
      <When it={isLoading}>
        <LoadingView maxNumbersAllowed={smsLimit} />
      </When>
      <When it={isError}>
        <Box>
          <Instruction>Unable to load phone numbers at this time.</Instruction>
        </Box>
      </When>
      <When it={config.DISABLE_SMS_SIGNUP}>
        <Alert
          severity="warning"
          variant="outlined"
          style={{ margin: '.6rem 0', borderRadius: '4px' }}
        >
          SMS notifications are currently unavailable. <br />
          <Link
            target="_blank"
            href={Links.SETUP_TEXT_MESSAGE_LEARN_URL}
            title="Learn More"
            underline="always"
          >
            Learn more.
          </Link>
        </Alert>
      </When>
      <When it={isSuccess}>
        <SMSContactViews methods={methods} />
      </When>
      <When it={isSuccess && methods.length < smsLimit}>
        <Instruction margin="1rem 0 1rem" fontSize="1rem">
          To receive text (SMS) notifications of contact form submissions you will need to:
        </Instruction>
        <Instruction margin="1rem">
          1. Agree to the the terms of service for Showit Account Notifications.
          <br />
          2. Enter a valid phone number.
          <br />
          3. Confirm authorization by entering the confirmation code sent to the phone number
          provided.
        </Instruction>
        <When it={!agreedToTerms}>
          {' '}
          <Button
            disabled={!!config.DISABLE_SMS_SIGNUP}
            variant="outlined"
            onClick={() => setTermsDialogOpen(true)}
          >
            <AddSMSBox>&nbsp;Add Phone Number</AddSMSBox>
          </Button>
        </When>
        <When it={agreedToTerms}>
          <AddNumberForm key={methods.length} />
        </When>
        <TermsDialog
          open={termsDialogOpen}
          onClose={() => setTermsDialogOpen(false)}
          confirmTerms={() => agreeWithTerms(true)}
        />
      </When>
    </>
  );
};

LoadingView.propTypes = {
  maxNumbersAllowed: PropTypes.number.isRequired,
};

SMSContactViews.propTypes = {
  methods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      userId: PropTypes.number.isRequired,
      type: PropTypes.oneOf(['sms', 'email']).isRequired,
      value: PropTypes.string.isRequired,
      isConfirmed: PropTypes.bool.isRequired,
      createDate: PropTypes.string.isRequired,
      lastUpdated: PropTypes.string.isRequired,
      confirmationCodeExpiry: PropTypes.number,
    })
  ).isRequired,
};

SMSContactMethods.propTypes = {
  smsLimit: PropTypes.number,
};

SMSContactMethods.defaultProps = {
  smsLimit: 2,
};

export default SMSContactMethods;

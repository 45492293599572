import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '../../icons/WarningIcon';
import { palette } from '../../../theme';

const StatusSnackbar = ({ status, message, onClose, ...props }) => (
  <Snackbar
    open={!!message}
    autoHideDuration={5000}
    onClose={onClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    {...props}
  >
    {message && (
      <Alert
        sx={{ display: 'flex', alignItems: 'center' }}
        icon={
          status === 'success' ? (
            <CheckIcon fontSize="inherit" />
          ) : (
            <WarningIcon
              w="1.25rem"
              h="1.25rem"
              color={status === 'error' ? palette.error.main : palette.warning.main}
            />
          )
        }
        severity={status}
      >
        {message}
      </Alert>
    )}
  </Snackbar>
);

StatusSnackbar.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
};
StatusSnackbar.defaultProps = {
  status: undefined,
  message: undefined,
  onClose: () => {},
};

export default StatusSnackbar;

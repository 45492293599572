import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import Referrals from '../../subscription/ReferralCard/Referrals';
import useReferrals from '../../../../hooks/useReferrals';
import { PAGINATION_LIMIT } from '../../../../constants';

const ReferralsTab = ({ status }) => {
  const [offset, setOffset] = useState(0);
  const { referrals, total, isLoading, isSuccess, isError } = useReferrals({
    status,
    offset,
  });
  return (
    <Box>
      {isLoading && (
        <Box textAlign="center" mt={1.5}>
          <CircularProgress size={22} />
        </Box>
      )}
      {isError && (
        <Typography variant="body2" color="error" textAlign="center" my={4}>
          Failed to load referrals
        </Typography>
      )}
      {isSuccess && (
        <>
          <Referrals referrals={referrals} />
          {total > PAGINATION_LIMIT && (
            <Box mt={2} display="flex" justifyContent="center">
              <Pagination
                shape="rounded"
                sx={{ textAlign: 'center' }}
                limit={PAGINATION_LIMIT}
                page={offset / PAGINATION_LIMIT + 1}
                count={Math.ceil(total / PAGINATION_LIMIT)}
                onChange={(e, next) => setOffset((next - 1) * PAGINATION_LIMIT)}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

ReferralsTab.propTypes = {
  status: PropTypes.arrayOf(PropTypes.number).isRequired,
};
export default ReferralsTab;

import React, { useState, useEffect } from 'react';
import { Box, Button, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import { user as UserType } from '../../../../domain/PropTypes';
import useChargebeePortal from '../../../../hooks/useChargebeePortal';

const ChargebeePortalButton = ({ user }) => {
  const [showAlert, setShowAlert] = useState(false);
  const { open: openChargebeePortal, portalError } = useChargebeePortal({ userId: user.id });

  useEffect(() => {
    if (portalError) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [portalError]);

  return (
    <>
      <Box mt={2} mb={2}>
        <Button type="submit" variant="primary" onClick={openChargebeePortal}>
          <Box display="flex" alignItems="center" px={4} lineHeight={1}>
            Manage Subscription
          </Box>
        </Button>
      </Box>
      <Snackbar open={showAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          onClose={() => {
            setShowAlert(false);
          }}
          severity="error"
        >
          {portalError ? portalError.message : ''}
        </Alert>
      </Snackbar>
    </>
  );
};

ChargebeePortalButton.propTypes = {
  user: UserType.isRequired,
};

export default ChargebeePortalButton;

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Footer from './Footer/Footer';

const Content = styled('div')({
  padding: '10px 10px',
  minHeight: 'calc(100vh - 118px)',
});

function ServicePage({ children }) {
  return (
    <>
      <Content>{children}</Content>
      <Footer />
    </>
  );
}

ServicePage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ServicePage;

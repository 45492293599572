export * as accountStatuses from './accountStatuses';
export * as subscriptionStatuses from './subscriptionStatuses';
export * as errorMessages from './errorMessages';
export * as queryData from './queryData';
export * as routePaths from './routePaths';
export * as userTypes from './userTypes';
export * as domainConstants from './domainConstants';
export * as contributorStatuses from './contributorStatuses';
export * as blogTypes from './blogTypes';
export * as blogStatuses from './blogStatuses';
export * as blogHostingStatus from './blogHostingStatuses';
export * as PaypalStatuses from './paypalStatuses';
export * as AffiliateTransactionStatuses from './affiliateTransactionStatuses';
export const PAGINATION_LIMIT = 25;
export const REFERRAL_CODE_KEY = 'referralCode';
export const CHARGEBEE_CODE = 'cb';
export const DESIGN_APP_SUPPORTED_BROWSERS = {
  chrome: {
    minVersion: 88,
  },
};

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { Card, Link } from '@mui/material';
import { palette } from '../../../theme';

import { ACCOUNT_PROFILE } from '../../../constants/routePaths';
import { Alert, SubTitle } from '../../common';

const ConfirmationAlert = styled(Alert)`
  margin: 16px auto;

  & .MuiAlert-message {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;

    a {
      color: ${palette.warning.light};
      text-decoration: underline;
    }
  }
`;

const EmailConfirmationRequired = () => (
  <Card>
    <SubTitle>Account Email</SubTitle>
    <ConfirmationAlert severity="warning" variant="outlined">
      Please Confirm Email Before Subscribing.
      <Link component={RouterLink} to={ACCOUNT_PROFILE}>
        Go To Account Profile
      </Link>
    </ConfirmationAlert>
  </Card>
);

export default EmailConfirmationRequired;

import React from 'react';
import { format } from 'date-fns';
import { Box, Typography, Skeleton } from '@mui/material';
import { subscription as SubscriptionType, user as UserType } from '../../../../domain/PropTypes';
import { When, SubscribeLink, Instruction } from '../../../common';
import ChargbeePortalButton from './ChargebeePortalButton';
import ChangeNotice from './ChangeNotice';
import useSubscription from './useSubscription';
import { ACTIVE, INACTIVE } from '../../../../constants/accountStatuses';
import { CHARGEBEE_CODE, subscriptionStatuses } from '../../../../constants';
import { NON_RENEWING } from '../../../../constants/subscriptionStatuses';
import { palette } from '../../../../theme';

const getSubscriptionStatusMessage = ({ status, expiration }) => {
  const messages = {
    [NON_RENEWING]: `Subscription will end on ${format(new Date(expiration), 'MMM d, y')}`,
    default: `Subscription will renew on ${format(new Date(expiration), 'MMM d, y')}`,
  };
  return messages[status] || messages.default;
};

const Details = ({ user, subscription }) => {
  const { status: userStatus } = user;
  return (
    <Box>
      <Typography variant="h5">{subscription.name}</Typography>
      <When
        it={
          userStatus === ACTIVE &&
          (!!subscription.expiration || subscription.status === subscriptionStatuses.IN_TRIAL)
        }
      >
        <When it={!!subscription.expiration}>
          <Instruction>{getSubscriptionStatusMessage(subscription)}</Instruction>
        </When>
        {subscription.service === CHARGEBEE_CODE && <ChargbeePortalButton user={user} />}
        <ChangeNotice />
      </When>
      <When it={userStatus === INACTIVE}>
        <div style={{ marginTop: '.5rem' }}>
          {subscription.service === CHARGEBEE_CODE &&
          subscription.status === subscriptionStatuses.CANCELLED ? (
            <>
              <ChargbeePortalButton user={user} />
              <ChangeNotice />
            </>
          ) : (
            <SubscribeLink>Resubscribe</SubscribeLink>
          )}
        </div>
      </When>
    </Box>
  );
};

Details.propTypes = {
  user: UserType.isRequired,
  subscription: SubscriptionType.isRequired,
};

const SubscriptionInfo = ({ user }) => {
  const { subscription, isSuccess, isLoading, isError } = useSubscription();
  return (
    <Box>
      <When it={isLoading}>
        <Skeleton />
      </When>
      <When it={isError}>
        <Box>Unable to load subscription. Please contact support.</Box>
      </When>
      <When it={isSuccess}>
        {subscription.id ? (
          <Details user={user} subscription={subscription} />
        ) : (
          <Box>
            No subscription found.&nbsp;
            <SubscribeLink color={palette.brightBlue}>Subscribe Now</SubscribeLink>
          </Box>
        )}
      </When>
    </Box>
  );
};

SubscriptionInfo.propTypes = {
  user: UserType.isRequired,
};

export default SubscriptionInfo;

/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import { fonts, palette } from '../../../theme';
import useRecentBlogPosts from '../../../hooks/useRecentBlogPosts';
import useFeaturettes from '../../../hooks/useFeaturettes';

const FeaturedImage = styled('img')`
  width: 190px;
  height: 130px;
  object-fit: cover;
  cursor: pointer;
  object-position: top;
  padding: 5px;
  &:hover {
    opacity: 0.8;
  }
  border: 1px solid ${palette.primary.main};
  @media screen and (min-width: 960px) {
    width: 190px;
  }
`;

const getRandomBetweenZeroAndLength = (length) => Math.floor(Math.random() * (length - 1));
const POST_TYPE_RECENT_BLOG = 'recentBlogPosts';
const mapPostToFeaturette = (post) => ({
  ...post,
  type: {
    label: 'Recent Blog Posts',
  },
  ctaText: 'read more',
  title: post.title?.rendered,
  ctaUrl: post.link,
  thumbnail: post.jetpack_featured_media_url.replace('.jpg', '-300x300.jpg'),
  postType: POST_TYPE_RECENT_BLOG,
});

const FeaturedPosts = () => {
  const { featurettes = [] } = useFeaturettes();
  const { recentBlogPosts = [] } = useRecentBlogPosts();
  const isDesktop = useMediaQuery('(min-width:960px)');
  const [recentBlogPost, setRecentBlogPost] = useState(null);
  const [featurette, setFeaturette] = useState(null);

  useEffect(() => {
    if (recentBlogPosts?.length) {
      setRecentBlogPost(
        mapPostToFeaturette(recentBlogPosts[getRandomBetweenZeroAndLength(recentBlogPosts.length)])
      );
    }
  }, [recentBlogPosts]);

  useEffect(() => {
    if (featurettes?.length) {
      setFeaturette(featurettes[getRandomBetweenZeroAndLength(featurettes.length)]);
    }
  }, [featurettes]);

  if (!featurette || !recentBlogPost) return null;

  return [recentBlogPost, featurette].map((post, i) => (
    <Box
      key={post.type?.label}
      flex={1}
      pt={3}
      pl={i === 1 && isDesktop ? 3 : 0}
      style={{
        borderTop: `1px solid ${palette.primary.main}`,
        borderLeft: i === 1 && isDesktop ? `1px solid ${palette.primary.main}` : 'none',
        overflow: 'hidden',
      }}
    >
      <Typography
        textTransform="uppercase"
        fontFamily={fonts.maison}
        fontSize="0.8rem"
        marginBottom="1rem"
      >
        {post.type?.label}:
      </Typography>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
        <Box>
          <Link target="_blank" href={post.ctaUrl}>
            <FeaturedImage
              src={post.thumbnail || post.featuretteThumbnail?.sizes.medium}
              alt={post.featuretteThumbnail?.alt}
            />
          </Link>
        </Box>
        <Box display="flex" flexDirection="column" py={1} px={{ xs: 0, md: 3 }}>
          <Typography
            lineHeight="1.1"
            fontSize="1.4em"
            fontWeight={700}
            fontFamily={fonts.atypText}
            /* Wordpress does not decode HTML entities in the post title (i.e. "August&#8217;s Favorites") */
            dangerouslySetInnerHTML={{ __html: post.title }}
          />
          <Typography variant="body" style={{ fontWeight: 300 }}>
            {post.postType !== POST_TYPE_RECENT_BLOG &&
              `${post.previewText?.slice(0, 63).trim()}... `}
            <Link target="_blank" href={post.ctaUrl}>
              {post.ctaText}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  ));
};

export default FeaturedPosts;

import { useQuery } from 'react-query';

import { getSubscriptionStatus } from '../../../../api';
import { SUBSCRIPTION } from '../../../../constants/queryData';

const defaults = {
  enabled: true,
  retry: 2,
  refetchOnWindowFocus: true,
  refetchOnReconnect: true,
  refetchOnMount: true,
};
export default function useSubscription(settings = defaults) {
  const { data, ...rest } = useQuery(SUBSCRIPTION, getSubscriptionStatus, {
    ...defaults,
    ...settings,
  });
  const { subscription = {} } = data || {};
  return { subscription, ...rest };
}

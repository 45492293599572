import React, { useState } from 'react';
import { Container } from '@mui/material';
import { Redirect } from 'react-router-dom';
import SubscriptionChooser from './SubscriptionChooser';
import { When } from '../../common';
import useUser from '../../../hooks/useUser';
import { canSubscribe } from '../../../utils/user';
import { ACCOUNT, ACCOUNT_SUBSCRIBE_SUCCESS } from '../../../constants/routePaths';
import EmailConfirmationRequired from './EmailConfirmationRequired';
import sendConversionGA from '../../../utils/googleAnalytics';

const SubscribeView = () => {
  const { user = {} } = useUser();
  const [allowedToSubscribe] = useState(canSubscribe(user));
  const [showCheckoutComplete, setShowCheckoutComplete] = useState(false);
  const { emailConfirmed } = user;

  const onCheckoutComplete = ({ cbSubscriptionId, price }) => {
    sendConversionGA({ cbSubscriptionId, price });
    setShowCheckoutComplete(true);
  };

  return (
    <Container sx={{ flexDirection: 'column', display: 'flex' }}>
      <When it={showCheckoutComplete}>
        <Redirect to={ACCOUNT_SUBSCRIBE_SUCCESS} />
      </When>
      <When it={allowedToSubscribe && !showCheckoutComplete}>
        <SubscriptionChooser onCheckoutComplete={onCheckoutComplete} />
      </When>
      <When it={!allowedToSubscribe && !showCheckoutComplete}>
        <When it={emailConfirmed}>
          <Redirect to={ACCOUNT} />
        </When>
        <When it={!emailConfirmed}>
          <EmailConfirmationRequired />
        </When>
      </When>
    </Container>
  );
};

export default SubscribeView;

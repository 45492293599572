import React from 'react';
import Link from '@mui/material/Link';
import Links from '../../../../../constants/links';

// alert types
export const DNS_PROPAGATING = 'DNS_PROP';
export const ISSUING_SSL = 'ISSUING_SSL';
export const CONTACT_SUPPORT = 'CONTACT_SUPPORT';

const SupportLink = () => (
  <>
    If you have any questions, please{' '}
    <Link href={Links.HELP_URL} color="inherit" fontWeight="bold" underline="hover">
      contact support.
    </Link>
  </>
);

export const ALERT_TYPES = {
  [DNS_PROPAGATING]: {
    title: 'Verifying DNS',
    desc: () => (
      <>
        Please wait 24-48 hours for DNS to propagate.&nbsp;
        <SupportLink />
      </>
    ),
  },
  [ISSUING_SSL]: {
    title: 'Issuing SSL',
    desc: () => (
      <>
        Please wait 24 hours for us to issue your SSL certificate.&nbsp;
        <SupportLink />
      </>
    ),
  },
  [CONTACT_SUPPORT]: {
    title: 'Request Your SSL',
    desc: () => (
      <>
        Please&nbsp;
        <Link href={Links.HELP_URL} color="inherit" underline="always">
          contact support
        </Link>
        &nbsp; to set up your SSL certificate.
      </>
    ),
  },
};

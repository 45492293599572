import styled from '@emotion/styled';
import { Button, Card, List, Typography } from '@mui/material';

import theme, { fonts, palette } from '../../../theme';

export const SubscriptionPanel = styled(Card)`
  border: 1px solid ${palette.primary.main};
  max-width: 380px;
  padding: 20px 30px;

  & button {
    line-height: normal;
    white-space: nowrap;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 20px 13%;
  }

  ${theme.breakpoints.up('md')} {
    max-width: 330px;
    padding: 20px 13.5%;

    & button {
      font-size: 1em;
    }
  }

  & .priceBox {
    margin: 24px 0;
    text-align: center;
  }

  & .yearlyPrice,
  & .monthlyPrice,
  & .planName {
    font-family: ${fonts.atyp};
    font-weight: 500;

    & .monthLabel {
      font-size: 0.5em;
    }
  }

  & .planName {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
  }

  & .yearlyPrice {
    font-size: 60px;
  }

  & .yearSubButton {
    margin-top: 10px;
  }

  & .yearTotal {
    margin: 8px 0;
    font-family: ${fonts.maison};
    font-size: 0.8rem;
  }

  & .monthlyPrice {
    font-size: 28px;
    margin-top: 24px;
    margin-bottom: 8px;
  }
`;

export const Header = styled(Typography)`
  text-align: center;
  font-size: 16px;
  font-family: ${fonts.atyp};
  font-weight: 600;
  margin-top: 16px;
`;

export const PlanList = styled(List)`
  & .reason {
    align-items: flex-start;
    font-size: 16;
    font-family: ${fonts.ambit};
  }

  & .wordDivider {
    width: calc(100% - 32px);
    margin: 1em 0 1em auto;
  }

  & .MuiListItemIcon-root {
    min-width: 32px;

    & .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
`;

export const Title = styled(Typography)`
  margin: 24px 0 32px;
  text-align: center;
  font-family: ${fonts.atyp};
  font-weight: 500;
  font-size: 30px;
`;

export const TogglePlanButton = styled(Button)`
  ${theme.breakpoints.down('sm')} {
    height: auto;
    min-height: 40px;
  }
`;

export const CheckoutButton = styled(Button)`
  ${theme.breakpoints.down('sm')} {
    height: auto;
    min-height: 40px;
  }
`;

export const Discount = styled(Card)`
  display: flex;
  flex-direction: column;

  & .selection {
    font-family: ${fonts.atyp};
    text-align: center;
    font-size: 1.8em;
    &.header {
      font-size: 1.2em;
    }
  }

  & .inputs {
    max-width: 300px;
    margin: 1em auto 2em;
    display: flex;
    flex-direction: column;
  }

  & .chooseDifferentButton {
    fontsize: '1rem';
    text-transform: none;
    font-family: ${fonts.maison};
    font-size: 1em;
  }
`;

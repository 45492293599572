import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  user as UserType,
  subscription as SubscriptionType,
} from '../../../../../domain/PropTypes';
import { COMPED, LIFETIME, NORMAL } from '../../../../../constants/userTypes';
import SubscriptionInfo from '../SubscriptionInfo';
import { formatDateAsWords } from '../../../../../utils/dateTime';

const LifetimeView = () => (
  <Box>
    <Typography variant="h5">Lifetime - Inactive</Typography>
    <Typography>Please contact support.</Typography>
  </Box>
);

const CompedView = () => (
  <Box>
    <Typography>Please contact support.</Typography>
  </Box>
);

const NormalView = ({ user }) => <SubscriptionInfo user={user} />;
NormalView.propTypes = {
  user: UserType.isRequired,
};

const UserTypeMap = {
  [COMPED]: CompedView,
  [LIFETIME]: LifetimeView,
  [NORMAL]: NormalView,
};

const StatusInactive = ({ user, subscription }) => {
  const { userType } = user;
  const UserTypeView = UserTypeMap[userType];
  return (
    <Box>
      {subscription?.expiration && (
        <Box my={1}>Your subscription ended on {formatDateAsWords(subscription.statusDate)}.</Box>
      )}
      {UserTypeView && <UserTypeView user={user} />}
    </Box>
  );
};

StatusInactive.propTypes = {
  user: UserType.isRequired,
  subscription: SubscriptionType.isRequired,
};

export default StatusInactive;

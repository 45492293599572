import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

import { publisherFactory } from '../utils/publisherFactory';

const FIVE_MINUTES = 60 * 5; // 60 seconds * 5 minutes

let sessionToken = null;
let sessionExpires = 0;
let timeout = null;

const { subscribe, publish } = publisherFactory();
const isAuthorized = () => sessionToken != null && Date.now() / 1000 <= sessionExpires;
const isStale = () => isAuthorized() && sessionExpires <= Date.now() / 1000 + FIVE_MINUTES;
const isAdmin = () => {
  try {
    const payload = jwtDecode(sessionToken);
    return !!payload?.data?.adminId;
  } catch (error) {
    return false;
  }
};
const getToken = () => sessionToken;
const getExpires = () => sessionExpires;
const clear = () => {
  localStorage.removeItem('sessionToken');
  localStorage.removeItem('sessionExpires');
  sessionToken = null;
  sessionExpires = 0;
};
const redirectToLogin = () => {
  clear();
  publish({ isAuthorized: isAuthorized(), timedOut: true });
};
const set = ({ token, tokenExpires } = {}, timedOut = false) => {
  const expires = parseInt(tokenExpires, 10);

  clearTimeout(timeout);

  if (!!token && expires) {
    localStorage.setItem('sessionToken', token);
    localStorage.setItem('sessionExpires', expires);
    sessionToken = token;
    sessionExpires = expires;
    timeout = setTimeout(() => redirectToLogin(), expires * 1000 - Date.now());

    if (isAuthorized()) {
      sessionStorage.setItem('sameSession', true);
    }
  } else {
    clear();
  }

  publish({ isAuthorized: isAuthorized(), timedOut });
};

const useAuthorization = () => {
  const [isAuth, setIsAuth] = useState({
    isAuthorized: isAuthorized(),
    isAdmin: isAdmin(),
    timedOut: false,
  });

  useEffect(() => subscribe(setIsAuth), []);

  return isAuth;
};

export { getToken, getExpires, set, isAuthorized, isStale, useAuthorization };

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { palette } from '../../theme';

const linkTextStyles = {
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

// Looks and behaves like a link, but allows you to use onClick actions
const ActionLinkText = ({ color, onClick, disabled, display = 'initial', children }) => (
  <Typography
    sx={linkTextStyles}
    tabIndex="0"
    display={display}
    variant="body2"
    color={color}
    onClick={() => !disabled && onClick()}
  >
    {children}
  </Typography>
);

ActionLinkText.defaultProps = {
  children: null,
  display: 'initial',
  disabled: false,
  color: palette.brightBlue,
};
ActionLinkText.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.node,
  display: PropTypes.string,
};

export default ActionLinkText;

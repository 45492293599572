import Links from '../../../constants/links';

// Reasons
const REASON_NO_BLOG = { text: "You don't need a blog" };
const REASON_MIGRATE_BLOG = {
  text: 'You want to migrate blog posts from WordPress or Squarespace',
  append: 'or',
};
const REASON_PLUGINS = {
  text: 'You want to install your choice of WordPress plugins',
  append: 'and',
};
const REASON_SEO_EXPERT = {
  text: 'You have worked with an SEO expert',
};

// Features
const FEATURE_BUILDER = {
  text: 'Showit website builder',
};
const FEATURE_SECURE = {
  text: 'Secure certificate (HTTPS)',
};
const FEATURE_MEDIA_STORAGE = {
  text: 'Web hosting with 20GB of media storage',
};
const FEATURE_BACKUPS = {
  text: 'Regular design backups every 10 minutes, stored for 7 days',
};
const FEATURE_20GB_BLOG_STORAGE = {
  text: '20 GB of blog storage',
};
const FEATURE_DESIGN_WP_THEME = {
  text: 'Design your WordPress theme in Showit',
};
const FEATURE_WP_HOSTING = {
  link: [
    { text: 'WordPress hosting powered by ' },
    { href: 'https://wpengine.com/', text: 'WP Engine' },
  ],
};
const FEATURE_BLOG_BACKUPS = {
  text: 'Daily blog backups, stored for 30 days',
};
const FEATURE_SUPPORT_CUSTOM_PLUGINS = {
  text: 'Support for Showit blog design and custom WordPress plugins (including e-commerce plugins)',
};
const FEATURE_UNLIMITED_WORDPRESS_USERS = {
  text: 'Unlimited WordPress users',
};
const FEATURE_BLOG_MIGRATION = {
  text: 'Free advanced blog migration (4-day turnaround, includes posts, images, comments, and WordPress plugins)',
};
const FEATURE_FTP_DB = {
  text: 'FTP & database access (Available upon request. Recommended only for advanced users with prior knowledge)',
};

// Plans
const showit = {
  id: 'showit',
  name: 'Showit',
  blogType: 'NONE',
  monthly: {
    planId: 'showit_m',
    period: 'm',
    price: 24,
    periodTotal: 24,
  },
  yearly: {
    planId: 'showit_y',
    period: 'y',
    price: 19,
    periodTotal: 228.0,
  },
  reasons: [REASON_NO_BLOG],
  features: [FEATURE_BUILDER, FEATURE_SECURE, FEATURE_MEDIA_STORAGE, FEATURE_BACKUPS],
};
const showitBasic = {
  id: 'showit_basic',
  name: 'Showit + Basic Starter Blog',
  blogType: 'BASIC',
  monthly: {
    planId: 'showit_basic_m',
    period: 'm',
    price: 29,
    periodTotal: 29,
  },
  yearly: {
    planId: 'showit_basic_y',
    period: 'y',
    price: 24,
    periodTotal: 288.0,
  },
  reasons: [
    {
      text: 'You want to start a new blog',
    },
    {
      link: [
        { text: 'Our ' },
        {
          href: Links.BILLING_LEARN_URL,
          text: 'preinstalled plugins',
        },
        { text: ' are sufficient for your needs' },
      ],
    },
  ],
  features: [
    FEATURE_BUILDER,
    FEATURE_SECURE,
    FEATURE_MEDIA_STORAGE,
    FEATURE_BACKUPS,
    FEATURE_20GB_BLOG_STORAGE,
    {
      text: 'Up to 10K blog visits per month',
    },
    FEATURE_DESIGN_WP_THEME,
    FEATURE_WP_HOSTING,
    FEATURE_BLOG_BACKUPS,
    {
      text: 'One Wordpress user',
    },
    {
      link: [
        { text: 'Support for Showit blog design and ' },
        {
          href: Links.BILLING_LEARN_URL,
          text: 'preinstalled plugins',
        },
        { text: '. (Additional plugins are not permitted)' },
      ],
    },
  ],
};
const showitAdvanced = {
  id: 'showit_adv',
  name: 'Showit + Advanced Blog',
  blogType: 'ADV',
  monthly: {
    planId: 'showit_adv_m',
    period: 'm',
    price: 39,
    periodTotal: 39,
  },
  yearly: {
    planId: 'showit_adv_y',
    period: 'y',
    price: 34,
    periodTotal: 408.0,
  },
  reasons: [REASON_MIGRATE_BLOG, REASON_PLUGINS, REASON_SEO_EXPERT],
  features: [
    FEATURE_BUILDER,
    FEATURE_SECURE,
    FEATURE_MEDIA_STORAGE,
    FEATURE_BACKUPS,
    FEATURE_20GB_BLOG_STORAGE,
    {
      text: 'Up to 25K blog visits per month',
    },
    FEATURE_DESIGN_WP_THEME,
    FEATURE_WP_HOSTING,
    FEATURE_BLOG_BACKUPS,
    FEATURE_UNLIMITED_WORDPRESS_USERS,
    FEATURE_SUPPORT_CUSTOM_PLUGINS,
    FEATURE_BLOG_MIGRATION,
    FEATURE_FTP_DB,
  ],
};
const showitHighTraffic1 = {
  id: 'showit_ht1',
  name: 'Showit + Advanced Blog 50K',
  blogType: 'ADV',
  monthly: {
    planId: 'showit_ht1_m',
    period: 'm',
    price: 69,
    periodTotal: 69,
  },
  yearly: {
    planId: 'showit_ht1_y',
    period: 'y',
    price: 64,
    periodTotal: 768.0,
  },
  reasons: [
    {
      text: 'You have more than 50,000 visits per month',
    },
    REASON_MIGRATE_BLOG,
    REASON_PLUGINS,
    REASON_SEO_EXPERT,
  ],
  features: [
    FEATURE_BUILDER,
    FEATURE_SECURE,
    FEATURE_MEDIA_STORAGE,
    FEATURE_BACKUPS,
    {
      text: '30 GB of blog storage',
    },
    {
      text: 'Up to 50K blog visits per month',
    },
    FEATURE_DESIGN_WP_THEME,
    FEATURE_WP_HOSTING,
    FEATURE_BLOG_BACKUPS,
    FEATURE_UNLIMITED_WORDPRESS_USERS,
    FEATURE_SUPPORT_CUSTOM_PLUGINS,
    FEATURE_BLOG_MIGRATION,
    FEATURE_FTP_DB,
  ],
};
const showitHighTraffic2 = {
  id: 'showit_ht2',
  name: 'Showit + Advanced Blog 100K',
  blogType: 'ADV',
  monthly: {
    planId: 'showit_ht2_m',
    period: 'm',
    price: 129,
    periodTotal: 129,
  },
  yearly: {
    planId: 'showit_ht2_y',
    period: 'y',
    price: 124,
    periodTotal: 1488.0,
  },
  reasons: [
    {
      text: 'You have more than 100,000 visits per month',
    },
    REASON_MIGRATE_BLOG,
    REASON_PLUGINS,
    REASON_SEO_EXPERT,
  ],
  features: [
    FEATURE_BUILDER,
    FEATURE_SECURE,
    FEATURE_MEDIA_STORAGE,
    FEATURE_BACKUPS,
    {
      text: '50 GB of blog storage',
    },
    {
      text: 'Up to 100K blog visits per month',
    },
    FEATURE_DESIGN_WP_THEME,
    FEATURE_WP_HOSTING,
    FEATURE_BLOG_BACKUPS,
    FEATURE_UNLIMITED_WORDPRESS_USERS,
    FEATURE_SUPPORT_CUSTOM_PLUGINS,
    FEATURE_BLOG_MIGRATION,
    FEATURE_FTP_DB,
  ],
};
const showitSelfHostedBlog = {
  id: 'showit_selfhostedblog',
  name: 'Showit + Your Own WPEngine Blog',
  blogType: 'ADV',
  external: true,
  features: [
    FEATURE_BUILDER,
    FEATURE_SECURE,
    FEATURE_MEDIA_STORAGE,
    FEATURE_BACKUPS,
    FEATURE_DESIGN_WP_THEME,
    FEATURE_SUPPORT_CUSTOM_PLUGINS,
    {
      text: 'Blog server features dependent on subscription with WP Engine',
    },
  ],
};

const standardPlans = [showit, showitBasic, showitAdvanced];
const highTrafficPlans = [showitHighTraffic1, showitHighTraffic2, showitSelfHostedBlog];

function useSubscriptionPlans() {
  return [standardPlans, highTrafficPlans];
}

export default useSubscriptionPlans;

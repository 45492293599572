export const referralOption = 'Referral Code';

export default [
  '',
  'Friend',
  'Someone I Follow',
  'Designer',
  'Workshop/Conference/Event',
  'Advertisement',
  'Local Group',
  'Search Result',
  'Other',
];

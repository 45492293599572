import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Box, Button, CircularProgress, Container, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { StatusCodes } from 'http-status-codes';
import { ACCOUNT_MESSAGES } from '../../../constants/routePaths';
import { MESSAGE } from '../../../constants/queryData';
import { SubTitle, When } from '../../common';
import { getMessageContent } from '../../../api';
import { formatDate } from '../../../utils/dateTime';
import { fonts } from '../../../theme';
import useUser from '../../../hooks/useUser';

const SingleMessagePage = () => {
  const { id } = useParams();
  const { user } = useUser();

  const signedInUserEmail = user?.emailAddress || '';

  const { data, isLoading, isError, error, isSuccess } = useQuery(
    [MESSAGE, id],
    () => getMessageContent(id),
    {
      staleTime: Infinity,
      retry: 2,
    }
  );

  const isLoaded = isSuccess && !!data;
  const isUnauthorized = error?.response?.status === StatusCodes.UNAUTHORIZED;

  return (
    <Container maxWidth="md">
      <div
        style={{
          whiteSpace: 'pre-wrap',
        }}
      >
        <When it={isLoading}>
          <CircularProgress size={20} thickness={3.5} color="primary" textAlign="center" />
        </When>
        <When it={isError}>
          <Typography
            mb={2}
            fontFamily={fonts.atypText}
            fontWeight={700}
            textAlign={{ xs: 'left', md: 'center' }}
            sx={{ fontSize: '1.1rem' }}
          >
            A problem occurred while loading this message
          </Typography>
        </When>
        <When it={isUnauthorized}>
          <Typography
            mb={2}
            fontFamily={fonts.atypText}
            fontWeight={400}
            textAlign={{ xs: 'left', md: 'center' }}
            sx={{ fontSize: '1rem' }}
          >
            This message is not available to view when signed in as {signedInUserEmail}.
            <Box mb={2} fontWeight={700}>
              Please sign in with a different account.
            </Box>
          </Typography>
        </When>
        <When it={isLoaded && !isUnauthorized}>
          {`Submitted: ${formatDate(data?.timestamp)}\n`}
          {`From IP Address: ${data?.requestIp}\n`}
          <SubTitle my={2} width="100%">
            {`${data?.fields?.find(({ name }) => name === 'subject')?.value}`}
          </SubTitle>
          {data?.fields?.find(({ name }) => name === 'body')?.value}
          <div>
            <br />
            <Button variant="contained" color="primary" href={ACCOUNT_MESSAGES}>
              Back
            </Button>
          </div>
        </When>
      </div>
    </Container>
  );
};

export default SingleMessagePage;

import { finishEntriConfiguration } from '../api';
import { DNS_SHOULD_NOT_EXIST, DNS_UNKNOWN } from '../constants/domainConstants';
import { DOMAIN_DATA } from '../constants/queryData';

const SCRIPT_ID = 'entri-script';

export const loadEntriScript = async (callback) => {
  const existingScript = document.getElementById(SCRIPT_ID);
  if (existingScript) return callback(window.entri);

  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://cdn.goentri.com/entri.js';
    script.id = SCRIPT_ID;
    script.async = true;
    script.onload = async () => {
      resolve(callback(window.entri));
    };
    document.body.appendChild(script);
  });
};

export const filterDNSRecords = (dnsRecords) =>
  dnsRecords.filter(
    ({ expectedValue }) => expectedValue !== DNS_SHOULD_NOT_EXIST && expectedValue !== DNS_UNKNOWN
  );

export const saveEntriEvent = async ({ userId, domainId, dnsRecords, queryClient }) => {
  try {
    const configureDNSEvent = await finishEntriConfiguration({
      userId,
      domainId,
      // only keep certain properties
      dnsRecords: dnsRecords.map((record) => ({
        domainId: record.domainId,
        recordName: record.recordName,
        recordType: record.recordType,
        expectedValue: record.expectedValue,
      })),
    });
    // set configureDate on each dns record that was configured
    queryClient.setQueryData([DOMAIN_DATA, userId], (cache) =>
      cache.map((cachedApexDomain) =>
        cachedApexDomain.domainId === domainId
          ? {
              ...cachedApexDomain,
              dnsRecords: cachedApexDomain.dnsRecords.map((cachedRecord) =>
                configureDNSEvent.jsonData.dnsRecords.some(
                  ({ recordName, recordType, expectedValue }) =>
                    recordName === cachedRecord.recordName &&
                    recordType === cachedRecord.recordType &&
                    expectedValue === cachedRecord.expectedValue
                )
                  ? {
                      ...cachedRecord,
                      configureDate: configureDNSEvent.occurredDate,
                    }
                  : cachedRecord
              ),
            }
          : cachedApexDomain
      )
    );
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
  }
};

export const mapToEntriRecords = (dnsRecords) =>
  dnsRecords.map(({ recordType, expectedValue, host, ttl }) => ({
    type: recordType,
    value: expectedValue,
    host,
    ttl,
  }));

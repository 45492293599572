import styled from '@emotion/styled';
import { Box, Card, Typography } from '@mui/material';

export const DesignerContainer = styled(Box)`
  display: flex;
  width: 50%;
  flex-direction: column;
`;

export const DesignerName = styled(Typography)`
  position: absolute;
  border-radius: 30px;
  padding: 2px 18px;
  margin: -54px -70px 0;
  font-size: 0.9em;
  text-align: center;
`;

export const DesignerLogoContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  max-width: 125px;
`;

export const LoginCard = styled(Card, { shouldForwardProp: (name) => name !== 'showDesigner' })`
  ${({ showDesigner }) => (showDesigner ? 'display: flex; padding: 15px;' : '')}

  & .LoginCardContent {
    ${({ showDesigner }) => (showDesigner ? 'width: 50%; padding: 1% 2%;' : 'width: 100%;')}
  }

  & .LoginFormContainer {
    ${({ showDesigner }) => (showDesigner ? 'padding: 15% 10%;' : '')}
  }
`;

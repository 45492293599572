import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { Box, Card, IconButton, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import styled from '@emotion/styled';
import { Alert, Button, SubTitle, Instruction, TextInput, When } from '../../common';
import { updatePrimaryEmail } from '../../../api';
import { yup, handleError, buildSubmitHandler } from '../../../utils';
import { ACCOUNT_SECURITY } from '../../../constants/routePaths';
import queryData from '../../../utils/queryData';
import { palette } from '../../../theme';
import Transition from '../../common/Transition';

const StyledAccountCard = styled(Card)`
  background-color: ${palette.lighterGrey};
  margin-top: 3em;
  margin-bottom: 2em;
  padding: 40px;
  & .submitContainer {
    display: flex;
    justify-content: center;
    & .submitButton {
      &,
      & button {
        width: 100%;
      }
    }
  }
`;

const EmailForm = ({ emailAddress, confirmed }) => {
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [confirmationResent, setConfirmationResent] = useState(false);
  const [emailUpdated, setEmailUpdated] = useState(false);

  const queryClient = useQueryClient();
  const { mutate } = useMutation(({ values }) => updatePrimaryEmail(values), {
    onSuccess: (data, { actions, values }) => {
      queryData.updateUser(data, queryClient);
      setShowUpdateForm(false);
      setConfirmationResent(!!values.resend);
      setEmailUpdated(!values.resend);
      actions.resetForm();
    },
    onError: handleError,
  });

  const inputRefs = useRef({});
  const setInputRef = (element) => {
    if (element != null) {
      inputRefs.current[element.name] = element;
    }
  };

  const { errors, values, handleSubmit, handleChange, touched, resetForm } = useFormik({
    initialValues: {
      emailAddress: '',
      emailAddressConfirmation: '',
    },
    enableReinitialize: true,
    validationSchema: yup.createSchema({
      emailAddress: yup.emailAddress,
      emailAddressConfirmation: yup.emailAddressConfirmation,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: buildSubmitHandler(mutate),
  });

  const resendConfirmationEmail = () =>
    mutate({
      values: { emailAddress, emailAddressConfirmation: emailAddress, resend: true },
      actions: { resetForm: () => undefined },
    });

  return (
    <StyledAccountCard>
      <SubTitle>Account Email</SubTitle>
      <Instruction>This is the email address used to sign in to your Showit account.</Instruction>
      <When
        it={errors.general}
        render={() => (
          <Transition mb={0}>
            <Alert severity="error" noBorder transparent mb={0}>
              {errors.general}
            </Alert>
          </Transition>
        )}
      />
      <When it={!confirmed && !showUpdateForm && !emailUpdated}>
        <Transition mb={0}>
          <Alert severity="warning" variant="outlined" noBorder transparent>
            Needs Confirmation
          </Alert>
        </Transition>
      </When>
      <When it={emailUpdated}>
        <Transition timeout={4000} callback={() => setEmailUpdated(false)} mb={0}>
          <Alert severity="success" noBorder transparent>
            Email Updated. Please Confirm.
          </Alert>
        </Transition>
      </When>
      <Box display="flex" mt={1} mb={2} alignItems="center" justifyContent="space-between">
        <Typography variant="h5" noWrap title={emailAddress}>
          {emailAddress}
        </Typography>
        <When it={!showUpdateForm}>
          <IconButton
            onClick={() => setShowUpdateForm(true)}
            title="Change Email Address"
            size="large"
          >
            <Edit />
          </IconButton>
        </When>
      </Box>
      <When it={!showUpdateForm && !confirmed && !confirmationResent}>
        <Box display="flex" justifyContent="center" width="100%">
          <Button type="button" variant="text" onClick={resendConfirmationEmail}>
            Resend Confirmation
          </Button>
        </Box>
      </When>
      <When it={!showUpdateForm && confirmationResent}>
        <Alert icon={false} severity="success" onClose={() => setConfirmationResent(false)}>
          Confirmation Email Resent
        </Alert>
      </When>
      <When it={showUpdateForm}>
        <form onSubmit={handleSubmit}>
          <TextInput
            inputRef={setInputRef}
            type="text"
            variant="outlined"
            label="New Email"
            name="emailAddress"
            value={values.emailAddress}
            onChange={handleChange}
            error={touched.emailAddress && !!errors.emailAddress}
            helperText={touched.emailAddress && errors.emailAddress}
          />
          <TextInput
            inputRef={setInputRef}
            type="text"
            variant="outlined"
            label="Confirm New Email"
            name="emailAddressConfirmation"
            value={values.emailAddressConfirmation}
            onChange={handleChange}
            error={touched.emailAddressConfirmation && !!errors.emailAddressConfirmation}
            helperText={touched.emailAddressConfirmation && errors.emailAddressConfirmation}
          />
          <Box mt={2}>
            <Button type="submit">Update Email</Button>
            <Button
              sx={{ marginLeft: 2 }}
              variant="text"
              onClick={() => {
                setShowUpdateForm(false);
                resetForm();
              }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </When>

      <SubTitle my={2}>Account Password</SubTitle>
      <Box className="submitContainer">
        <RouterLink to={ACCOUNT_SECURITY} className="submitButton">
          <Button type="submit" variant="outlined">
            Change Password
          </Button>
        </RouterLink>
      </Box>
    </StyledAccountCard>
  );
};

EmailForm.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  confirmed: PropTypes.bool.isRequired,
};

export default EmailForm;

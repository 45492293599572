import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import appConfig from '../../../config';
import { user as UserShape } from '../../../domain/PropTypes';

const IntercomBoot = ({ user }) => {
  const { boot, shutdown } = useIntercom();
  useEffect(() => {
    const intercomConfig = {
      user_id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      email: user.emailAddress,
      created_at: Date.parse(user.createDate) / 1000,
      customAttributes: {
        industry: user.industry,
        'showit-status': user.status,
        showit_blog_type: user.blogType,
      },
      user_hash: user.intercomUserHash,
    };
    boot(intercomConfig);
    return shutdown;
    // Needed so that intercom doesnt reinitialize when user settings are changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boot, shutdown]);
  return null;
};

const Intercom = ({ user, children }) => {
  const { INTERCOM_APP_ID } = appConfig;
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <IntercomBoot user={user} />
      {children}
    </IntercomProvider>
  );
};

Intercom.propTypes = {
  user: UserShape,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

IntercomBoot.propTypes = {
  user: UserShape,
};

IntercomBoot.defaultProps = {
  user: {},
};

Intercom.defaultProps = {
  user: {},
};

export default Intercom;

import React from 'react';
import { StatusCodes } from 'http-status-codes';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import WarningIcon from '../../icons/WarningIcon';
import { When } from '../../common';
import { fonts, palette } from '../../../theme';

const DomainStatusIcon = ({ bgColor, icon, label }) => (
  <Stack direction="row" alignItems="center" mb={1}>
    <Box p={0.5} mr={1} display="grid" bgcolor={bgColor} color="white" borderRadius="100%">
      {icon}
    </Box>
    <Typography
      textTransform="uppercase"
      fontSize="1rem"
      alignSelf="self-end"
      fontFamily={fonts.maison}
    >
      {label}
    </Typography>
  </Stack>
);
DomainStatusIcon.propTypes = {
  label: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

const DomainStatusIcons = ({
  statusCode,
  statusOk,
  sslEnabled,
  isShowit,
  showStatusText = true,
}) => (
  <Box mt={1.75}>
    <Box mb={1.5}>
      <DomainStatusIcon
        label={statusOk ? (isShowit ? 'Online' : 'Not Connected') : 'Offline'}
        bgColor={statusOk && isShowit ? palette.success.secondary : palette.error.main}
        icon={
          statusOk && isShowit ? (
            <CheckIcon style={{ display: 'flex', fontSize: 18 }} />
          ) : (
            <WarningIcon w="1.2rem" h="1.2rem" display="flex" />
          )
        }
      />
      <DomainStatusIcon
        label={sslEnabled ? 'Secure' : 'Not Secure'}
        bgColor={sslEnabled ? palette.success.secondary : palette.error.main}
        icon={
          sslEnabled ? (
            <LockIcon style={{ display: 'flex', fontSize: 18 }} />
          ) : (
            <LockOpenIcon style={{ display: 'flex', fontSize: 18 }} />
          )
        }
      />
    </Box>
    <When it={showStatusText}>
      <When it={statusCode !== StatusCodes.OK}>
        <Typography color="error">
          Your website doesn&apos;t appear to be online. Please contact support for help.
          {statusCode && ` (Error ${statusCode})`}
        </Typography>
      </When>
      <When it={!sslEnabled}>
        <Typography color="error">SSL is not enabled for this domain.</Typography>
      </When>
    </When>
  </Box>
);
DomainStatusIcons.propTypes = {
  statusCode: PropTypes.number,
  statusOk: PropTypes.bool.isRequired,
  sslEnabled: PropTypes.bool.isRequired,
  isShowit: PropTypes.bool.isRequired,
  showStatusText: PropTypes.bool,
};
DomainStatusIcons.defaultProps = {
  statusCode: undefined,
  showStatusText: true,
};
export default DomainStatusIcons;

import React from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import { StatusCodes } from 'http-status-codes';
import DomainStatusIcons from '../DomainStatusIcons';
import { isDomainSecure } from '../../../../utils/domainTools';
import BlogStatusMessage from '../BlogStatusMessage';
import { DomainName, LastChecked } from '../Styles';
import { blogShape, domainShape } from '../../../../domain/PropTypes';

const SubdomainStatus = ({ subdomain, blog }) => {
  const { domainName, domainStatus } = subdomain;
  const { statusCode, isShowit, lastDNSMonitor, checkDate } = domainStatus;
  return (
    <Card
      elevation={0}
      sx={{
        p: 2.5,
        mb: 1,
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
      }}
    >
      <DomainName>{domainName}</DomainName>
      {lastDNSMonitor && (
        <DomainStatusIcons
          statusCode={statusCode}
          statusOk={statusCode === StatusCodes.OK}
          sslEnabled={isDomainSecure(domainStatus)}
          isShowit={isShowit}
        />
      )}
      <BlogStatusMessage hasBlogPlan={!!blog} blog={blog} />
      <LastChecked mt={1}>
        {checkDate
          ? `Status last checked ${format(new Date(checkDate), 'MMM dd h:mm a')}`
          : 'No status information available yet.'}
      </LastChecked>
    </Card>
  );
};
SubdomainStatus.propTypes = {
  subdomain: PropTypes.shape(domainShape).isRequired,
  blog: blogShape,
};
SubdomainStatus.defaultProps = {
  blog: undefined,
};
export default SubdomainStatus;

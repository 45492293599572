import React from 'react';

import { LOGIN } from '../../constants/routePaths';
import { NotFound, ServicePage } from '../common';

const NotFoundPage = () => (
  <ServicePage>
    <NotFound linkTo={LOGIN} linkText="Sign in" />
  </ServicePage>
);

export default NotFoundPage;

import { SUBSCRIPTION, USER } from '../constants/queryData';

const updateUser = (user, queryClient) => {
  if (user) {
    queryClient.setQueryData(USER, user);
  }

  return user;
};

const updateSubscription = (subscription, queryClient) => {
  if (subscription) {
    // wrapped in an object because that is how the data from the query is cached.
    queryClient.setQueryData(SUBSCRIPTION, { subscription });
  }

  return subscription;
};

export default { updateSubscription, updateUser };

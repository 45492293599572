import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import PayoutMethod from '../PayoutMethod';
import useAffiliateTransactions from '../../../../hooks/useAffiliateTransactions';
import AffiliateTransactions from '../AffiliateTransactions';
import { PAGINATION_LIMIT } from '../../../../constants';

const AffiliateCreditsTab = () => {
  const [offset, setOffset] = useState(0);
  const { transactions, total, isLoading, isSuccess, isError } = useAffiliateTransactions(offset);
  return (
    <Box py={1}>
      {isLoading && (
        <Box textAlign="center" mt={1}>
          <CircularProgress size={22} />
        </Box>
      )}
      {isError && (
        <Typography variant="body2" color="error" textAlign="center">
          Failed to load affiliate credits
        </Typography>
      )}
      {isSuccess && (
        <>
          {transactions.length > 0 && <PayoutMethod />}
          <AffiliateTransactions transactions={transactions} />
          {total > PAGINATION_LIMIT && (
            <Box mt={2} display="flex" justifyContent="center">
              <Pagination
                shape="rounded"
                sx={{ textAlign: 'center' }}
                limit={PAGINATION_LIMIT}
                page={offset / PAGINATION_LIMIT + 1}
                count={Math.ceil(total / PAGINATION_LIMIT)}
                onChange={(e, next) => setOffset((next - 1) * PAGINATION_LIMIT)}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
export default AffiliateCreditsTab;

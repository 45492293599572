import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';

import SubscriptionPlan from './SubscriptionPlan';
import ExternalBlogPlan from './ExternalBlogPlan';

const PlanContainer = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const SubscriptionPlans = ({ plans, onChoosePlan }) => {
  const planViews = plans.map((plan) => (
    <PlanContainer key={plan.id} item xs={12} md={4}>
      {plan.external ? (
        <ExternalBlogPlan plan={plan} />
      ) : (
        <SubscriptionPlan plan={plan} onChoosePlan={onChoosePlan} />
      )}
    </PlanContainer>
  ));

  return (
    <Grid container spacing={1}>
      {planViews}
    </Grid>
  );
};

SubscriptionPlans.defaultProps = {
  onChoosePlan: undefined,
};

SubscriptionPlans.propTypes = {
  onChoosePlan: PropTypes.func,
  plans: PropTypes.arrayOf(
    PropTypes.oneOfType([SubscriptionPlan.propTypes.plan, ExternalBlogPlan.propTypes.plan])
  ).isRequired,
};

export default SubscriptionPlans;

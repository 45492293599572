import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme, { palette, fonts } from '../../../theme';

export const DomainName = styled(Typography)`
  font-family: ${fonts.atypText};
  font-weight: 700;
  text-align: left;
  font-size: 1.1rem;
`;

export const RefreshDNS = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 1rem 0;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
  }

  & .lastUpdated {
    color: #bbb;
    font-size: 0.9rem;
    font-style: italic;
    word-break: break-all;
  }
`;

export const LastChecked = styled(Box)`
  text-align: left;
  color: ${palette.mediumGrey};
  font-weight: 100;
  font-style: italic;
`;

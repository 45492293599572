import { useQuery } from 'react-query';

import { getBlogPosts } from '../api/external';
import { BLOG_POSTS, DEFAULT_QUERY_SETTINGS } from '../constants/queryData';

export default (settings = {}) => {
  const { data: recentBlogPosts = [], ...rest } = useQuery(BLOG_POSTS, () => getBlogPosts(), {
    ...DEFAULT_QUERY_SETTINGS,
    ...settings,
  });
  return { recentBlogPosts, ...rest };
};

import styled from '@emotion/styled';
import { Container } from '@mui/material';

const PageContent = styled(Container)`
  min-height: calc(100vh - 118px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default PageContent;

/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { ACCOUNT_SUBSCRIBE } from '../../constants/routePaths';

const SubscribeButton = ({ children = 'Subscribe', to = ACCOUNT_SUBSCRIBE }) => {
  const history = useHistory();
  const onGoSubscribe = () => {
    history.push(to);
  };
  return (
    <Button onClick={onGoSubscribe} variant="subscribe" underline="hover">
      {children}
    </Button>
  );
};

SubscribeButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

export default SubscribeButton;

import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { Box, Card } from '@mui/material';

import {
  Alert,
  Button,
  TextInput,
  Link,
  LoadingIndicator,
  When,
  ServicePage,
  SubTitle,
} from '../common';
import { signup } from '../../api';
import { CREATE, LOGIN, SIGNUP } from '../../constants/routePaths';
import { yup, buildSubmitHandler, handleError } from '../../utils';
import PageContent from '../common/PageContent';
import FormFooter from '../common/FormFooter';
import OuterCardLogo from '../common/OuterCardLogo';
import CardText from '../common/CardText';
import Transition from '../common/Transition';

const locationUrl = new URL(document.location.href);
const returnUrl = ['return_url', 'redirect_url', 'store_url'].reduce(
  (accumulation, value) => locationUrl.searchParams.get(value) || accumulation,
  ''
);
const emailAddress = ['email_address', 'emailaddress', 'emailAddress', 'email'].reduce(
  (accumulation, value) => locationUrl.searchParams.get(value) || accumulation,
  ''
);
const returnUrlButtonText = ['return_button', 'returnbutton', 'returnButton'].reduce(
  (accumulation, value) => locationUrl.searchParams.get(value) || accumulation,
  'Return and Sign In'
);

const AccountSignup = () => {
  const history = useHistory();
  const [state] = useState({ emailAddress, returnUrlButtonText, returnUrl });
  const { mutate } = useMutation(({ values }) => signup(values), {
    onSuccess: (data) => {
      history.push(CREATE, { ...state, emailAddress: data.emailAddress });
    },
    onError: handleError,
  });
  const { errors, values, handleSubmit, submitForm, handleChange, touched, isSubmitting } =
    useFormik({
      initialValues: { emailAddress },
      validationSchema: yup.createSchema({
        emailAddress: yup.emailAddress,
      }),
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: buildSubmitHandler(mutate),
    });

  useEffect(() => {
    history.replace(`${SIGNUP}${window.location.search}`);
  }, [history]);

  useEffect(() => {
    if (state.emailAddress !== '') {
      submitForm();
    }
  }, [state, submitForm]);

  return (
    <ServicePage>
      <PageContent maxWidth="xs">
        <OuterCardLogo />
        <Card>
          <SubTitle cardTitle sx={{ fontSize: '1.25rem' }}>
            Get Started with Showit
          </SubTitle>
          <CardText>
            Start your free 14-day trial and experience the creative freedom enjoyed by thousands of
            other creatives like you.
          </CardText>
          <When it={isSubmitting}>
            <LoadingIndicator />
          </When>
          <When
            it={errors.general}
            render={() => (
              <Transition>
                <Alert severity="error" variant="outlined" noBorder>
                  {errors.general}
                </Alert>
              </Transition>
            )}
          />
          <When it={!isSubmitting}>
            <form onSubmit={handleSubmit}>
              <TextInput
                type="text"
                variant="outlined"
                label="Your email"
                name="emailAddress"
                value={values.emailAddress}
                onChange={handleChange}
                error={touched.emailAddress && !!errors.emailAddress}
                helperText={touched.emailAddress && errors.emailAddress}
              />
              <Box mb={3}>
                <Button fullWidth type="submit" variant="contained">
                  Get Started
                </Button>
              </Box>
              <FormFooter>
                <Link component={RouterLink} to={LOGIN} underline="hover">
                  Already have an account? Sign in here.
                </Link>
              </FormFooter>
            </form>
          </When>
        </Card>
      </PageContent>
    </ServicePage>
  );
};

export default AccountSignup;

import React from 'react';
import { user as UserType } from '../../../../domain/PropTypes';
import { SubscribeButton } from '../../../common';
import { getTrialDaysRemainingMessage } from '../../../../utils/dateTime';
import DashboardNoticeWrapper from './DashboardNoticeWrapper';

const AccountInTrial = ({ user }) => {
  const message = getTrialDaysRemainingMessage(user.expiration);
  return (
    <DashboardNoticeWrapper
      message={message}
      cta={<SubscribeButton>Subscribe Now</SubscribeButton>}
    />
  );
};

AccountInTrial.propTypes = {
  user: UserType.isRequired,
};

export default AccountInTrial;

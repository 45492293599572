import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const Icon = ({ sx, style }) => (
  <SvgIcon sx={sx} style={style} viewBox="0 0 24 24">
    <path d="M17.16,9.52c-.36,0-.65-.28-.65-.63,.04-1.69-.45-3.35-1.4-4.77-.86-1.66-2.95-2.32-4.67-1.5-.67,.32-1.22,.85-1.55,1.5-.95,1.42-1.43,3.08-1.4,4.77,0,.35-.29,.63-.65,.63s-.65-.28-.65-.63h0c-.04-1.94,.53-3.85,1.63-5.49,1.26-2.23,4.16-3.05,6.47-1.84,.81,.42,1.47,1.06,1.91,1.84,1.1,1.63,1.66,3.54,1.63,5.49,0,.35-.29,.63-.65,.63h0Z" />
    <path d="M20.35,5.65h-2.12c.31,1.05,.47,2.14,.46,3.23,.07,.81-.55,1.53-1.39,1.6-.84,.07-1.59-.53-1.66-1.34,0-.09,0-.17,0-.26,.01-1.11-.21-2.21-.64-3.23h-5.98c-.44,1.02-.66,2.12-.64,3.23,.07,.81-.55,1.53-1.39,1.6-.84,.07-1.59-.53-1.66-1.34,0-.09,0-.17,0-.26,0-1.09,.15-2.18,.46-3.23H3.65c-.36,0-.65,.28-.65,.63V22.37c0,.35,.29,.63,.65,.63H20.35c.36,0,.65-.28,.65-.63V6.29c0-.35-.29-.63-.65-.63Z" />
  </SvgIcon>
);

Icon.propTypes = {
  sx: PropTypes.shape({
    fontSize: PropTypes.string,
    color: PropTypes.string,
  }),
  style: PropTypes.shape({}),
};

Icon.defaultProps = {
  sx: {},
  style: {},
};

export default Icon;

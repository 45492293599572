/**
 * https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-portal-instance-object
 */
import { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { getChargebeePortalSession, syncChargebeeSubscription } from '../api';
import getChargebee from '../utils/getChargebee';
import queryData from '../utils/queryData';
import { CHARGEBEE_SUBSCRIPTION_ERROR } from '../constants/errorMessages';

const useChargebeePortal = ({ userId } = {}) => {
  const [portalClosed, setPortalClosed] = useState(false);
  const [portalError, setPortalError] = useState(false);
  const [syncNeeded, setSyncNeeded] = useState(false);
  const queryClient = useQueryClient();

  const {
    mutate: syncSubscription,
    isIdle: isWaitingToSyncSubscription,
    reset: resetSubscriptionSync,
  } = useMutation(syncChargebeeSubscription);

  const open = () => {
    setSyncNeeded(false);
    setPortalClosed(false);
    setPortalError(false);
    resetSubscriptionSync();
    const { cbInstance, sections } = getChargebee();
    cbInstance.setPortalSession(getChargebeePortalSession);
    const portal = cbInstance.createChargebeePortal();
    portal.open({
      close: () => {
        setPortalError(false);
        setPortalClosed(true);
      },
      visit: (sectionType) => {
        if (sectionType === sections.SUBSCRIPTION_DETAILS) {
          setSyncNeeded(true);
        }
      },
      error: (err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setPortalError({ message: CHARGEBEE_SUBSCRIPTION_ERROR, originalError: err });
      },
    });
  };

  useEffect(() => {
    if (portalClosed && syncNeeded && isWaitingToSyncSubscription && userId) {
      const run = async (subscriptionUserId) => {
        try {
          await syncSubscription(subscriptionUserId, {
            onSuccess: (data) => {
              const { account, subscription } = data;
              queryData.updateUser(account, queryClient);
              queryData.updateSubscription(subscription, queryClient);
            },
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      };
      run(userId);
    }
  }, [
    portalClosed,
    syncNeeded,
    syncSubscription,
    isWaitingToSyncSubscription,
    queryClient,
    userId,
  ]);

  return { open, portalError };
};

export default useChargebeePortal;

import React, { useRef, useState } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { Card } from '@mui/material';

import { Alert, Button, Link, TextInput, ServicePage, When, SubTitle } from '../common';
import CardText from '../common/CardText';
import { forgotPassword } from '../../api';
import { LOGIN } from '../../constants/routePaths';
import { yup, buildSubmitHandler, handleError } from '../../utils';
import OuterCardLogo from '../common/OuterCardLogo';
import PageContent from '../common/PageContent';
import FormFooter from '../common/FormFooter';
import Transition from '../common/Transition';

const ForgotPassword = () => {
  const history = useHistory();
  const [helpSent, setHelpSent] = useState(false);
  const { mutate } = useMutation(({ values }) => forgotPassword(values), {
    onSuccess: () => setHelpSent(true),
    onError: handleError,
  });

  const inputRefs = useRef({});
  const setInputRef = (element) => {
    if (element != null) {
      inputRefs.current[element.name] = element;
    }
  };

  const { errors, values, handleSubmit, handleChange, touched, isSubmitting } = useFormik({
    initialValues: {
      emailAddress: '',
    },
    validationSchema: yup.createSchema({ emailAddress: yup.emailAddress }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: buildSubmitHandler(mutate),
  });

  return (
    <ServicePage>
      <PageContent maxWidth="xs">
        <OuterCardLogo />
        <Card>
          <SubTitle cardTitle>Forgot Your Password?</SubTitle>
          <When it={helpSent}>
            <Transition timeout={4000} callback={() => history.push(LOGIN)}>
              <Alert variant="outlined" severity="success" noBorder>
                Password reset request submitted.
              </Alert>
            </Transition>
            <CardText>
              If the email you entered is valid, you&apos;ll receive an email with instructions for
              resetting your account&apos;s password shortly.
            </CardText>
          </When>
          <When it={!helpSent}>
            <CardText>
              Enter the email address for your Showit account and we&apos;ll send you instructions
              for resetting your password.
            </CardText>
            <When it={!!errors.general}>
              <Transition>
                <Alert severity="error" variant="outlined" noBorder>
                  {errors.general}
                </Alert>
              </Transition>
            </When>

            <form onSubmit={handleSubmit}>
              <TextInput
                inputRef={setInputRef}
                type="text"
                label="Your Email"
                variant="outlined"
                name="emailAddress"
                value={values.emailAddress}
                onChange={(e) => {
                  e.target.value = e.target.value.trim();
                  handleChange(e);
                }}
                error={touched.emailAddress && !!errors.emailAddress}
                helperText={touched.emailAddress && errors.emailAddress}
              />
              <Button type="submit" fullWidth loading={isSubmitting} sx={{ marginBottom: '24px' }}>
                Reset Password
              </Button>
              <FormFooter>
                <Link component={RouterLink} to={LOGIN} underline="hover">
                  Cancel
                </Link>
              </FormFooter>
            </form>
          </When>
        </Card>
      </PageContent>
    </ServicePage>
  );
};

export default ForgotPassword;

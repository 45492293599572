import { ACTIVE, INACTIVE, TRIAL, TRIAL_ENDED } from '../constants/accountStatuses';
import { NORMAL } from '../constants/userTypes';
import queryClient from '../config/queryClient';
import logoutChargebee from './logoutChargebee';
import { logout } from '../api';
import { isAuthorized } from '../api/session';

export const canSubscribe = ({ status, userType, emailConfirmed }) => (
  (status === TRIAL || status === TRIAL_ENDED || status === INACTIVE) &&
  userType === NORMAL &&
  emailConfirmed
);

export const canHaveSubscription = ({ status, userType }) => (status === ACTIVE || status === INACTIVE) && userType === NORMAL;

export const isInTrial = ({ status }) => status === TRIAL;

export const logoutUser = () => {
  queryClient.clear();
  logoutChargebee();
  if (isAuthorized()) {
    logout();
  }
};

import React from 'react';
import { Box, Card } from '@mui/material';
import { user as UserType, subscription as SubscriptionType } from '../../../../domain/PropTypes';
import { SubTitle } from '../../../common';
import { ACTIVE, INACTIVE, TRIAL, TRIAL_ENDED } from '../../../../constants/accountStatuses';
import StatusActive from './StatusActive';
import StatusInactive from './StatusInactive';
import StatusTrial from './StatusTrial';
import StatusTrialEnded from './StatusTrialEnded';

const AccountStatusMap = {
  [ACTIVE]: StatusActive,
  [INACTIVE]: StatusInactive,
  [TRIAL]: StatusTrial,
  [TRIAL_ENDED]: StatusTrialEnded,
};

const SubscriptionCard = ({ user, subscription }) => {
  const { status } = user;
  const StatusView = AccountStatusMap[status];

  return (
    <Card elevation={0} sx={{ backgroundColor: '#fafafb', marginBottom: '2em' }}>
      <SubTitle style={{ textAlign: 'left' }}>Current Plan</SubTitle>
      {StatusView && (
        <Box pt={1}>
          <StatusView user={user} subscription={subscription} />
        </Box>
      )}
    </Card>
  );
};

SubscriptionCard.propTypes = {
  user: UserType.isRequired,
  subscription: SubscriptionType.isRequired,
};

export default SubscriptionCard;

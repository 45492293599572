import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Link, Typography } from '@mui/material';
import config from '../../../config';
import { Button, Instruction, SubTitle, When } from '../../common';
import useBrowserInfo from '../../../hooks/useBrowserInfo';
import { ACCOUNT } from '../../../constants/routePaths';

const { DESIGN_APP_URL } = config;

const Completed = () => {
  const { designAppSupported } = useBrowserInfo();
  return (
    <Container
      maxWidth="xs"
      sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}
    >
      <SubTitle align="center">Welcome to Showit</SubTitle>
      <Typography mt={2} mb={3} align="center">
        Thank you for subscribing!
      </Typography>
      <When it={designAppSupported}>
        <Button component={Link} href={DESIGN_APP_URL} fullWidth style={{ marginBottom: '1em' }}>
          Get back to your design
        </Button>
      </When>
      <When it={!designAppSupported}>
        <Box>
          <Instruction align="center">
            You can now continue designing your website using Showit! Just open{' '}
            <Link href={DESIGN_APP_URL} underline="hover">
              {DESIGN_APP_URL}
            </Link>{' '}
            using the Chrome browser on your laptop or desktop computer.
          </Instruction>
        </Box>
      </When>
      <Link to={ACCOUNT} component={RouterLink} underline="hover" align="center">
        Back to Home
      </Link>
    </Container>
  );
};

export default Completed;

import platform from 'platform';
import { DESIGN_APP_SUPPORTED_BROWSERS } from '../constants';

export function isDesignAppSupported(browserName, browserVersion, supportedBrowsers) {
  const name = browserName ? browserName.toLowerCase() : '';
  const version = parseInt(browserVersion, 10);
  return !!supportedBrowsers[name] && version >= supportedBrowsers[name].minVersion;
}

const designAppSupported = isDesignAppSupported(
  platform.name,
  platform.version,
  DESIGN_APP_SUPPORTED_BROWSERS
);

function useBrowserInfo() {
  return { designAppSupported };
}

export default useBrowserInfo;

import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import useUser from '../../../hooks/useUser';
import useContributorAccess from '../../../hooks/useContributorAccess';

const AccountSelect = ({ value, onBlur, onChange }) => {
  const { user } = useUser({
    enabled: true,
    refetchOnWindowFocus: 'always',
  });

  const { accessibleAccounts = [] } = useContributorAccess();

  return (
    <TextField
      select
      style={{ textAlign: 'left' }}
      label="Account"
      name="account"
      variant="outlined"
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      SelectProps={{
        native: true,
      }}
    >
      <option value={user.id}>{`${user.firstName} ${user.lastName} (${user.emailAddress})`}</option>
      {accessibleAccounts.map(({ id, ownerUserId, firstName, lastName, ownerEmail }) => (
        <option value={ownerUserId} key={id}>
          {`${firstName} ${lastName} (${ownerEmail})`}
        </option>
      ))}
    </TextField>
  );
};

AccountSelect.propTypes = {
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

AccountSelect.defaultProps = {
  value: '',
  onBlur: undefined,
  onChange: undefined,
};

export default AccountSelect;

/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Snackbar,
  Alert,
  Box,
  Typography,
} from '@mui/material';
import NoIcon from '@mui/icons-material/RemoveCircle';
import AddIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import PendingIcon from '@mui/icons-material/AccessTimeOutlined';
import PropTypes from 'prop-types';
import { When } from '../../../common';
import { DNS_SHOULD_NOT_EXIST, DNS_UNKNOWN } from '../../../../constants/domainConstants';
import { isRecordPending } from '../../../../utils/domainTools';

const copyCellStyles = { '&:hover': { cursor: 'copy' } };

const DNSTable = ({ records }) => {
  const [copied, setCopied] = useState(false);
  const [showCopiedToast, setShowCopiedToast] = useState(false);
  const onCopyValue = async ({ target }) => {
    try {
      await navigator.clipboard.writeText(target?.textContent);
      setCopied(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error copying to clipboard', err);
      setCopied(false);
    } finally {
      setShowCopiedToast(true);
    }
  };
  return (
    <>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <TableContainer sx={{ width: 'auto' }}>
          <Table size="small" sx={{ width: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Type</strong>
                </TableCell>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Value</strong>
                </TableCell>
                <TableCell>
                  <strong>Note</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((record) => {
                const isPending = isRecordPending(record);
                return (
                  <TableRow key={record.dnsId}>
                    <TableCell
                      title="Click to copy value"
                      sx={copyCellStyles}
                      onClick={onCopyValue}
                    >
                      {record.recordType}
                    </TableCell>
                    <TableCell
                      title="Click to copy value"
                      sx={copyCellStyles}
                      onClick={onCopyValue}
                    >
                      {record.host}
                    </TableCell>
                    <TableCell
                      title="Click to copy value"
                      sx={copyCellStyles}
                      onClick={onCopyValue}
                    >
                      {record.expectedValue === DNS_SHOULD_NOT_EXIST ||
                      record.expectedValue === DNS_UNKNOWN
                        ? record.actualValue
                        : record.expectedValue}
                    </TableCell>
                    <TableCell>
                      {record.actualValue !== record.expectedValue ? (
                        <Box
                          sx={{
                            color: isPending
                              ? 'gray'
                              : record.actualValue?.length
                              ? 'error.main'
                              : 'warning.main',
                          }}
                          display="flex"
                          alignItems="center"
                        >
                          {isPending ? (
                            <PendingIcon sx={{ marginRight: '.25em' }} />
                          ) : record.actualValue?.length ? (
                            <NoIcon sx={{ marginRight: '.25em' }} />
                          ) : (
                            <AddIcon sx={{ marginRight: '.25em' }} />
                          )}
                          <Typography variant="subtitle2" fontWeight="500">
                            <em>
                              {isPending
                                ? 'Pending: Changes may take up to 24 hours to take effect.'
                                : record.actualValue?.length
                                ? `Remove: '${record.actualValue}' is not correct.`
                                : record.recordName === '*'
                                ? 'Add: This DNS record needs to be added for subdomains to be live.'
                                : 'Add: This DNS record needs to be added for the site to be live.'}
                            </em>
                          </Typography>
                        </Box>
                      ) : (
                        <Box sx={{ color: 'success.main' }} display="flex" alignItems="center">
                          <CheckIcon sx={{ marginRight: '.25em' }} />
                          <Typography variant="subtitle2" fontWeight="500">
                            <em>Correct: This is good to go.</em>
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={2} display={{ xs: 'block', sm: 'none' }}>
        {records.map((record, i) => {
          const isPending = isRecordPending(record);
          return (
            <Box key={record.dnsId} pb={i !== records.length - 1 && 2}>
              <Stack>
                <Box>
                  <strong>Type:</strong> {record.recordType}
                </Box>
                <Box>
                  <strong>Name:</strong> {record.host}
                </Box>
                <Box>
                  <strong>Value:</strong> {record.expectedValue}
                </Box>
                <When it={record.actualValue !== record.expectedValue}>
                  <Box
                    sx={{
                      color: isPending
                        ? 'gray'
                        : record.actualValue?.length
                        ? 'error.main'
                        : 'warning.main',
                    }}
                  >
                    <Typography variant="subtitle2" fontWeight="100">
                      <strong>Note:</strong>{' '}
                      <em>
                        {isPending
                          ? 'Record is pending'
                          : record.actualValue?.length
                          ? `Remove: '${record.actualValue}' is not correct.`
                          : 'Add this record'}
                      </em>
                    </Typography>
                  </Box>
                </When>
              </Stack>
            </Box>
          );
        })}
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showCopiedToast}
        autoHideDuration={2000}
        onClose={() => setShowCopiedToast(false)}
      >
        <Alert
          onClose={() => setShowCopiedToast(false)}
          severity={copied ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {copied ? 'Copied!' : 'Error copying to clipboard'}
        </Alert>
      </Snackbar>
    </>
  );
};

DNSTable.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      recordType: PropTypes.string,
      recordName: PropTypes.string,
      expectedValue: PropTypes.string,
      dnsId: PropTypes.number,
      actualValue: PropTypes.string,
    })
  ),
};

DNSTable.defaultProps = {
  records: [],
};

export default DNSTable;

import React, { useState, createRef } from 'react';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { IconButton, Link, useMediaQuery } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import CustomTooltip from '../../../common/Tooltip/Tooltip';
import TimedButton from './TimedButton';
import { When } from '../../../common';
import Links from '../../../../constants/links';
import InfoDialog from '../../../common/Dialog/InfoDialog';
import { RefreshDNS } from '../Styles';

const tooltipText =
  'This button will run a check against your DNS records to pull in the latest information. It can be run once every 5 minutes.';

const RefreshDNSButton = ({ lastCheck, onClick, isLoading, isError, reset, buttonLabel }) => {
  const [hoverOpen, setHoverOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <RefreshDNS>
      <CustomTooltip
        title={tooltipText}
        arrow
        placement="top"
        open={hoverOpen}
        onClose={() => setHoverOpen(false)}
        onOpen={() => setHoverOpen(true)}
        leaveTouchDelay={0}
      >
        <span>
          <TimedButton
            ref={createRef()}
            label={buttonLabel}
            loading={isLoading}
            referenceTime={lastCheck}
            onClick={onClick}
            sx={{ marginRight: '.5em' }}
          />
          <When it={isMobile}>
            <IconButton onClick={() => setHoverOpen(true)}>
              <HelpOutline />
            </IconButton>
          </When>
        </span>
      </CustomTooltip>

      <InfoDialog open={!!isError} onClose={reset} title="Error">
        <>
          Something went wrong while checking your site. Please try again later. If the issue
          persists please{' '}
          <Link target="_blank" href={Links.HELP_URL}>
            contact support
          </Link>
        </>
      </InfoDialog>
    </RefreshDNS>
  );
};

RefreshDNSButton.propTypes = {
  lastCheck: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

RefreshDNSButton.defaultProps = {
  lastCheck: '',
};

export default RefreshDNSButton;

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Home from '@mui/icons-material/Home';
import Repeat from '@mui/icons-material/Repeat';
import Person from '@mui/icons-material/Person';
import Message from '@mui/icons-material/Message';
import PowerOff from '@mui/icons-material/PowerOff';
import LeakAdd from '@mui/icons-material/LeakAdd';
import Language from '@mui/icons-material/Language';
import RedeemIcon from '@mui/icons-material/Redeem';
import MenuLink from './MenuLink';
import {
  ACCOUNT,
  ACCOUNT_MESSAGES,
  ACCOUNT_THIRD_PARTY,
  ACCOUNT_PROFILE,
  ACCOUNT_SUBSCRIPTION,
  LOGIN,
  ACCOUNT_DOMAINS,
  ACCOUNT_REFERRALS,
} from '../../../constants/routePaths';
import { palette } from '../../../theme';
import { logoutUser } from '../../../utils/user';

const styles = {
  menu: {
    width: 200,
    color: palette.primary.main,
    padding: '0 0 10px 10px',
  },
};

const MenuDrawer = ({ open, onClose }) =>
    <Drawer open={open} onClose={onClose} anchor="right">
      <Box sx={styles.menu}>
        <Box
          mr={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          style={{ height: 64 }}
        >
          <IconButton aria-label="close menu" onClick={onClose} size="large">
            <Close style={{ fontSize: '35px', color: palette.primary.main }} />
          </IconButton>
        </Box>
        <Divider />
        <MenuLink to={ACCOUNT} text="Home" icon={<Home />} onClick={onClose} />
        <MenuLink to={ACCOUNT_PROFILE} text="Account" icon={<Person />} onClick={onClose} />
        <MenuLink
          to={ACCOUNT_SUBSCRIPTION}
          text="Subscription"
          icon={<Repeat />}
          onClick={onClose}
        />
        <MenuLink to={ACCOUNT_MESSAGES} text="Messages" icon={<Message />} onClick={onClose} />
        <MenuLink
          to={ACCOUNT_THIRD_PARTY}
          text="Integrations"
          icon={<LeakAdd />}
          onClick={onClose}
        />
        <MenuLink to={ACCOUNT_DOMAINS} text="Domains" icon={<Language />} onClick={onClose} />
        <MenuLink to={ACCOUNT_REFERRALS} text="Referrals" icon={<RedeemIcon />} onClick={onClose} />
        <MenuLink to={LOGIN} text="Sign Out" icon={<PowerOff />} onClick={logoutUser} />
      </Box>
    </Drawer>

MenuDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MenuDrawer;

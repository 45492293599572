import React, { useEffect, useState, useCallback } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Box, CircularProgress, Card } from '@mui/material';

import { Button, ServicePage, Link, SubTitle } from '../common';
import PasswordUpdateForm from '../common/PasswordUpdateForm';
import { resetPassword, sessionVerify } from '../../api';
import { LOGIN, PASSWORD_FORGOT, PASSWORD_RESET } from '../../constants/routePaths';
import { PASSWORD_RESET_SESSION_VERIFY } from '../../constants/queryData';
import PageContent from '../common/PageContent';
import OuterCardLogo from '../common/OuterCardLogo';
import CardText from '../common/CardText';

const locationUrl = new URL(document.location.href);
const resetSession = {
  token: locationUrl.searchParams.get('action'),
  tokenExpires: locationUrl.searchParams.get('exp'),
};

const Expired = () => (
  <>
    <SubTitle my={2}>Password Reset Expiration</SubTitle>
    <CardText>
      Sorry, the link to update your password has expired. To request a new link click&nbsp;
      <Link component={RouterLink} to={PASSWORD_FORGOT}>
        here
      </Link>
      .
    </CardText>
  </>
);

const Success = () => (
  <>
    <SubTitle my={2}>Password Reset Successful</SubTitle>
    <CardText>Your password has been set! Sign in with your updated password.</CardText>
    <Box my={2}>
      <Button component={RouterLink} to={LOGIN} fullWidth>
        Sign In
      </Button>
    </Box>
  </>
);

const Loading = () => (
  <>
    <SubTitle my={2}>Loading...</SubTitle>
    <Box display="flex" justifyContent="center" mb={2}>
      <CircularProgress />
    </Box>
  </>
);

const Reset = () => {
  const history = useHistory();
  const [{ token, tokenExpires }] = useState(resetSession);
  const { data, isLoading } = useQuery(PASSWORD_RESET_SESSION_VERIFY, () => sessionVerify(token));
  const isExpired =
    tokenExpires <= Math.ceil(Date.now() / 1000) - 60 || data?.validSession === false;
  const [passwordChanged, setPasswordChanged] = useState(false);

  const resetUserPassword = useCallback((values) => resetPassword(token, values), [token]);

  useEffect(() => {
    history.replace(PASSWORD_RESET);
  }, [history]);

  const view = isLoading ? (
    <Loading />
  ) : isExpired ? (
    <Expired />
  ) : passwordChanged ? (
    <Success />
  ) : (
    <PasswordUpdateForm
      onSubmit={resetUserPassword}
      onSuccess={setPasswordChanged}
      actionTitle="Reset"
    />
  );

  return (
    <ServicePage>
      <PageContent maxWidth="xs">
        <OuterCardLogo />
        <Card>{view}</Card>
      </PageContent>
    </ServicePage>
  );
};

export default Reset;

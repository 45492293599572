import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import theme from '../../../../theme';
import { When } from '../../../common';

const StyledContributorButtons = styled(Box)`
  display: flex;

  &.mobile {
    & button {
      padding-bottom: 0;
      padding-top: 0;
    }
    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  }

  &.desktop {
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  }
`;

const ContributorButtons = ({ isActive, className = '', onPause, onRemove, pauseable = false }) => {
  const pauseIconTitle = `${isActive ? 'Pause' : 'Resume'} contributor access for this user`;
  const removeIconTitle = `Remove ${pauseable ? 'contributor' : 'design'} access from this user`;
  return (
    <StyledContributorButtons className={`contributorButtons${className ? ` ${className}` : ''}`}>
      <When it={pauseable}>
        <IconButton
          edge="end"
          aria-label={pauseIconTitle}
          title={pauseIconTitle}
          onClick={onPause}
          size="large"
        >
          {isActive ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />}
        </IconButton>
      </When>
      <IconButton
        edge="end"
        aria-label={removeIconTitle}
        title={removeIconTitle}
        onClick={onRemove}
        size="large"
      >
        <DeleteOutlineIcon />
      </IconButton>
    </StyledContributorButtons>
  );
};

ContributorButtons.propTypes = {
  isActive: PropTypes.bool,
  className: PropTypes.string,
  onPause: PropTypes.func,
  onRemove: PropTypes.func,
  pauseable: PropTypes.bool,
};

ContributorButtons.defaultProps = {
  isActive: false,
  className: '',
  onPause: () => {},
  onRemove: () => {},
  pauseable: false,
};

export default ContributorButtons;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { palette } from '../../../../theme';
import WarningIcon from '../../../icons/WarningIcon';

const DashboardNoticeWrapper = ({ message, cta }) => (
  <Box display="flex" alignItems="center" flexDirection="column" p={1}>
    <Box display="flex" alignItems="center" mt={{ xs: 0, md: 3 }}>
      <Box
        p={1}
        mr={1}
        height="40px"
        width="40px"
        borderRadius="50%"
        backgroundColor={palette.warning.light}
      >
        <WarningIcon w="1.5rem" h="1.5rem" display="flex" />
      </Box>
      <Typography variant="body1">{message}</Typography>
    </Box>
    <Box mt={3} mb={{ xs: 3, md: 0 }}>
      {cta}
    </Box>
  </Box>
);

DashboardNoticeWrapper.propTypes = {
  cta: PropTypes.node.isRequired,
  message: PropTypes.string.isRequired,
};

export default DashboardNoticeWrapper;

import { useQuery } from 'react-query';
import { getAffiliateTransactions } from '../api';
import { AFFILIATE_TRANSACTIONS } from '../constants/queryData';
import { PAGINATION_LIMIT } from '../constants';

/**
 * Returns the user's affiliate transactions
 * @param {number} offset Used for pagination, defaults to 0
 * @returns {{ transactions: Array, total: number, ...reactQueryProps }}
 */
export default function useAffiliateTransactions(offset = 0, settings = {}) {
  const { data, ...rest } = useQuery(
    [AFFILIATE_TRANSACTIONS, offset],
    () => getAffiliateTransactions({ offset, limit: PAGINATION_LIMIT }),
    {
      keepPreviousData: true,
      ...settings,
    }
  );
  const { transactions = [], total } = data || {};
  return { transactions, total, ...rest };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import useImageLoaded from '../../hooks/useImageLoaded';

const Image = styled(Box, { shouldForwardProp: (name) => name !== 'hide' && name !== 'imageUrl' })`
  background-image: url('${({ imageUrl }) => imageUrl}');
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.5s ease;
  ${({ hide }) => (hide ? 'opacity: 0;' : '')}
`;

const ImageFilter = styled(Box)`
  height: 100%;
  width: 100%;
  opacity: 1;
  backdrop-filter: blur(40px);
`;

const BlurredLoadImage = ({ style, bigImageUrl, smallImageUrl }) => {
  const bigImageLoaded = useImageLoaded(bigImageUrl);

  return (
    <Image imageUrl={bigImageUrl} style={style}>
      <Image imageUrl={smallImageUrl} style={style} hide={bigImageLoaded}>
        <ImageFilter />
      </Image>
    </Image>
  );
};

BlurredLoadImage.defaultProps = {
  style: {},
};

BlurredLoadImage.propTypes = {
  style: PropTypes.shape({}),
  bigImageUrl: PropTypes.string.isRequired,
  smallImageUrl: PropTypes.string.isRequired,
};

export default BlurredLoadImage;

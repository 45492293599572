import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Edit from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Button, TextInput, When } from '../../../common';
import { palette } from '../../../../theme';
import { useUpdateReferralCode } from './hooks/useReferralCode';

const ReferralCodeManager = ({ referralCode }) => {
  const [state, setState] = useState({
    editingCode: false,
    newReferralCode: '',
    justCopiedReferral: false,
    justCopiedReferralUrl: false,
    showUpdateError: false,
  });
  const setInputRef = useRef();
  const referralURLPrefix = `${window.location.protocol}//${window.location.host}/r/`;
  const {
    mutate: updateReferralCode,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
    isError: isUpdateCodeError,
    error: updateCodeError,
  } = useUpdateReferralCode();

  useEffect(() => {
    setState((s) => ({ ...s, newReferralCode: referralCode.code || '' }));
  }, [referralCode.code]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setState((s) => ({ ...s, editingCode: false }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (state.editingCode) {
      setInputRef.current.focus();
    }
  }, [state.editingCode]);

  useEffect(() => {
    setState((s) => ({ ...s, showUpdateError: true }));
  }, [updateCodeError]);

  const handleReferralChange = (e) => {
    setState({
      ...state,
      newReferralCode: e.target.value.toLowerCase(),
      showUpdateError: false,
    });
  };
  const onEditCode = () => {
    setState({ ...state, editingCode: true });
  };
  const onUpdateReferralCode = () => {
    updateReferralCode({ referralCodeId: referralCode.id, code: state.newReferralCode });
  };
  const onCancelEditCode = () => {
    setState({ ...state, newReferralCode: referralCode.code, editingCode: false });
  };
  const onCopyReferralCodeUrl = () => {
    navigator.clipboard.writeText(`${referralURLPrefix}${referralCode.code}`);
    setState((s) => ({ ...s, justCopiedReferralUrl: true }));
    setTimeout(() => setState((s) => ({ ...s, justCopiedReferralUrl: false })), 2000);
  };
  const onCopyReferralCode = () => {
    navigator.clipboard.writeText(referralCode.code);
    setState((s) => ({ ...s, justCopiedReferral: true }));
    setTimeout(() => setState((s) => ({ ...s, justCopiedReferral: false })), 2000);
  };

  return (
    <Box id="referral-card" mb={3}>
      <Box
        mt={2}
        mx={{ xs: 'auto', md: 0 }}
        style={{
          maxWidth: '450px',
        }}
      >
        <When it={state.editingCode}>
          <Typography variant="body2">
            Enter your prefered referral code using only letters and numbers.{' '}
            <span style={{ color: palette.mediumGrey }}>
              Making this change will cause your existing code ({referralCode.code}) to no longer
              work. Showit reserves the right to disable codes at our discretion.
            </span>
          </Typography>
        </When>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={{ xs: 'column', md: 'row' }}
          mb={2}
          mt={3}
          data-testid="referral-code-input-container"
        >
          <When it={!state.editingCode}>
            <Box
              display="flex"
              alignItems="center"
              border={`1px solid ${palette.primary.main}`}
              borderRadius="50px"
              pl={2}
              pr={1}
            >
              <Typography onDoubleClick={onCopyReferralCode}>{referralCode.code}</Typography>
              <IconButton
                style={{ marginLeft: '5px' }}
                onClick={onEditCode}
                title="Change Referral Code"
                size="small"
              >
                <Edit />
              </IconButton>
            </Box>
          </When>
          <When it={state.editingCode}>
            <TextInput
              error={state.editingCode && isUpdateCodeError && state.showUpdateError}
              helperText={
                state.editingCode && isUpdateCodeError && state.showUpdateError
                  ? updateCodeError?.response?.data?.errors[0]?.message ||
                    'Error updating referral code'
                  : null
              }
              inputRef={setInputRef}
              type="text"
              label="Referral Code"
              name="referralCode"
              pattern="[a-zA-Z0-9]+"
              value={state.newReferralCode}
              onFocus={() => setInputRef.current.select()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') onUpdateReferralCode();
              }}
              onChange={handleReferralChange}
            />
          </When>
          <When it={!state.editingCode}>
            <Box ml={{ xs: 0, md: 2 }} mt={{ xs: 1, md: 0 }}>
              <Button type="submit" size="small" onClick={onCopyReferralCode}>
                <ContentCopyIcon fontSize="small" />
                &nbsp;{state.justCopiedReferral ? 'Copied!' : 'Code'}
              </Button>
            </Box>
          </When>
        </Box>
        <When it={state.editingCode}>
          <Box mb={4} display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
            <Button
              loading={isUpdateLoading}
              type="submit"
              variant="primary"
              sx={{ mr: { xs: 0, md: 1 }, mb: { xs: 1, md: 0 } }}
              disabled={!state.newReferralCode || state.newReferralCode === referralCode.code}
              onClick={onUpdateReferralCode}
            >
              Update Referral Code
            </Button>
            <Button type="text" variant="text" color="primary" onClick={onCancelEditCode}>
              Cancel
            </Button>
          </Box>
        </When>
        <When it={!state.editingCode}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={{ xs: 4, md: 0 }}
            flexDirection={{ xs: 'column', md: 'row' }}
            data-testid="referral-code-input-container"
          >
            <Typography>
              {referralURLPrefix}
              {referralCode.code}
            </Typography>
            <Box ml={{ xs: 0, md: 2 }} mt={{ xs: 1, md: 0 }}>
              <Button type="submit" size="small" onClick={onCopyReferralCodeUrl}>
                <ContentCopyIcon fontSize="small" />
                &nbsp;{state.justCopiedReferralUrl ? 'Copied!' : 'Link'}
              </Button>
            </Box>
          </Box>
        </When>
      </Box>
    </Box>
  );
};

ReferralCodeManager.propTypes = {
  referralCode: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
  }).isRequired,
};
export default ReferralCodeManager;

// eslint-disable-next-line import/no-mutable-exports
let config = {
  API_ENDPOINT: null,
  DESIGN_APP_URL: null,
  TOS_URL: 'https://showit.com/terms-of-service/',
  MESSAGE_TOS_URL: 'https://showit.com/sms-terms-of-service/',
  SHOWIT_MAIN_URL: 'https://showit.com/',
  PRIVACY_URL: 'https://showit.com/privacy/',
  TRAINING_URL: 'https://showit.com/training',
  SUBSCRIPTION_PLANS_URL: 'https://showit.com/pricing',
  CONTACT_SUPPORT_LEARN_URL:
    'https://learn.showit.com/en/articles/615304-contact-the-showit-support-team',
  ADD_CONTACT_FORM_LEARN_URL: 'https://learn.showit.com/en/articles/358142-contact-form',
  APP_API: '',
  CHECK_APP_VERSION: true,
  INTERCOM_APP_ID: null,
  APP_VERSION: window.APP_VERSION,
  CHARGEBEE_SITE: '',
  TEST: false,
  DISABLE_SMS_SIGNUP: false,
};

if (process.env.NODE_ENV === 'development') {
  config.API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  config.DESIGN_APP_URL = process.env.REACT_APP_DESIGN_APP_URL;
  config.APP_API = process.env.REACT_APP_TEST_API_ENDPOINT || '';
  config.CHECK_APP_VERSION = false;
  config.APP_VERSION = process.env.REACT_APP_APP_VERSION;
  config.CHARGEBEE_SITE = process.env.REACT_APP_CHARGEBEE_SITE;
  config.INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
  config.DISABLE_SMS_SIGNUP = /true/gi.test(process.env.REACT_APP_DISABLE_SMS_SIGNUP);
  config.TEST = true;
} else {
  const configElement = document.getElementById('app-config');
  try {
    config = { ...config, ...JSON.parse(configElement.textContent) };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error Parsing App Config');
  }
}

export default config;

import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from '@mui/material';

import config from '../../../../config';
import { Button } from '../../../common';

const SMSTermsDialog = ({ open, onClose, confirmTerms }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle> Showit Account Notifications Terms of Service</DialogTitle>
    <DialogContent>
      <DialogContentText>
        1. When a visitor submits a contact form on your Showit website, Showit Account
        Notifications will send an SMS message containing a link to your Showit account. This link
        will allow you to view the submitted form data. These SMS messages will only be sent to the
        phone number authorized in your Showit account to receive contact form notifications.
        Messages will be sent from one of the following numbers:{' '}
        {config.TEST
          ? '+1(480) 405-0574 or SH0W1TT3ST.'
          : '+1(480) 386-1337, +1(855) 274-6917, or SHOWIT.'}
        <br />
        <br />
        2. You can cancel the SMS service at any time. Simply text &quot;STOP&quot; to the short
        code. After you send the SMS message &quot;STOP&quot; to us, we will reply with an SMS
        message to confirm that you have been unsubscribed. Once confirmed, you will no longer
        receive SMS messages from Showit. If you wish to rejoin, just text &quot;START&quot; or
        &quot;UNSTOP&quot; to the short code, and we will begin sending SMS messages to you again.
        <br />
        <br />
        3. If you encounter issues with the messaging program, you can respond with the keyword
        &quot;HELP&quot; for further assistance. Alternatively, you can seek help directly at
        https://showit.com/help or contact us at help@showit.com
        <br />
        <br />
        4. Carriers are not responsible for delayed or undelivered messages.
        <br />
        <br />
        5. As always, message and data rates may apply for any messages sent to you from us and from
        you to us. You could receive up to 50 SMS messages from contact forms each day. If you have
        questions about your text plan or data plan, we recommend reaching out to your wireless
        provider for clarification.
        <br />
        <br />
        6. If you have any questions regarding privacy, please read our privacy policy:&nbsp;
        <Link target="_blank" href={config.PRIVACY_URL} underline="hover">
          Privacy Policy
        </Link>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          confirmTerms();
          onClose();
        }}
      >
        I agree
      </Button>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

SMSTermsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  confirmTerms: PropTypes.func.isRequired,
};

export default SMSTermsDialog;

import axios from 'axios';

const BLOG_REST_API = 'https://showit.com/wp-json/wp/v2';

const getImageUrls = (id) =>
  axios.get(`${BLOG_REST_API}/media/${id}?_fields=media_details`).then(({ data }) => data);

export const getDesigner = () =>
  axios
    .get(`${BLOG_REST_API}/splash_images?splash_image_category=854,853&_fields=acf`)
    .then(({ data: designers }) => {
      const index = Math.floor(Math.random() * designers.length);
      const { byline, image } = designers[index].acf;
      return getImageUrls(image).then(({ media_details: media }) => {
        const { sizes } = media;
        return {
          byline,
          smallImageUrl: sizes.wcicon.source_url,
          bigImageUrl: sizes.full.source_url,
        };
      });
    });

export const getFeaturettes = async () =>
  axios
    .get(
      `${BLOG_REST_API}/featurettes?_fields=acf&acf_format=standard&per_page=5&featurette_location=855`
    )
    .then(({ data }) => data);

export const getBlogPosts = async () =>
  axios
    .get(
      `${BLOG_REST_API}/posts?tag=featured&per_page=5&_fields=title,jetpack_featured_media_url,link`
    )
    .then(({ data }) => data);

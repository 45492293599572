import { differenceInDays, parseJSON, format } from 'date-fns';

export const FIVE_MINUTES_IN_SECONDS = 60 * 5;

export const getTrialDaysRemainingMessage = (iso8601EndDate, startDate = new Date()) => {
  let message = 'You are in trial mode.';

  const endDate = parseJSON(iso8601EndDate);
  const numDays = differenceInDays(endDate, startDate);
  if (numDays < 0) {
    message = 'Your trial has ended.';
  } else if (numDays === 0) {
    message = 'Your trial ends today.';
  } else if (numDays === 1) {
    message = 'Your trial ends in 1 day.';
  } else if (!Number.isNaN(numDays)) {
    message = `Your trial ends in ${numDays} days.`;
  }

  return message;
};

export const formatDateAsWords = (iso8601Date) => {
  let result = '';

  if (iso8601Date && iso8601Date.length > 0) {
    const date = parseJSON(iso8601Date);
    result = format(date, 'LLLL do yyyy');
  }

  return result;
};

export const formatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);

  return `${date.toLocaleDateString([], {
    month: 'short',
    day: '2-digit',
  })}, ${date.toLocaleTimeString()}`;
};

/**
 * @description Convert a date to a readable time string.
 * @param {String} date
 * @returns {string}
 * @example
 * dateToReadableTime(new Date() - 60seconds) // '1 minutes ago'
 * dateToReadableTime(new Date() - 60minutes) // '1 hour ago'
 * dateToReadableTime(new Date() - 24hours) // '1 day ago'
 */
export const dateToReadableTime = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const now = new Date();
  const diff = now.getTime() - date;
  const seconds = Math.ceil(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }

  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }

  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }

  return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
};

/**
 * @description Get a timestamp in seconds. Will default to the current time.
 * @param {Date} date
 * @returns {number}
 */
export const timestamp = (date = new Date()) => Math.floor(date.getTime() / 1000);

/**
 * @description Determine if current time is after the expiration date.
 * @param {Date} expiration
 * @returns {boolean}
 */
export const isExpired = (expiration) => Date.now() >= expiration.getTime();

/**
 *
 * @description Given a reference time and a countdown duration, return the time left in seconds.
 * @param {number} referenceTime - A timestamp in seconds.
 * @param {number} countdownDuration - A duration in seconds.
 * @returns {number} Time left in seconds.
 * @example
 * getTimeLeft(date.getTime(), 60) // 60
 */
export const getTimeLeft = (referenceTime, countdownDuration) => {
  const lastCheckDate = timestamp(new Date(referenceTime));
  const current = timestamp();
  const elapsedTime = current - lastCheckDate;
  const timeLeft = countdownDuration - elapsedTime;

  return elapsedTime <= countdownDuration ? timeLeft : 0;
};

/**
 * @description Format a time in seconds to a string. Will pad the seconds with a leading zero if needed.
 * @param {number} seconds - Time in seconds.
 * @returns {string} Formatted time string.
 * @example
 * formatTime(125) // '2:05'
 * formatTime(45) // '0:45'
 */
export const formatTime = (seconds) => {
  const remainingMinutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const M = `${`${remainingMinutes}`.padStart(1, '0')}`;
  const SS = `${`${remainingSeconds}`}`.padStart(2, '0');

  return `${M}:${SS}`;
};

/**
 *
 * @param {Date} date The Date object
 * @returns {string} The date in `M/d/yy` format
 * @example
 * formatMonthDayYear(new Date()); // '10/15/2001'
 * formatMonthDayYear(new Date('2024-03-15T21:43:35.000Z')); // '3/15/24'
 */
export const formatMonthDayYear = (date = new Date()) => format(date, 'M/d/yy');

import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { Button, When, Link } from '../../common';
import { THIRD_PARTY_ACCOUNTS } from '../../../constants/queryData';
import { updateInstagramConnection } from '../../../api';
import { palette } from '../../../theme';

const statusColors = {
  0: palette.primary.main, // Pending
  1: palette.success.main, // Active
  2: palette.error.main, // Inactive
};

const styles = {
  icon: {
    marginLeft: '.65rem',
    width: '1em',
    height: '1em',
  },
  helperText: {
    color: palette.error.main,
    fontSize: '.9em',
    '@media (maxWidth: 460px)': {
      display: 'none',
    },
  },
};

const ThirdPartyAccount = ({
  account,
  i,
  setConnectionStatus,
  newConnectionId,
  setNewConnectionId,
}) => {
  const queryClient = useQueryClient();
  const updatedAccount = newConnectionId === account.integrationId;
  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    if (updatedAccount) {
      setTimeout(() => setNewConnectionId(''), 3000);
    }
  }, [updatedAccount, setNewConnectionId]);

  const { mutate: removeAccount } = useMutation((update) => updateInstagramConnection(update), {
    onSuccess: () => {
      setConnectionStatus({ status: 'success', message: 'Account Removal Successful' });
      queryClient.invalidateQueries([THIRD_PARTY_ACCOUNTS]);
    },
    onError: (err) => {
      setConnectionStatus({
        status: 'error',
        message: err.error || 'There was an issue removing your account, please try again later',
      });
    },
  });

  return (
    <div
      title="Third Party Account"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className="third-party-account"
      style={{
        border: hovering ? '1px solid #eaeaea' : '1px solid transparent',
        display: 'flex',
        padding: '8px 10px',
        alignItems: 'center',
        margin: '10px 0',
        justifyContent: 'space-between',
        backgroundColor: i % 2 === 1 ? '#f8f8f8' : '#fff',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', fontSize: '1.2em' }}>
        <Link
          href={`https://instagram.com/${account.name}`}
          target="_blank"
          title="View Instagram Account"
        >
          {account.name === 'unknown' ? 'Pending' : account.name}
        </Link>
        <When it={updatedAccount}>
          <CircularProgress
            thickness={5}
            style={{ width: '1em', height: '1em', color: statusColors[0], marginLeft: '.65rem' }}
          />
        </When>
        <When it={account.status === 1 && !updatedAccount}>
          <CheckIcon
            sx={styles.icon}
            style={{ fill: statusColors[account.status] }}
            className="success"
          />
        </When>
        <When it={account.status === 2 && !updatedAccount}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <PowerOffIcon
              sx={styles.icon}
              style={{ fill: statusColors[account.status] }}
              className="error"
            />
            <i style={styles.helperText}>disconnected</i>
          </div>
        </When>
      </div>

      <div>
        {/* If account is pending or inactive, allow user to reconnect */}
        <When it={account.status === 2 && account.authorizationUrl !== undefined}>
          <Button
            startIcon={<SyncIcon />}
            color="secondary"
            title="Reconnect this Instagram account"
            size="small"
            onClick={() => window.open(account.authorizationUrl, '_self')}
          >
            Reconnect
          </Button>
        </When>
        <When it={account.status === 1}>
          <Button
            startIcon={<PowerOffIcon />}
            color="secondary"
            size="small"
            title="Disconnect this Instagram account"
            onClick={() => removeAccount({ ...account, status: 9 })}
          >
            Disconnect
          </Button>
        </When>
      </div>
    </div>
  );
};

ThirdPartyAccount.propTypes = {
  account: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    authorizationUrl: PropTypes.string,
    status: PropTypes.number,
    integrationId: PropTypes.string,
    createDate: PropTypes.string,
  }).isRequired,
  setConnectionStatus: PropTypes.func.isRequired,
  i: PropTypes.number.isRequired,
  newConnectionId: PropTypes.string.isRequired,
  setNewConnectionId: PropTypes.func.isRequired,
};

export default ThirdPartyAccount;

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component for showing children when "it" is true. Alternatively only render children when "it" is true by passing a render function
 *
 * @component
 * @example
 * const isLoading = true;
 * return (
 *   <When it={isLoading}>
 *    <Loading>
 *   </When>
 * )
 *
 * const isReady = true
 * return (
 *   <When it={isReady} render={() => <Content />} />
 * )
 */
const When = ({ it = false, render = () => null, children }) => (
  <>{it ? children || (render && render()) : null}</>
);

When.defaultProps = {
  it: false,
  children: null,
  render: null,
};

When.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  it: PropTypes.any,
  render: PropTypes.func,
  children: PropTypes.node,
};

export default When;

import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { deletePayoutMethod } from '../../../api';
import { USER_PAYOUT_METHOD } from '../../../constants/queryData';
import StatusSnackbar from '../../common/Snackbar/StatusSnackbar';
import PayPalIcon from '../../icons/PayPalIcon';
import LogInWithPayPal from './LogInWithPayPal';

const PayPalEmail = ({ email, userPayoutMethodId }) => {
  const queryClient = useQueryClient();
  const [snackbar, setSnackbar] = useState({});

  const { mutate: disconnectPayPal, isLoading } = useMutation({
    mutationFn: () => deletePayoutMethod(userPayoutMethodId),
    onSuccess: () => {
      queryClient.setQueryData(USER_PAYOUT_METHOD, {});
      setSnackbar({
        message: 'Your email was successfully disconnected',
        status: 'success',
      });
    },
    onError: (error) => {
      const [apiError] = error?.response?.data?.errors || [];
      setSnackbar({
        message: apiError ? apiError.message : 'Something went wrong.',
        status: 'error',
      });
    },
  });

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({});
  };

  return (
    <>
      <StatusSnackbar status={snackbar.status} message={snackbar.message} onClose={handleClose} />
      {email ? (
        <Box display="flex" alignItems="center">
          <Box
            py={1}
            px={3}
            display="flex"
            alignItems="center"
            bgcolor="paypalGray"
            borderRadius="12rem"
            width="fit-content"
          >
            <PayPalIcon />
            <Typography variant="body1" ml={1}>
              {email}
            </Typography>
          </Box>
          <IconButton
            sx={{ ml: 1.5 }}
            title="Disconnect PayPal email"
            disabled={isLoading}
            onClick={disconnectPayPal}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ) : (
        <LogInWithPayPal setSnackbar={setSnackbar} />
      )}
    </>
  );
};
PayPalEmail.propTypes = {
  email: PropTypes.string,
  userPayoutMethodId: PropTypes.number,
};
PayPalEmail.defaultProps = {
  email: null,
  userPayoutMethodId: null,
};
export default PayPalEmail;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '../../../common';

const SnackbarAlert = React.forwardRef((props, ref) => {
  const {
    contributorStatus: { message, status },
    ...rest
  } = props;
  const severity = !['success', 'error'].includes(status) ? 'info' : status;

  return (
    <Alert {...rest} severity={severity} ref={ref}>
      {message}
    </Alert>
  );
});
SnackbarAlert.displayName = 'SnackbarAlert';

SnackbarAlert.propTypes = {
  contributorStatus: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.string,
  }),
};

SnackbarAlert.defaultProps = {
  contributorStatus: {
    message: '',
    status: '',
  },
};

export default SnackbarAlert;

import { arrayOf, bool, number, shape, string, oneOfType } from 'prop-types';

export const user = shape({
  acceptedTos: bool,
  blogType: string,
  businessName: string,
  createDate: string,
  emailAddress: string,
  emailConfirmed: bool,
  expiration: string,
  firstName: string,
  id: number,
  industry: string,
  lastLogin: string,
  lastName: string,
  phoneNumber: string,
  status: string,
  statusDate: string,
  userType: string,
});

export const subscription = shape({
  id: number,
  name: string,
  createDate: string,
  service: string,
  status: string,
  statusDate: string,
  lastUpdated: string,
  userId: number,
  typeId: number,
  expiration: string,
  externalId: string,
});

export const blogShape = shape({
  domainId: number,
  status: number,
  hostingStatus: number,
});

export const dnsRecordShape = shape({
  domainId: number,
  recordName: string,
  recordType: string,
  expectedValue: string,
  actualValue: string,
  required: number,
  configureDate: string,
});

export const domainStatusShape = shape({
  domainId: number,
  domainName: string,
  statusCode: number,
  protocol: string,
  isShowit: bool,
  lastDNSMonitor: string,
  checkDate: string,
});

export const domainShape = {
  domainId: number,
  domainName: string,
  userId: number,
  parentDomainId: number,
  isCanonical: number,
  canonicalGroupId: string,
  status: number,
  monitors: number,
  lastUpdate: string,
  createDate: string,
};

export const apexDomainShape = shape({
  ...domainShape,
  domainStatus: domainStatusShape,
  dnsRecords: arrayOf(dnsRecordShape),
  subdomains: arrayOf(
    shape({
      ...domainShape,
      domainStatus: domainStatusShape,
      dnsRecords: arrayOf(dnsRecordShape),
    })
  ),
});

export const discountShape = shape({
  id: oneOfType(number, string),
  code: string,
  type: string,
  valid: bool,
});

/**
 * https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-portal-instance-object
 */
import getChargebee from './getChargebee';

const logoutChargebee = () => {
  const { cbInstance } = getChargebee(false);
  if (cbInstance) {
    cbInstance.closeAll();
    cbInstance.logout();
  }
};

export default logoutChargebee;

import React from 'react';
import { StatusCodes } from 'http-status-codes';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import useBlogs from '../../../hooks/useBlogs';
import DomainStatusIcons from './DomainStatusIcons';
import { fonts, palette } from '../../../theme';
import { isDomainSecure } from '../../../utils/domainTools';
import { blogTypes } from '../../../constants';
import BlogStatusMessage from './BlogStatusMessage';
import { ACCOUNT_DOMAINS } from '../../../constants/routePaths';
import WarningIcon from '../../icons/WarningIcon';
import { apexDomainShape, user as userShape } from '../../../domain/PropTypes';
import { DomainName } from './Styles';

const DomainPreview = ({ user, isDesktop, apexDomains }) => {
  const { blogType } = user;
  const hasBlogPlan = blogType === blogTypes.BASIC_BLOG || blogType === blogTypes.ADV_BLOG;
  const { blogs, isLoading: blogsLoading, isSuccess: blogsFetched } = useBlogs();

  const domain =
    apexDomains.length === 1
      ? apexDomains[0]
      : apexDomains.find(({ domainId }) => blogs.some((blog) => blog.domainId === domainId)) ||
        apexDomains[0];
  const { domainId, domainName, dnsRecords = [], domainStatus } = domain || {};
  const { statusCode, isShowit, lastDNSMonitor } = domainStatus || {};
  const blog = blogs.find((b) => b.domainId === domainId);
  const dnsIssues = dnsRecords.filter((dns) => dns.expectedValue !== dns.actualValue).length;

  return (
    <Box
      display="flex"
      flex={1}
      pb={3}
      pt={{ xs: 3, md: 0 }}
      pl={{ xs: 0, md: 3 }}
      sx={
        isDesktop
          ? { borderLeft: `1px solid ${palette.primary.main}` }
          : { borderTop: `1px solid ${palette.primary.main}` }
      }
    >
      <Card
        sx={{
          width: '100%',
          bgcolor: palette.cardBg,
        }}
      >
        {blogsLoading && <CircularProgress size={20} />}
        {blogsFetched && domainName && (
          <>
            <DomainName>{domainName}</DomainName>
            {lastDNSMonitor && (
              <DomainStatusIcons
                statusCode={statusCode}
                statusOk={statusCode === StatusCodes.OK}
                sslEnabled={isDomainSecure(domainStatus)}
                isShowit={isShowit}
                showStatusText={false}
              />
            )}
            <BlogStatusMessage
              blog={blog}
              hasBlogPlan={hasBlogPlan}
              hasBlogRecords={blogs.length > 0}
            />
            <Box mt={2}>
              {dnsIssues > 0 && (
                <Stack
                  direction="row"
                  alignItems="center"
                  mb={1}
                  sx={{
                    color: palette.warning.main,
                    fontFamily: fonts.ambit,
                  }}
                >
                  <WarningIcon w="1.1rem" h="1.1rem" display="flex" marginRight=".5rem" />
                  <Link
                    component={RouterLink}
                    to={ACCOUNT_DOMAINS}
                    title="View your DNS issues."
                    underline="none"
                    color="inherit"
                  >
                    {dnsIssues} Issue{dnsIssues !== 1 && 's'} detected
                  </Link>
                </Stack>
              )}
              <Link component={RouterLink} to={ACCOUNT_DOMAINS}>
                Your DNS Records &#8680;
              </Link>
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
};
DomainPreview.propTypes = {
  user: userShape.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  apexDomains: PropTypes.arrayOf(apexDomainShape).isRequired,
};
export default DomainPreview;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, Divider } from '@mui/material';

const FooterContent = styled(Box)`
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
`;

const FormFooter = ({ children, ...props }) => (
  <>
    <Divider mt={3} />
    {/* eslint-disable-next-line */}
    <FooterContent {...props}>{children}</FooterContent>
  </>
);

FormFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormFooter;

import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Box } from '@mui/material';
import { SubTitle } from '../../../common';
import config from '../../../../config';
import { REFERRAL_CODE_KEY } from '../../../../constants';

const ReferralLanding = () => {
  const { referralCode } = useParams();
  const code = decodeURIComponent(referralCode || '');

  if (code.length >= 3) {
    localStorage.setItem(REFERRAL_CODE_KEY, code);
    window.location = `${config.SHOWIT_MAIN_URL}referral?referralCode=${code}`;
  } else {
    window.location = config.SHOWIT_MAIN_URL;
  }
  return (
    <Card>
      <Box my={5} sx={{ textAlign: 'center' }} py={5}>
        <SubTitle>Redirecting...</SubTitle>
      </Box>
    </Card>
  );
};
export default ReferralLanding;

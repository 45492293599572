export const buildFormikSubmitHandler = (mutate) => (values, actions) =>
  mutate({ values, actions });
export const formikErrorFocus = (fields, errors, elements) => {
  fields.some((name) => {
    const hasError = !!errors[name] && !!elements[name];
    if (hasError) {
      elements[name].focus();
    }
    return hasError;
  });
};

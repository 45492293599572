import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link, SvgIcon, Typography } from '@mui/material';
import ReasonList from './ReasonList';
import { SubscriptionPanel } from './Styled';
import Links from '../../../constants/links';

const FlameIcon = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props} viewBox="0 0 48 48">
    <path
      d="M33.781,27.201c0.297,3.286-1.424,6.786-5.939,8.63c0.97-0.894,2.025-2.268,2.001-4.112
          c-0.034-2.702-1.312-3.406-1.844-7.281c-0.438,3.094-6.781,5.172-6.781,8.484c0.045,1.023,0.319,2.365,1.013,2.972
          c-3.508-0.997-6.029-4.709-6.029-8.327c0-3.627,2.922-5.474,1.922-9.385c2.844,0.536,4.185,5.164,2.53,7.267
          c5.594-3.343,1.371-9.193,7.086-13.374C25.519,16.778,33.24,21.223,33.781,27.201z"
    />
  </SvgIcon>
);

const ExternalBlogPlan = ({ plan }) => (
  <SubscriptionPanel style={{ height: '100%' }}>
    <Typography variant="h3" className="planName">
      Completely On Fire?
    </Typography>
    <FlameIcon style={{ width: 'auto', height: 'auto' }} color="primary" />
    <Typography variant="h5" align="center">
      If your blog is garnering more than 100K visits per month, congratulations!
    </Typography>
    <Box py={4}>
      <Typography>
        For exceptionally high-traffic blogs created with Showit, please reach out to{' '}
        <Link href={Links.HELP_URL} target="_blank" rel="noreferrer" underline="hover">
          Showit Support
        </Link>{' '}
        for a customized solution to match your traffic needs.
      </Typography>
    </Box>
    <Typography>Please contact Showit Support for more information.</Typography>
    {plan.reasons && <ReasonList reasons={plan.reasons} />}
  </SubscriptionPanel>
);

ExternalBlogPlan.propTypes = {
  plan: PropTypes.shape({
    reasons: ReasonList.propTypes.reasons,
  }).isRequired,
};

export default ExternalBlogPlan;

import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StyledTab, TabsRoot } from './components';
import AffiliateCreditsTab from './tabs/AffiliateCreditsTab';
import ReferralStats from './ReferralStats';
import ReferralsTab from './tabs/ReferralsTab';
import referralStatuses from '../../../constants/referralStatuses';
import useQueryParams from '../../../hooks/useQueryParams';
import useReferralCode from '../subscription/ReferralCard/hooks/useReferralCode';
import useUser from '../../../hooks/useUser';

const STATUS_PENDING = [
  referralStatuses.QUALIFYING,
  referralStatuses.APPLYING_CREDIT,
  referralStatuses.DISQUALIFIED,
];
const STATUS_FULFILLED = [referralStatuses.CREDIT_APPLIED];

export const TAB_PENDING_REFERRALS = 'pr';
export const TAB_SUBSCRIPTION_CREDITS = 'sc';
export const TAB_AFFILIATE_CREDITS = 'ac';

const ReferralTabs = () => {
  const history = useHistory();
  const params = useQueryParams();
  const activeTab = params.get('tab') || TAB_PENDING_REFERRALS;
  const { referralCode } = useReferralCode();
  const { user } = useUser();
  const showSubscriptionCredits = !user?.affiliateStatus;

  const handleChange = (_, val) => {
    history.push({ search: `?tab=${val}` });
  };

  return referralCode?.acceptedReferralTos ? (
    <Box>
      <ReferralStats />
      <TabsRoot value={activeTab} onChange={handleChange} aria-label="Referral Stats">
        <StyledTab
          value={TAB_PENDING_REFERRALS}
          label={<Typography variant="body2">Pending Referrals</Typography>}
        />
        {showSubscriptionCredits && (
          <StyledTab
            value={TAB_SUBSCRIPTION_CREDITS}
            label={<Typography variant="body2">Subscription Credits</Typography>}
          />
        )}
        <StyledTab
          value={TAB_AFFILIATE_CREDITS}
          label={<Typography variant="body2">Affiliate Credits</Typography>}
        />
      </TabsRoot>
      <Box py={1}>
        {activeTab === TAB_PENDING_REFERRALS && <ReferralsTab status={STATUS_PENDING} />}
        {activeTab === TAB_SUBSCRIPTION_CREDITS && showSubscriptionCredits && (
          <ReferralsTab status={STATUS_FULFILLED} />
        )}
        {activeTab === TAB_AFFILIATE_CREDITS && <AffiliateCreditsTab />}
      </Box>
    </Box>
  ) : null;
};

export default ReferralTabs;

import { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { getChargebeeCheckout, completeChargebeeCheckout } from '../api';
import getChargebee from '../utils/getChargebee';
import queryData from '../utils/queryData';
import { REFERRAL_CODE_KEY } from '../constants';

const completeCheckoutProcess = async ({ hostedPageId }) => completeChargebeeCheckout(hostedPageId);

function useChargebeeCheckout() {
  const queryClient = useQueryClient();

  const {
    mutate: completeCheckout,
    isLoading: isCompleteCheckoutLoading,
    isError: isCompleteCheckoutError,
  } = useMutation(completeCheckoutProcess);

  const [result, setResult] = useState({
    error: false,
    subscribed: false,
    hostedPageId: null,
    planId: null,
    completed: false,
    user: null,
    subscription: null,
  });

  const [wasClosed, setWasClosed] = useState(false);

  function openCheckout({ chargebeePlanId, discount }) {
    const { cbInstance } = getChargebee();
    cbInstance.openCheckout({
      hostedPage() {
        setResult((prevResult) => ({ ...prevResult, planId: chargebeePlanId }));
        return getChargebeeCheckout({ planId: chargebeePlanId, discount });
      },
      loaded() {
        setWasClosed(false);
      },
      success(hostedPageId) {
        setResult((prevResult) => ({ ...prevResult, subscribed: true, hostedPageId }));
      },
      close() {
        setWasClosed(true);
      },
      error(error) {
        // eslint-disable-next-line no-alert
        alert('A problem occurred during the checkout process');
        // eslint-disable-next-line no-console
        console.log(error);
        setResult((prevResult) => ({ ...prevResult, error: true }));
      },
    });
  }

  useEffect(() => {
    if (
      result.subscribed &&
      !result.completed &&
      !isCompleteCheckoutLoading &&
      !isCompleteCheckoutError
    ) {
      const run = async (hostedPageId) => {
        try {
          completeCheckout(
            { hostedPageId },
            {
              onSuccess: (data) => {
                const { account, subscription } = data;
                queryData.updateUser(account, queryClient);
                queryData.updateSubscription(subscription, queryClient);
                localStorage.removeItem(REFERRAL_CODE_KEY);
                setResult((prevResult) => ({
                  ...prevResult,
                  completed: true,
                  user: account,
                  subscription,
                }));
              },
            }
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      };
      run(result.hostedPageId);
    }
  }, [queryClient, result, isCompleteCheckoutError, isCompleteCheckoutLoading, completeCheckout]);

  return [openCheckout, wasClosed, result];
}

export default useChargebeeCheckout;

import { create, request } from 'axios';
import { StatusCodes } from 'http-status-codes';
import env from '../config';
import { set, getToken, isStale } from './session';

const createBrowserError = (msg) => {
  const error = new Error(msg);
  error.code = 'BrowserError';

  return error;
};

const { API_ENDPOINT: baseURL } = env;

let pendingRefresh;
const refreshSession = (token) => {
  if (!pendingRefresh) {
    pendingRefresh = request({
      method: 'GET',
      url: `${baseURL}/login/refresh`,
      headers: { Authorization: `Bearer ${token}` },
    }).then(({ data }) => {
      set(data);
      pendingRefresh = undefined;
      return data.token;
    });
  }

  return pendingRefresh;
};

const getAuthorization = async () => {
  try {
    const token = getToken();
    return isStale() ? await refreshSession(token) : token;
  } catch (error) {
    set();
    return undefined;
  }
};

const authorized = (() => {
  const requestor = create({ baseURL });

  requestor.interceptors.request.use(async (config) => ({
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${await getAuthorization()}` },
  }));

  requestor.interceptors.response.use(
    (response) => response,
    (error) => {
      const appError = error;

      if (
        appError?.response?.status === StatusCodes.UNAUTHORIZED &&
        appError?.response?.data?.errors?.find((e) => e.code === 'AuthorizationError') // this happens on session timeout
      ) {
        set({}, true);
      }

      if (!appError.response) {
        appError.response = {
          status: -1,
          data: { errors: [createBrowserError(error.message)] },
        };
      }

      throw appError;
    }
  );

  return requestor;
})();

const notAuthorized = (() => {
  const requestor = create({ baseURL });

  requestor.interceptors.response.use(
    (response) => response,
    (error) => {
      const appError = error;

      if (!appError.response) {
        appError.response = {
          status: -1,
          data: { errors: [createBrowserError(error.message)] },
        };
      }

      throw appError;
    }
  );

  return requestor;
})();

export { authorized, notAuthorized, notAuthorized as default, refreshSession };

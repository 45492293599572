import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { palette } from '../../theme';

const CardText = styled(Typography)`
  text-align: center;
  color: ${palette.primary.main};
  margin: 1em 1em 2em;
  font-size: 1em;
`;

export default CardText;

import React from 'react';
import { When } from '../../../common';
import { user as UserType } from '../../../../domain/PropTypes';
import ConfirmEmail from './ConfirmEmail';

const Notices = ({ user }) => {
  const { emailConfirmed } = user;

  return (
    <>
      <When it={!emailConfirmed}>
        <ConfirmEmail />
      </When>
    </>
  );
};

Notices.propTypes = {
  user: UserType.isRequired,
};

export default Notices;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery, Link } from '@mui/material';
import { useTheme } from '@emotion/react';
import useDesigner from '../../hooks/useDesigner';
import { ServicePage, When } from '../common';
import HeaderLogo from '../common/HeaderLogo';
import LoginForm from './LoginForm';
import PageContent from '../common/PageContent';
import { DesignerContainer, DesignerLogoContainer, DesignerName, LoginCard } from './Styled';
import OuterCardLogo from '../common/OuterCardLogo';
import BlurredLoadImage from '../common/BlurredLoadImage';

const pickRandomValueFromObject = (object) => {
  const values = Object.values(object);
  return values[Math.floor(Math.random() * values.length)];
};

const pickRandomColor = (colors) => pickRandomValueFromObject(colors);

const Designer = ({ designer = {} }) => {
  const {
    palette: { branding: colors },
  } = useTheme();
  const { smallImageUrl, bigImageUrl, byline } = designer;

  if (!bigImageUrl) {
    return null;
  }

  const randomColor = pickRandomColor(colors);

  return (
    <DesignerContainer>
      <BlurredLoadImage
        bigImageUrl={bigImageUrl}
        smallImageUrl={smallImageUrl}
        style={{ borderRadius: '24px' }}
      />
      <Box>
        <DesignerName variant="p" backgroundColor={randomColor}>
          {byline}
        </DesignerName>
      </Box>
    </DesignerContainer>
  );
};

const Login = ({ autoLoggedOut }) => {
  const showDesigner = useMediaQuery('(min-width: 900px)');
  const { designer } = useDesigner();

  useEffect(() => {}, []);

  return (
    <ServicePage>
      <PageContent maxWidth={showDesigner ? 'md' : 'xs'}>
        <When it={!showDesigner}>
          <OuterCardLogo />
        </When>
        <LoginCard elevation={0} showDesigner={showDesigner}>
          <Box className="LoginCardContent">
            <When it={showDesigner}>
              <DesignerLogoContainer>
                <Link rel="noreferrer" href="https://www.showit.com" title="Back to Showit.com">
                  <HeaderLogo style={{ width: '100px' }} />
                </Link>
              </DesignerLogoContainer>
            </When>
            <Box className="LoginFormContainer">
              <LoginForm autoLoggedOut={autoLoggedOut} />
            </Box>
          </Box>
          <When it={showDesigner && !!designer} render={() => <Designer designer={designer} />} />
        </LoginCard>
      </PageContent>
    </ServicePage>
  );
};

Login.propTypes = {
  autoLoggedOut: PropTypes.bool.isRequired,
};

Designer.propTypes = {
  designer: PropTypes.shape({
    acf: PropTypes.shape({
      byline: PropTypes.string,
      image: PropTypes.string,
    }),
  }).isRequired,
};

export default Login;

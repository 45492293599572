import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RedeemIcon from '@mui/icons-material/Redeem';
import { ActionLinkText, SubTitle } from '../../../common';
import Links from '../../../../constants/links';

const ReferralHelpText = ({ acceptedReferralTos }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Box mb={1.5}>
      {acceptedReferralTos ? (
        <Box>
          Give your friends a free month of Showit&nbsp;
          {expanded ? (
            <>
              and you&apos;ll get a free month applied to your own subscription. If you have 12
              months of subscription credit in your account, you can instead earn affiliate credit
              for any additional referrals that have used your code to subscribe!&nbsp;
              <Link href={Links.REFERRAL_TOS} target="_blank" color="brightBlue" underline="hover">
                Referral Program terms of service
              </Link>
            </>
          ) : (
            <ActionLinkText title="Learn more" onClick={() => setExpanded(true)}>
              Learn more&nbsp;
            </ActionLinkText>
          )}
        </Box>
      ) : (
        <>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
            <RedeemIcon fontSize="large" />
            <SubTitle style={{ textAlign: 'left', marginLeft: '.6rem' }}>
              Give a month, get a month
            </SubTitle>
          </Box>
          <Box mt={2}>
            <Typography textAlign={{ xs: 'center', md: 'left' }} variant="body2">
              Give your friends a free month of Showit and <br />
              you&apos;ll get a free month after they subscribe!
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

ReferralHelpText.propTypes = {
  acceptedReferralTos: PropTypes.bool.isRequired,
};
export default ReferralHelpText;

import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import HeaderLogo from './HeaderLogo';

const LogoContainer = styled(Box)`
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
`;

const OuterCardLogo = (props) => (
  // eslint-disable-next-line
  <LogoContainer {...props}>
    <HeaderLogo />
  </LogoContainer>
);

export default OuterCardLogo;

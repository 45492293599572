import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Link, Typography } from '@mui/material';

import { SubTitle, PageTitle, Instruction, When } from '../../common';
import SMSContactMethods from './SMSContactMethods';
import { ACCOUNT_PROFILE } from '../../../constants/routePaths';
import useUser from '../../../hooks/useUser';
import { palette } from '../../../theme';

const Notifications = () => {
  const { user } = useUser();
  const activeAccount = user.status === 'ACTIVE';

  return (
    <Container maxWidth="sm">
      <PageTitle align="left">Notification Settings</PageTitle>
      <Instruction>
        When a new contact form is submitted on your Showit website you will be notified via the
        following methods:
      </Instruction>
      <SubTitle style={{ textAlign: 'left' }} gutterBottom>
        Email:
      </SubTitle>
      <Box>
        <Typography variant="h5">{user.emailAddress}</Typography>
        <Instruction>
          You can edit your email address in&nbsp;
          <Link component={RouterLink} to={ACCOUNT_PROFILE}>
            your account profile
          </Link>
        </Instruction>
      </Box>
      <Box borderTop={`1px solid ${palette.primary.main}`} pt={3} mt={3}>
        <SubTitle style={{ textAlign: 'left' }} gutterBottom>
          Text (SMS):
        </SubTitle>
        <When it={!activeAccount}>
          <Instruction>Text messaging is only available to Showit subscribers.</Instruction>
        </When>
        <When it={activeAccount}>
          <SMSContactMethods />
        </When>
      </Box>
    </Container>
  );
};

export default Notifications;

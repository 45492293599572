/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Link,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import ContributorLink from './ContributorLink';
import { BASIC_BLOG } from '../../../../constants/blogTypes';
import Links from '../../../../constants/links';

const BASIC_BLOG_TEXT =
  'This account has a Basic Blog subscription. Sign in using your own Showit username and password.';
const ADV_BLOG_TEXT =
  'This account has an Advanced Blog subscription. Sign in using the username and password that the website owner created for you in WordPress, not your Showit account credentials.';

const getDialogData = (blogType) => ({
  text: blogType === BASIC_BLOG ? BASIC_BLOG_TEXT : ADV_BLOG_TEXT,
  link:
    blogType === BASIC_BLOG
      ? Links.CONTRIBUTOR_ACCESS_LEARN_URL
      : Links.SHARING_ADV_BLOG_ACCESS_LEARN_URL,
});

const WPLoginBtn = ({ firstName, lastName, blogType, blogDomainName }) => {
  const [open, setOpen] = useState(false);
  const { text, link } = getDialogData(blogType);

  const openBlogAdmin = () => {
    window.open(`https://${blogDomainName}/wp-admin`, '_blank');
    setOpen(false);
  };

  return (
    <>
      <ContributorLink wp onClick={() => setOpen(true)} />
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography mb={1} fontSize="1.1rem" id="alert-dialog-title">
            {firstName} {lastName}
          </Typography>
          <DialogContentText id="alert-dialog-description">
            {text}{' '}
            <Link target="_blank" href={link} title="Learn more" underline="hover">
              Learn More
            </Link>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" sx={{ py: '.25rem' }} onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" sx={{ py: '.25rem' }} onClick={openBlogAdmin}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

WPLoginBtn.defaultProps = {
  firstName: '',
  lastName: '',
};

WPLoginBtn.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  blogDomainName: PropTypes.string.isRequired,
  blogType: PropTypes.string.isRequired,
};

export default WPLoginBtn;

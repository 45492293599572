import React from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { SubTitle } from '../../../common';
import Links from '../../../../constants/links';
import { LastChecked } from '../Styles';
import RefreshDNSButton from './RefreshDNSButton';
import { scanDNS } from '../../../../api';
import DNSTable from './DNSTable';
import ConfigureDNSBtn from './ConfigureDNSBtn';
import useEntriSupport from '../../../../hooks/useEntriSupport';
import { DOMAIN_DATA } from '../../../../constants/queryData';
import { dnsRecordShape, domainStatusShape } from '../../../../domain/PropTypes';
import { DNS_SHOULD_NOT_EXIST, DNS_UNKNOWN } from '../../../../constants/domainConstants';
import useUser from '../../../../hooks/useUser';
import { ACTIVE } from '../../../../constants/accountStatuses';

const DNSInfo = ({ userId, domainId, domainName, dnsRecords, dnsValid, domainStatus }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { lastDNSMonitor } = domainStatus;

  const { user } = useUser();
  const userIsActive = user.status === ACTIVE;
  const { supported, isLoading: entriIsLoading } = useEntriSupport(
    { domainId, domainName },
    {
      enabled: userIsActive && !dnsValid && dnsRecords.length > 0,
    }
  );

  const {
    mutate: runDNSCheck,
    isLoading: scanningDNS,
    isError: dnsScanError,
    reset,
  } = useMutation(() => scanDNS(domainId, userId), {
    onSuccess: async (results) => {
      queryClient.setQueryData([DOMAIN_DATA, userId], (cache = []) =>
        cache.map((cachedApexDomain) =>
          cachedApexDomain.domainId === domainId
            ? {
                ...cachedApexDomain,
                dnsRecords: results.dns,
                domainStatus: results.domainStatus,
              }
            : cachedApexDomain
        )
      );
    },
  });
  return (
    <Box>
      {dnsRecords.length > 0 && (
        <>
          <SubTitle>Your DNS Records</SubTitle>
          <Typography variant="subtitle2" mb={2}>
            Use the following IP addresses to point <strong>{domainName}</strong> to your Showit
            site.
            <br />
            <Link
              target="_blank"
              rel="noreferrer"
              href={Links.DOMAIN_HELP_LEARN_URL}
              title="Learn more about DNS"
              underline="always"
            >
              Learn how to configure your DNS records.
            </Link>
          </Typography>
          {lastDNSMonitor && (
            <LastChecked>
              Status last checked {format(new Date(lastDNSMonitor), 'MMM dd h:mm a')}
            </LastChecked>
          )}
          <Stack direction={isMobile ? 'column' : 'row'} mb={2} mt={isMobile ? 2 : 0}>
            {userIsActive && !dnsValid && (
              <ConfigureDNSBtn
                userId={userId}
                domainId={domainId}
                dnsRecords={dnsRecords}
                domainName={domainName}
                entriIsLoading={entriIsLoading}
                showEntriBtn={
                  supported &&
                  !dnsValid &&
                  dnsRecords.some(
                    (dns) =>
                      dns.expectedValue !== DNS_SHOULD_NOT_EXIST &&
                      dns.expectedValue !== DNS_UNKNOWN
                  )
                }
                sx={{ mr: 1.5 }}
              />
            )}
            {userIsActive && (
              <RefreshDNSButton
                lastCheck={lastDNSMonitor}
                onClick={runDNSCheck}
                isLoading={scanningDNS}
                isError={dnsScanError}
                reset={reset}
                buttonLabel="Check Site"
              />
            )}
          </Stack>
          <DNSTable records={dnsRecords} />
          {!dnsValid && (
            <>
              <br />
              <Typography variant="subtitle2" mb={2}>
                * Once you make DNS changes through your domain registrar account, it may take 24-48
                hours for those changes to refresh across the World Wide Web. Our records listed on
                this page may not reflect the changes you recently made.
              </Typography>
            </>
          )}
        </>
      )}
    </Box>
  );
};
DNSInfo.propTypes = {
  userId: PropTypes.number.isRequired,
  domainId: PropTypes.number.isRequired,
  domainName: PropTypes.string.isRequired,
  dnsRecords: PropTypes.arrayOf(dnsRecordShape).isRequired,
  dnsValid: PropTypes.bool.isRequired,
  domainStatus: domainStatusShape.isRequired,
};

export default DNSInfo;

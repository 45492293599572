import React from 'react';
import { AppBar } from '@mui/material';
import HeaderLogo from './HeaderLogo';

const Header = () => {
  const styles = {
    appBar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  return (
    <AppBar position="static" sx={styles.appBar}>
      <HeaderLogo />
    </AppBar>
  );
};

export default Header;

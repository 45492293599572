import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, FormHelperText, Checkbox } from '@mui/material';

const MuiCheckbox = ({ error, label, helperText, ...rest }) => (
  <FormControl error={error} data-checkboxerror={error}>
    <FormControlLabel
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      control={<Checkbox color="primary" {...rest} />}
      label={label}
    />
    {error && helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
  </FormControl>
);

MuiCheckbox.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
};

MuiCheckbox.defaultProps = {
  error: false,
  label: null,
  helperText: null,
};

export default MuiCheckbox;

import React from 'react';
import { SubscribeButton, When } from '../../../common';
import { formatDateAsWords } from '../../../../utils/dateTime';
import useSubscription from '../../subscription/SubscriptionCard/useSubscription';
import { ACCOUNT_SUBSCRIPTION } from '../../../../constants/routePaths';
import DashboardNoticeWrapper from './DashboardNoticeWrapper';

const AccountInactive = () => {
  const { subscription = {}, isLoading, isSuccess } = useSubscription();

  return (
    <>
      <When it={isLoading}>Loading...</When>
      <When it={isSuccess && !!subscription.expiration}>
        <DashboardNoticeWrapper
          message={`Your subscription ended on ${formatDateAsWords(subscription.statusDate)}.`}
          cta={<SubscribeButton to={ACCOUNT_SUBSCRIPTION}>Resubscribe now</SubscribeButton>}
        />
      </When>
      <When it={isSuccess && !subscription.expiration}>
        <DashboardNoticeWrapper
          message="Account Inactive."
          cta={<SubscribeButton>Subscribe Now</SubscribeButton>}
        />
      </When>
    </>
  );
};

export default AccountInactive;

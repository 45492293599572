import React from 'react';
import { useFormik } from 'formik';
import { Grid, TextField, Alert } from '@mui/material';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '../../../common';
import { confirmContactMethod, resendConfirmation } from '../../../../api';
import { yup, createHandleError, buildSubmitHandler } from '../../../../utils';
import { CONTACTMETHODS } from '../../../../constants/queryData';
import { palette } from '../../../../theme';

const handleError = createHandleError('confirmationCode');

const ConfirmationForm = ({ method }) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    ({ values }) =>
      confirmContactMethod({
        id: method.id,
        type: method.type,
        confirmationCode: values.confirmationCode,
      }),
    {
      onSuccess: ({ data: confirmedMethod }) =>
        queryClient.setQueryData(CONTACTMETHODS, (cache) =>
          cache.map((cachedMethod) =>
            cachedMethod.id === confirmedMethod.id ? confirmedMethod : cachedMethod
          )
        ),
      onError: handleError,
    }
  );
  const {
    mutate: resendCode,
    reset: resetConfirmation,
    isLoading: confirmationResending,
    isSuccess: confirmationResent,
    isError: confirmationResendingFailed,
  } = useMutation(() => resendConfirmation(method));

  const { errors, values, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      confirmationCode: '',
    },
    validationSchema: yup.createSchema({ confirmationCode: yup.confirmationCode }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: buildSubmitHandler(mutate),
  });

  return (
    <form
      style={{ borderBottom: `1px solid ${palette.primary.main}`, marginBottom: '1em' }}
      onSubmit={handleSubmit}
    >
      <Alert
        severity="warning"
        variant="outlined"
        style={{ margin: '.6rem 0', borderRadius: '4px' }}
      >
        You must confirm the phone number by entering the confirmation code.
      </Alert>
      {confirmationResent && (
        <Alert
          severity="success"
          style={{ margin: '.75rem 0', borderRadius: '4px' }}
          onClose={resetConfirmation}
        >
          Confirmation Code Resent.
        </Alert>
      )}
      {confirmationResendingFailed && (
        <Alert
          severity="error"
          style={{ marginTop: 6, borderRadius: '4px' }}
          onClose={resetConfirmation}
        >
          Unable to resend confirmation.
        </Alert>
      )}
      <TextField
        variant="standard"
        name="confirmationCode"
        label="Confirmation Code"
        value={values.confirmationCode}
        onChange={handleChange}
        error={!!errors.confirmationCode}
        helperText={errors.confirmationCode}
      />
      <Grid container alignItems="center" spacing={2} mt={2} mb={3}>
        <Grid item>
          <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
            Confirm
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="text"
            loading={confirmationResending}
            disabled={confirmationResending}
            onClick={resendCode}
          >
            Resend
          </Button>
        </Grid>
        <Grid />
      </Grid>
    </form>
  );
};

ConfirmationForm.propTypes = {
  method: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    value: PropTypes.string,
    isConfirmed: PropTypes.bool,
  }),
};

ConfirmationForm.defaultProps = {
  method: {},
};

export default ConfirmationForm;

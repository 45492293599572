import React, { useState, useMemo } from 'react';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';
import PropTypes from 'prop-types';

import phoneUtil from '../../../../utils/phoneNumber';
import { When } from '../../../common';
import ConfirmationForm from './ConfirmationForm';
import DeleteDialog from './DeleteDialog';
import { deleteContactMethod } from '../../../../api';
import { CONTACTMETHODS } from '../../../../constants/queryData';

const PhoneNotificationItem = ({ method }) => {
  const queryClient = useQueryClient();
  const { mutate: remove, isLoading } = useMutation(() => deleteContactMethod(method), {
    onSuccess: () => {
      queryClient.setQueryData(CONTACTMETHODS, (cache) =>
        cache.filter((contactMethod) => contactMethod.id !== method.id)
      );
    },
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  const { type, value, isConfirmed } = method;
  const phoneNumber = useMemo(() => phoneUtil.getRegionFormat(phoneUtil.parse(value)), [value]);

  return (
    <Box>
      <Grid container alignItems="center" alignContent="center" justifyContent="space-between">
        <Grid item>
          <Typography component="span" variant="h5">
            {phoneNumber}
          </Typography>
        </Grid>
        <Grid item>
          {isLoading ? (
            <CircularProgress size={20} color="primary" />
          ) : (
            <IconButton
              onClick={() => setDialogOpen(true)}
              title="Delete Phone Number"
              size="large"
            >
              <Delete />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <When it={!isConfirmed}>
        <ConfirmationForm method={method} />
      </When>
      <DeleteDialog
        type={type}
        value={value}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        confirmDelete={remove}
      />
    </Box>
  );
};

PhoneNotificationItem.propTypes = {
  method: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.string,
    isConfirmed: PropTypes.bool,
  }),
};

PhoneNotificationItem.defaultProps = {
  method: undefined,
};

export default PhoneNotificationItem;

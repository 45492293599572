import { useMutation, useQuery, useQueryClient } from 'react-query';
import { addReferral, deleteReferral, getReferral, verifyDiscountCode } from '../api';
import { DISCOUNT_CODE } from '../constants/queryData';
import { CODE_TYPE_COUPON, COUPON, REFERRAL } from '../constants/discountCodeTypes';

export const getDiscountProps = (data) =>
  data?.code
    ? {
        id: data.id,
        code: data.code,
        valid: data.valid,
        type:
          // always convert to string
          typeof data.type === 'string'
            ? data.type
            : data.type === CODE_TYPE_COUPON
            ? COUPON
            : REFERRAL,
      }
    : {};

/**
 * Stores referral/discount code. Only used in the subscription checkout flow
 * @returns {{
 *  discount: {
 *    id: string,
 *    code: string,
 *    type: "referral" | "coupon",
 *    valid: boolean,
 *   },
 * }}
 */
const useDiscountCode = (settings = {}) => {
  const { data, ...rest } = useQuery([DISCOUNT_CODE], () => getReferral().then(getDiscountProps), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    ...settings,
  });
  return { discount: data || {}, ...rest };
};
export default useDiscountCode;

/**
 * Verifies a discount code, creates a referral record if necessary, then updates the `DISCOUNT_CODE` cache
 */
export const useVerifyDiscountCode = () => {
  const queryClient = useQueryClient();
  return useMutation((code) => verifyDiscountCode(code), {
    onSuccess: async (response) => {
      if (response.type === REFERRAL) {
        await addReferral(response.code);
      }
      queryClient.setQueryData(
        [DISCOUNT_CODE],
        getDiscountProps({
          ...response,
          valid: true,
        })
      );
    },
  });
};

export const useRemoveDiscount = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => deleteReferral(id), {
    onSuccess: () => {
      queryClient.setQueryData([DISCOUNT_CODE], {});
    },
  });
};

import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import useReferralStats from '../subscription/ReferralCard/hooks/useReferralStats';
import { penniesToDollars } from '../../../utils/money';
import { Statistic } from './components';
import useUser from '../../../hooks/useUser';

const ReferralStats = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useUser();
  const {
    pendingReferrals = 0,
    subscriptionCredits = 0,
    affiliateCredits = 0,
    isLoading,
    isSuccess,
    isError,
  } = useReferralStats();

  return (
    <Box>
      {isLoading && (
        <Box textAlign="center" my={1}>
          <CircularProgress size={22} color="black" />
        </Box>
      )}
      {isError && (
        <Typography variant="body2" color="error" textAlign="center" my={4}>
          Failed to load referral stats
        </Typography>
      )}
      {isSuccess && (
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          divider={<Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />}
          border="1px solid #e0e0e0"
          mb={2.5}
        >
          <Statistic>
            Pending Referrals
            <br />
            <span>{pendingReferrals}</span>
          </Statistic>
          {!user?.affiliateStatus && (
            <Statistic>
              Subscription Credits
              <br />
              <span>{subscriptionCredits}</span>
              <span style={{ fontSize: '0.8rem' }}> mo.</span>
            </Statistic>
          )}
          <Statistic>
            Affiliate Credits
            <br />
            <span>{penniesToDollars(affiliateCredits)}</span>
          </Statistic>
        </Stack>
      )}
    </Box>
  );
};
export default ReferralStats;

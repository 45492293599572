import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { fonts } from '../../theme';

const StyledSubTitle = styled(Typography, {
  shouldForwardProp: (name) => !['homeHeader', 'cardTitle', 'formHeader'].includes(name),
})`
  font-weight: 700;
  font-family: ${fonts.atypText};
  font-size: 1.25rem;
  ${({ cardTitle }) => (cardTitle ? `text-align: center;` : '')}
  ${({ homeHeader }) => (homeHeader ? `font-size: 1.15rem;` : '')} */
`;

const SubTitle = ({ children, variant, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledSubTitle variant={variant} {...props}>
    {children}
  </StyledSubTitle>
);

SubTitle.defaultProps = {
  variant: 'h3',
};

SubTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  variant: PropTypes.string,
};

export default SubTitle;

import React from 'react';
import PropTypes from 'prop-types';
import ShowitLogo from '../../assets/showit-logo.svg';

const Logo = ({ style }) => (
  <img
    style={{
      width: '225px',
      height: '30px',
      ...style,
    }}
    src={ShowitLogo}
    alt=""
  />
);

Logo.propTypes = {
  style: PropTypes.shape({}),
};

Logo.defaultProps = {
  style: {},
};

export default Logo;

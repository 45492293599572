import React from 'react';
import { Box, Typography } from '@mui/material';
import { user as UserType } from '../../../../../domain/PropTypes';
import { COMPED, LIFETIME, NORMAL } from '../../../../../constants/userTypes';
import SubscriptionInfo from '../SubscriptionInfo';

const LifetimeView = () => (
  <Box>
    <Typography variant="h5">Lifetime</Typography>
  </Box>
);

const CompedView = () => (
  <Box>
    <Typography variant="h5">Complementary</Typography>
  </Box>
);

const NormalView = ({ user }) => <SubscriptionInfo user={user} />;

NormalView.propTypes = {
  user: UserType.isRequired,
};

const UserTypeMap = {
  [COMPED]: CompedView,
  [LIFETIME]: LifetimeView,
  [NORMAL]: NormalView,
};

const StatusActive = ({ user }) => {
  const { userType } = user;
  const UserTypeView = UserTypeMap[userType];

  return <UserTypeView user={user} />;
};

StatusActive.propTypes = {
  user: UserType.isRequired,
};

export default StatusActive;

import { useState } from 'react';

const getSessionStorageOrDefault = (key, defaultValue) => {
  const stored = sessionStorage.getItem(key);
  return stored ? JSON.parse(stored) : defaultValue;
};

const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(getSessionStorageOrDefault(key, defaultValue));

  const writeValue = (newValue) => {
    if (newValue !== undefined) {
      sessionStorage.setItem(key, JSON.stringify(newValue));
    } else {
      sessionStorage.removeItem(key);
    }
    setValue(newValue);
  };

  return [value, writeValue];
};

export default useSessionStorage;

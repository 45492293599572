const Links = {
  DESIGN_MARKET_URL: 'https://store.showit.com/',
  TRAINING_COURSES_URL: 'https://training.showit.com/',
  BLOG_SETUP_HELP_DOC:
    'https://learn.showit.com/en/articles/424821-request-a-blog-setup-or-migration',
  CONTRIBUTOR_ACCESS_LEARN_URL: 'https://learn.showit.com/en/articles/5899098-contributor-access',
  SETUP_TEXT_MESSAGE_LEARN_URL:
    'https://learn.showit.com/en/articles/493306-setup-text-message-notifications',
  SHARING_ADV_BLOG_ACCESS_LEARN_URL:
    'https://learn.showit.com/en/articles/6574673-sharing-advanced-blog-access-with-contributors',
  DNS_HELP_LEARN_URL: 'https://learn.showit.com/en/articles/5886604-dns-help',
  DOMAIN_HELP_LEARN_URL: 'https://learn.showit.com/en/collections/3034365-domain-configuration',
  WP_TROUBLESHOOTING_LEARN_URL:
    'http://learn.showit.com/faqs-and-troubleshooting/wordpress-and-blogs/showit-wordpress-powered-by-wpengine',
  BILLING_LEARN_URL: 'http://learn.showit.com/account-and-billing/billing/showit-blog-subscription',
  PROMO_BACKGROUND_LIB_URL: 'https://lib.showit.com/app/demo-promo-background.jpg',
  REFERRAL_HELP_URL: 'https://learn.showit.com/en/articles/6050631-refer-a-friend-to-showit',
  REFERRAL_TOS: 'https://showit.com/referral-program-terms-of-service',
  HELP_URL: 'https://showit.com/help',
};

export default Links;

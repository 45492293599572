import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';

import { FIVE_MINUTES_IN_SECONDS, getTimeLeft } from '../../../../utils/dateTime';

const TimedButton = forwardRef((props, ref) => {
  const { referenceTime, onClick, loading, label, ...rest } = props;
  const [timeLeft, setTimeLeft] = useState(() =>
    referenceTime ? getTimeLeft(referenceTime, FIVE_MINUTES_IN_SECONDS) : 0
  );

  useEffect(() => {
    setTimeLeft(getTimeLeft(referenceTime, FIVE_MINUTES_IN_SECONDS));
  }, [referenceTime]);

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(intervalId);
  }, [timeLeft]);

  return (
    <LoadingButton
      ref={ref}
      {...rest}
      variant="outlined"
      loading={loading}
      onClick={onClick}
      disabled={timeLeft > 0}
    >
      {label}
    </LoadingButton>
  );
});

TimedButton.displayName = 'TimedButton';

TimedButton.propTypes = {
  referenceTime: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string,
};

TimedButton.defaultProps = {
  referenceTime: undefined,
  label: '',
};

export default TimedButton;

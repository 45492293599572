import { useQuery } from 'react-query';
import { getAccountDomains, getAccountDNS, getAccountDomainsStatus } from '../api';
import { DOMAIN_DATA } from '../constants/queryData';
import { DOMAIN_STATUS_DISABLED } from '../constants/domainConstants';
import useUser from './useUser';

const defaults = {
  staleTime: 60000, // 1 min
};

const fetchUserApexDomains = async (userId) => {
  const [domains = [], dnsRecords = [], domainsStatus = []] = await Promise.all([
    getAccountDomains(userId),
    getAccountDNS(userId),
    getAccountDomainsStatus(userId),
  ]);

  // Structure the data for each apex domain card
  const apexDomains = domains.reduce((acc, domain) => {
    if (!domain.parentDomainId && domain.status !== DOMAIN_STATUS_DISABLED) {
      const apexDomain = {
        ...domain,
        domainStatus: domainsStatus.find((ds) => ds.domainId === domain.domainId) || {},
        dnsRecords: dnsRecords.filter(({ domainId }) => domainId === domain.domainId),
        subdomains: domains
          .filter(
            ({ parentDomainId, status }) =>
              parentDomainId === domain.domainId && status !== DOMAIN_STATUS_DISABLED
          )
          // attach domain status records to subdomains
          .map((subdomain) => ({
            ...subdomain,
            domainStatus:
              domainsStatus.find((status) => status.domainId === subdomain.domainId) || {},
          })),
      };
      acc.push(apexDomain);
    }
    return acc;
  }, []);

  return apexDomains;
};

/**
 * Returns all relevant data for an apex domain
 * @param {number?} userId - Optional. Defaults to current user.
 * @returns {{
 * apexDomains: Array,
 * }}
 */
export default function useDomainData(userId, opts = {}) {
  const { user } = useUser();
  const userID = Number(userId || user.id);
  const { data, ...rest } = useQuery([DOMAIN_DATA, userID], () => fetchUserApexDomains(userID), {
    ...defaults,
    ...opts,
  });
  return { apexDomains: data || [], ...rest };
}

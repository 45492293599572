/**
 * Converts an amount in pennies to a formatted currency string.
 *
 * @param {number} pennies - The amount in pennies to convert.
 * @returns {string} - The formatted currency string.
 * @throws Will throw an error if the input is not a valid number.
 *
 * @example
 * penniesToDollars(2050) // Output: "$20.50"
 * penniesToDollars(15000) // Output: "$150"
 * penniesToDollars(99) // Output: "$0.99"
 * penniesToDollars(-150) // Output: "- $1.50"
 */
export const penniesToDollars = (pennies) => {
  if (typeof pennies !== 'number') {
    throw new Error('Pennies must be a valid number');
  }
  const totalPennies = Math.abs(pennies);
  const dollars = Math.floor(totalPennies / 100);
  const formattedDollars = dollars.toLocaleString('en-US');
  const cents = (totalPennies % 100) / 100;
  const formattedCents = cents > 0 ? cents.toFixed(2).substring(1) : '';
  return `${pennies < 0 ? '- ' : ''}$${formattedDollars}${formattedCents}`;
};

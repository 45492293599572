import React, { useState } from 'react';
import { get } from 'axios';
import { useQuery } from 'react-query';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import config from '../../config';
import { APP_INFO } from '../../constants/queryData';
import When from './When';
import Button from './Button/Button';

const THREE_MINUTES = 3 * 60 * 1000;

const getInfo = () =>
  get(`${config.APP_API}/info`, { headers: { 'Access-Control-Allow-Origin': '*' } }).then(
    ({ data }) => data
  );

const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const formateDate = (timestamp) => {
  const dateTime = new Date(timestamp);

  return `${dateTime.toLocaleDateString(
    undefined,
    dateOptions
  )} at ${dateTime.toLocaleTimeString()}`;
};

const reloadApp = () => window.location.reload();

const VersionAlert = () => {
  const [{ open, message, versionChanged }, setState] = useState({});
  const handleClose = () => setState({});

  useQuery(APP_INFO, getInfo, {
    enabled: config.CHECK_APP_VERSION,
    refetchInterval: THREE_MINUTES,
    initialData: {},
    onSuccess: ({ version, maintenance, maintenancePending } = {}) => {
      if (config.APP_VERSION !== undefined && version !== config.APP_VERSION) {
        setState({
          open: true,
          message: 'A new version of this App is available please refresh the page.',
          versionChanged: true,
        });
      }
      if (maintenance != null) {
        reloadApp();
      }
      if (maintenancePending != null) {
        setState({
          open: true,
          message: `Maintenance will happen on ${formateDate(maintenancePending)}.`,
        });
      }
    },
  });

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        severity="warning"
        style={{ alignItems: 'center', borderRadius: '4px' }}
      >
        {message}
        <When it={!!versionChanged}>
          <Button style={{ marginLeft: 10, fontSize: 12 }} onClick={reloadApp}>
            Refresh Now
          </Button>
        </When>
      </Alert>
    </Snackbar>
  );
};

export default VersionAlert;

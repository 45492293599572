import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ActionLinkText, Button, TextInput } from '../../common';
import { useVerifyDiscountCode } from '../../../hooks/useDiscountCode';

const inputStyles = {
  maxWidth: '275px',
  '& .MuiOutlinedInput-root': {
    mb: 0,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    mb: 0,
  },
};

const DiscountInput = () => {
  const [code, setCode] = useState('');
  const [showInput, setShowInput] = useState(false);
  const { mutate: verifyCode, isLoading, isError, error, reset } = useVerifyDiscountCode();

  const goBack = () => {
    setShowInput(false);
    setCode('');
    reset();
  };

  return showInput ? (
    <Box textAlign="center">
      <Box display="flex" alignItems="flex-start">
        <TextInput
          data-testid="discount-input"
          error={isError}
          helperText={
            error
              ? error?.response?.status === 404
                ? 'Invalid code'
                : 'Failed to add discount code'
              : null
          }
          autoFocus
          variant="outlined"
          type="text"
          sx={inputStyles}
          label="Discount Code"
          name="discountCode"
          pattern="[a-zA-Z0-9]+"
          value={code}
          onKeyDown={(e) => e.key === 'Enter' && !isLoading && verifyCode(code)}
          onChange={(e) => setCode(e.target.value)}
        />
        <Button
          data-testid="apply-button"
          type="submit"
          sx={{ ml: 1 }}
          variant="outlined"
          color="primary"
          loading={isLoading}
          disabled={!code}
          onClick={() => verifyCode(code)}
        >
          Apply
        </Button>
      </Box>
      <Box my={2}>
        <ActionLinkText onClick={goBack}>Go back</ActionLinkText>
      </Box>
    </Box>
  ) : (
    <Box mb={3}>
      <Typography variant="body2" display="inline" alignment="center">
        Have a referral or coupon code?{' '}
      </Typography>
      <ActionLinkText display="inline" onClick={() => setShowInput(true)}>
        Enter it here
      </ActionLinkText>
    </Box>
  );
};
export default DiscountInput;

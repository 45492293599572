import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { StatusCodes } from 'http-status-codes';
import { palette } from '../../../../theme';
import { isDomainSecure } from '../../../../utils/domainTools';
import { SubTitle } from '../../../common';
import DNSInfo from './DNSInfo';
import SubdomainStatus from './SubdomainStatus';
import DomainStatusIcons from '../DomainStatusIcons';
import BlogStatusMessage from '../BlogStatusMessage';
import { apexDomainShape, blogShape } from '../../../../domain/PropTypes';
import { blogTypes } from '../../../../constants';
import { DomainName, LastChecked } from '../Styles';
import DomainAlert from './alerts/DomainAlert';

const ApexDomain = ({ userId, apexDomain, blogs, userBlogType }) => {
  const { domainId, domainName, domainStatus, dnsRecords, subdomains } = apexDomain;
  const hasBlogPlan = userBlogType === blogTypes.BASIC_BLOG || userBlogType === blogTypes.ADV_BLOG;
  const blog = blogs.find((b) => b.domainId === domainId);
  const sslEnabled = isDomainSecure(domainStatus);
  const dnsValid =
    dnsRecords.length > 0 &&
    dnsRecords.every(({ actualValue, expectedValue }) => actualValue === expectedValue);
  const { statusCode, isShowit, lastDNSMonitor } = domainStatus;
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: palette.cardBg,
        p: 2.5,
        mb: 2,
      }}
    >
      <DomainAlert
        sslEnabled={sslEnabled}
        isBlogDomain={hasBlogPlan && !!blog}
        dnsRecords={dnsRecords}
        dnsValid={dnsValid}
      />
      <DomainName>{domainName}</DomainName>
      {/* Only display staus info when site is being checked */}
      {lastDNSMonitor ? (
        <DomainStatusIcons
          statusCode={statusCode}
          statusOk={statusCode === StatusCodes.OK}
          sslEnabled={sslEnabled}
          isShowit={isShowit}
        />
      ) : (
        <LastChecked mt={1}>No status information available yet.</LastChecked>
      )}
      {/* Blog Status Messages */}
      <BlogStatusMessage hasBlogPlan={hasBlogPlan} blog={blog} hasBlogRecords={blogs.length > 0} />
      <Box my={3} sx={{ borderBottom: `1px dashed ${palette.mediumGrey}` }} />
      <DNSInfo
        userId={userId}
        domainId={domainId}
        domainName={domainName}
        dnsRecords={dnsRecords}
        dnsValid={dnsValid}
        domainStatus={domainStatus}
      />
      {subdomains.length > 0 && (
        <>
          <SubTitle mt={1} mb={2} style={{ fontSize: '1.1rem' }}>
            Subdomains
          </SubTitle>
          {subdomains.map((subdomain) => (
            <SubdomainStatus
              subdomain={subdomain}
              blog={blogs.find((b) => b.domainId === subdomain.domainId)}
              key={subdomain.domainId}
            />
          ))}
        </>
      )}
    </Card>
  );
};

ApexDomain.propTypes = {
  userId: PropTypes.number.isRequired,
  apexDomain: apexDomainShape.isRequired,
  blogs: PropTypes.arrayOf(blogShape).isRequired,
  userBlogType: PropTypes.string,
};
ApexDomain.defaultProps = {
  userBlogType: undefined,
};

export default ApexDomain;

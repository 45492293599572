import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import MuiButton from '@mui/material/Button';

const Button = ({ children, type, loading, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiButton size="large" type={type} {...rest}>
    {loading ? <CircularProgress size={20} color="inherit" /> : children}
  </MuiButton>
);

Button.propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  color: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
};

Button.defaultProps = {
  loading: false,
  type: 'button',
  children: null,
  color: 'primary',
  variant: 'contained',
  fullWidth: false,
};

export default Button;

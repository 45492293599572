import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const Icon = ({ sx }) => (
  <SvgIcon sx={sx} viewBox="0 0 100 87">
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="-.74"
        y1="792.26"
        x2=".26"
        y2="792.26"
        gradientTransform="translate(56.14 18830.22) scale(26.01 -23.73)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#d93227" />
        <stop offset="1" stopColor="#ea4435" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="-.08"
        y1="792.92"
        x2="-.52"
        y2="791.92"
        gradientTransform="translate(57.73 17882.31) scale(29.73 -22.52)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#208e45" />
        <stop offset="1" stopColor="#33a852" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="-.78"
        y1="792.99"
        x2=".09"
        y2="791.99"
        gradientTransform="translate(58.51 17882.3) scale(15.01 -22.52)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fcca34" />
        <stop offset="1" stopColor="#f9bc15" />
      </linearGradient>
    </defs>
    <g id="desktop">
      <path
        style={{ fill: '#bdbdbd' }}
        d="M92.7,5H7.3c-1.27,0-2.3,1.03-2.3,2.3v59.4c0,1.27,1.03,2.3,2.3,2.3H42v6.26c0,2.06-1.67,3.74-3.74,3.74h-6.34c-.51,0-.92,.41-.92,.92v2.08h38v-2.08c0-.51-.41-.92-.92-.92h-6.34c-2.06,0-3.74-1.67-3.74-3.74v-6.26h34.7c1.27,0,2.3-1.03,2.3-2.3V7.3c0-1.27-1.03-2.3-2.3-2.3Zm-1.7,54H9V9H91V59Z"
      />
    </g>
    <g id="chrome-logo">
      <g id="Chrome_Logo">
        <path
          id="Path_85"
          style={{ fill: 'url(#linear-gradient)' }}
          d="M63.01,26.49c-4.15-7.18-13.33-9.64-20.51-5.5-2.28,1.32-4.18,3.21-5.5,5.5l.9,16.22,25.11-16.22Z"
        />
        <path
          id="Path_86"
          style={{ fill: 'url(#linear-gradient-2)' }}
          d="M43.5,37.76l-6.5-11.26c-4.15,7.18-1.69,16.37,5.5,20.51,2.28,1.32,4.87,2.01,7.5,2.01l14.72-15.02-21.22,3.75Z"
        />
        <path
          id="Path_87"
          style={{ fill: 'url(#linear-gradient-3)' }}
          d="M56.51,37.76l-6.5,11.26c8.29,0,15.01-6.73,15.01-15.02,0-2.63-.69-5.22-2.01-7.5h-13l6.5,11.26Z"
        />
        <path
          id="Path_88"
          style={{ fill: '#fff' }}
          d="M50.01,41.51c4.15,0,7.51-3.36,7.51-7.51s-3.36-7.51-7.51-7.51c-4.15,0-7.51,3.36-7.51,7.51h0c0,4.15,3.36,7.51,7.51,7.51Z"
        />
        <path
          id="Path_89"
          style={{ fill: '#3f70b7' }}
          d="M50.01,39.95c3.28,0,5.95-2.66,5.95-5.95s-2.66-5.95-5.95-5.95h0c-3.28,0-5.95,2.66-5.95,5.95s2.66,5.95,5.95,5.95Z"
        />
      </g>
    </g>
  </SvgIcon>
);

Icon.propTypes = {
  sx: PropTypes.shape({
    fontSize: PropTypes.string,
    color: PropTypes.string,
  }),
};

Icon.defaultProps = {
  sx: {},
};

export default Icon;

import React from 'react';
import PropTypes from 'prop-types';
import { palette, fonts } from '../../theme';

const styles = {
  dark: {
    color: palette.black,
  },
  light: {
    color: palette.tertiary?.light,
  },
};

function PageTitle({ style, variant, align, children }) {
  return (
    <h1
      style={{
        textAlign: align,
        fontSize: '1.8rem',
        fontWeight: 700,
        ...styles[variant],
        fontFamily: fonts.atypText,
        ...style,
      }}
    >
      {children}
    </h1>
  );
}

PageTitle.propTypes = {
  style: PropTypes.shape({}),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  variant: PropTypes.oneOf(['dark', 'light']),
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

PageTitle.defaultProps = {
  style: undefined,
  variant: 'dark',
  align: 'center',
};

export default PageTitle;

import { bool, ref, string, object } from 'yup';

import {
  BUSINESS_MISSING,
  EMAIL_BAD,
  EMAIL_DOES_NOT_MATCH,
  EMAIL_MISSING,
  EMAIL_NOT_CONFIRMED,
  FIRSTNAME_SHORT,
  FIRSTNAME_MISSING,
  HEARDFROM_MISSING,
  INDUSTRY_MISSING,
  LASTNAME_SHORT,
  LASTNAME_MISSING,
  PASSWORD_DOES_NOT_MATCH,
  PASSWORD_MISSING,
  PASSWORD_NOT_CONFIRMED,
  PASSWORD_SHORT,
  PASSWORD_WEAK,
  PHONENUMBER_MISSING,
  TOS_MISSING,
  COUNTRY_MISSING,
  CONFIRMATIONCODE_SIZE,
  CONFIRMATIONCODE_MISSING,
  PHONENUMBER_BAD,
  INVALID_DOMAIN,
} from '../constants/errorMessages';
import { yupVerifyPhoneNumber } from './phoneNumber';

const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/;

// login, signup, passwordForgot
export const emailAddress = string().email(EMAIL_BAD).required(EMAIL_MISSING);
export const emailAddressConfirmation = string()
  .oneOf([ref('emailAddress'), null], EMAIL_DOES_NOT_MATCH)
  .required(EMAIL_NOT_CONFIRMED);

// login
export const loginPassword = string().required(PASSWORD_MISSING);

// create, profile
export const firstName = string().min(2, FIRSTNAME_SHORT).required(FIRSTNAME_MISSING);
export const lastName = string().min(2, LASTNAME_SHORT).required(LASTNAME_MISSING);
export const businessName = string().required(BUSINESS_MISSING);
export const industry = string().required(INDUSTRY_MISSING);

// create
export const currentWebsite = string().matches(
  /^(((?!-))(xn--)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30})\.[a-z]{2,}$/,
  INVALID_DOMAIN
);
export const accountPhoneNumber = string().required(PHONENUMBER_MISSING);
export const heardFrom = string().required(HEARDFROM_MISSING);
export const pleaseExplain = string();

// create, security
export const password = string()
  .matches(passwordRules, PASSWORD_WEAK)
  .min(12, PASSWORD_SHORT)
  .required(PASSWORD_MISSING);
export const passwordConfirmation = string()
  .required(PASSWORD_NOT_CONFIRMED)
  .oneOf([ref('password'), null], PASSWORD_DOES_NOT_MATCH);

// create
export const acceptedTos = bool().oneOf([true], TOS_MISSING);

// notification/contactMethods
export const country = string().required(COUNTRY_MISSING);
export const phoneNumber = string()
  .transform((value) => value.replace(/\D/g, ''))
  .required(PHONENUMBER_MISSING)
  // .min(10, PHONENUMBER_SHORT) // international numbers without the country code can be min 4 digits
  // .max(15, PHONENUMBER_LONG) // international numbers without the country code can be max 12 digits, 13 if the CC is 2 digits
  .test('verifyPhoneNumber', PHONENUMBER_BAD, yupVerifyPhoneNumber);
export const confirmationCode = string()
  .min(6, CONFIRMATIONCODE_SIZE)
  .max(6, CONFIRMATIONCODE_SIZE)
  .required(CONFIRMATIONCODE_MISSING);

export const createSchema = (definition) => object().shape(definition);

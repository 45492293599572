import { useQuery } from 'react-query';

import { getAccount } from '../api';
import { USER } from '../constants/queryData';

const defaults = {
  enabled: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
  staleTime: Infinity,
};
export default function useUser(settings = {}) {
  const { data, ...rest } = useQuery(USER, getAccount, { ...defaults, ...settings });

  return { user: data || {}, ...rest };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { palette } from '../../../theme';

const styles = {
  item: {
    color: palette.primary.main,
    '&:hover .MuiListItemText-root': {
      transform: 'translateX(-4px)',
    },
  },
  text: {
    transition: 'transform 0.45s',
  },
};

const MenuLink = ({ onClick, icon, text, to }) => (
  <ListItem to={to} onClick={onClick} component={Link} sx={styles.item} button>
    <ListItemIcon sx={{ color: palette.primary.main }}>{icon}</ListItemIcon>
    <ListItemText sx={styles.text}>{text}</ListItemText>
  </ListItem>
);

MenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  onClick: PropTypes.func,
};

MenuLink.defaultProps = {
  icon: null,
  onClick: null,
};

export default MenuLink;

import { createTheme } from '@mui/material/styles';

export const palette = {
  background: {
    default: '#eceef0',
  },
  text: {
    // primary: '#eceef0',
    // icons: '#eceef0',
  },
  primary: {
    main: '#272727',
    // contrastText: '#eceef0',
  },
  secondary: {
    main: '#4e5f69',
  },
  tertiary: createTheme().palette.augmentColor({
    color: {
      main: '#272727',
      light: '#eceef0',
    },
  }),
  neutral: {
    main: '#eceef0',
  },
  branding: {
    blue: '#e0f1ff',
    green: '#dbffbf',
    red: '#fcceb4',
    yellow: '#fff9bf',
  },
  brightBlue: '#2e97c2',
  black: '#272727',
  cardBg: '#fafafb',
  inputBorder: '#f1f1f5',
  paypalGray: '#e6e6e6',
  lighterGrey: '#f5f6f7',
  lightGrey: '#e8e9ea',
  mediumLightGrey: '#c6ccce',
  mediumGrey: '#a6a6a6',
  darkGrey: '#727272',
  error: { main: '#BA0000', light: '#ffacac' },
  // info: { main: '#86BBD8' },
  warning: { main: '#F9952A', light: '#f4b248', lighter: '#ffe4ba' },
  success: { main: '#6CC080', secondary: '#00a000' },
  contrastThreshold: 3,
  tonalOffset: 0.2,
};
export const fonts = {
  ambit: 'Ambit, sans-serif',
  maison: 'MaisonNeue, sans-serif',
  atyp: 'Atyp-Display, sans-serif',
  atypText: 'Atyp-Text, sans-serif',
};

const theme = createTheme({
  typography: {
    fontFamily: ['Ambit', 'sans-serif'].join(','),
    h3: {
      fontSize: '1.25rem',
      color: palette.black,
      lineHeight: 1.6,
    },
    h5: {
      fontSize: '18px',
    },
  },
  shape: {
    borderRadius: 0,
  },
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '.875rem',
          lineHeight: '1.43',
        },
        html: {
          WebkitFontSmoothing: 'auto',
        },
        a: {
          textDecoration: 'none',
        },
        '@global': {},
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: '#fff',
          minHeight: '40px',
          left: 0,
          width: 'calc(100% - 80px)',
          maxWidth: '1088px',
          borderBottom: '1px solid #272727',
          margin: '0 auto',
          paddingBottom: '5px',
          // eslint-disable-next-line
          ['@media screen and (max-width: 960px)']: {
            width: 'calc(100% - 40px)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        elevation1: {
          boxShadow: 'rgba(0, 0, 0, 0.2) 0 2px 5px',
          marginBottom: '1px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          '&.hasValue': {
            '.MuiOutlinedInput-root': {
              background: '#fff',
              '&.Mui-error': {
                background: `${palette.error.main}21`,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.black,
                borderWidth: '1px',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.error.main,
              },
            },
            '& .MuiInputLabel-asterisk': {
              color: palette.error.main,
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            backgroundColor: 'rgba(255, 0, 0, 0.08)',
          },
          '&.Mui-focused': {
            backgroundColor: 'rgba(46, 151, 194, 0.1)',
            boxShadow: 'none',
          },
        },
        formControl: {
          background: 'rgba(236, 238, 240, 0.5)',
          padding: '8px 21px',
          borderRadius: '24px',
          borderBottom: '1px solid rgba(255,255,255,.1)',
          border: '1px solid rgba(0,0,0,.1)',
        },
        underline: {
          '&:after': {
            borderBottom: 'none',
          },
          '&:before': {
            borderBottom: 'none',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '40px',
          borderRadius: '50px',
          background: `${palette.black}08`,
          marginBottom: '24px',
          '&.Mui-error': {
            marginBottom: 0,
          },
          '& input': {
            padding: '16px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.inputBorder,
            transition: 'border 0.4s ease-in-out',
          },
          '&.Mui-focused': {
            background: '#fff',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.black,
              borderWidth: '1px',
            },
          },
          '&.Mui-error, &.Mui-error.Mui-focused': {
            background: `${palette.error.main}21`,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.error.main,
            },
          },
          '& legend': {
            marginLeft: '0.2em',
          },
        },
        focused: {},
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(12px, 24px) scale(1)',
          '&.Mui-focused': {
            color: palette.primary.main,
          },
        },
        outlined: {
          transform: 'translate(8px, 6px) scale(1)',
          top: '3px',
          color: `${palette.black}99`,
          '&.Mui-focused, &.MuiFormLabel-filled': {
            transform: 'translate(7px, -12px) scale(1)',
            fontSize: '0.8em',
            color: palette.black,
            '&.Mui-error': {
              color: palette.error.main,
            },
          },
          '&.Mui-error, &.Mui-focused .MuiInputLabel-asterisk': {
            color: palette.error.main,
          },
        },
        formControl: {
          top: '4px',
          left: '10px',
        },
        shrink: {
          transform: 'translate(-10px, -7px) scale(0.75)',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          letterSpacing: '0.03333em',
          '&.Mui-error': {
            top: 'unset',
            padding: '0 18px',
            color: 'rgba(186, 0, 0, 1)',
            margin: '4px 0 8px',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          color: palette.error.main,
          border: `${palette.error.main} solid 2px`,
        },
        standardWarning: {
          color: palette.warning.main,
          border: `${palette.warning.main} solid 2px`,
        },
        standardSuccess: {
          color: palette.success.main,
          border: `${palette.success.main} solid 2px`,
        },
        outlined: {
          borderColor: palette.black,
          fontFamily: ['MaisonNeue', 'serif'].join(','),
          textTransform: 'uppercase',
          color: palette.black,
          '& .MuiAlert-icon, & .MuiAlert-action': {
            alignItems: 'center',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: '0px 25px',
          fontWeight: 400,
          letterSpacing: '0.1em',
          lineHeight: 1,
          border: 0,
          color: '#fff',
          borderRadius: '50px',
          height: '40px',
          fontFamily: ['MaisonNeue', 'serif'].join(','),
          '&.Mui-disabled': {
            backgroundColor: `${palette.black}24`,
          },
        },
        contained: {
          backgroundColor: palette.black,
          '&:hover': {
            backgroundColor: `${palette.black}d0`,
          },
        },
        primary: {
          backgroundColor: palette.black,
          justifyContent: 'flex-start',
          '&:hover': {
            backgroundColor: `${palette.black}d0`,
          },
        },
        secondary: {
          color: palette.black,
          backgroundColor: palette.background.default,
          '&:hover': {
            backgroundColor: `${palette.black}2b`,
          },
        },
        icon: {
          backgroundColor: palette.black,
          justifyContent: 'flex-start',
          padding: '0 10% 0',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: `${palette.black}d0`,
          },
        },
        inverted: {
          backgroundColor: palette.background.default,
          color: palette.black,
          '&:hover': {
            backgroundColor: `${palette.background.default}d0`,
          },
        },
        outlined: {
          color: palette.black,
          border: `1px solid ${palette.black}`,
          '&:hover': {
            backgroundColor: `${palette.black}20`,
            border: `1px solid ${palette.black}`,
          },
        },
        subscribe: {
          backgroundColor: palette.brightBlue,
          '&:hover': {
            backgroundColor: `${palette.brightBlue}d0`,
          },
        },
        text: {
          color: palette.primary.main,
        },
        containedSizeLarge: {
          padding: '0 15px',
          height: '40px',
        },
        textSizeLarge: {
          padding: '0 15px',
          height: '40px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          borderRadius: '24px',
          padding: '20px',
          boxShadow: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialog-paper': {
            borderRadius: '24px',
          },
        },
      },
    },
  },
});

export default theme;

/**
 * Create a publisher that can be subscribed to and notifies when its updated
 * @param {*} initialSubscribers an Array with the initial list of subscribers
 */
const publisherFactory = (initialSubscribers = []) => {
  const subscribers = initialSubscribers.slice();

  const unsubscribe = (fn) => {
    const index = subscribers.indexOf(fn);
    if (index >= 0) {
      subscribers.splice(index, 1);
    }
  };

  const subscribe = (fn) => {
    subscribers.push(fn);

    return () => unsubscribe(fn);
  };

  const publish = (value) => subscribers.forEach((fn) => fn(value));

  return {
    subscribe,
    unsubscribe,
    publish,
  };
};

export { publisherFactory, publisherFactory as default };

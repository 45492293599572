import styled from '@emotion/styled';
import { Box, List, ListSubheader } from '@mui/material';
import theme, { palette } from '../../../../theme';

export const ContributorList = styled(List)`
  width: 100%;
  margin-bottom: 1rem;

  ${theme.breakpoints.up('sm')} {
    margin-bottom: 0.5rem;
  }

  ${theme.breakpoints.down('sm')} {
    & .subheader {
      padding: 0;
    }
  }

  & .contributor {
    padding-left: 0;

    ${theme.breakpoints.up('sm')} {
      padding-left: 25px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1em;
    }

    ${theme.breakpoints.down('sm')} {
      padding-right: 0;
      margin-bottom: 0.5em;
    }

    & .content {
      width: 100%;
    }

    & .nameAndButtonRow {
      display: flex;
      justify-content: space-between;
    }

    & .blogInfo {
      display: inline-flex;
      width: 100%;
      font-size: 0.875rem;
      ${theme.breakpoints.up('sm')} {
        margin: 0 0 -1em 1em;
      }
    }

    & .appButtons {
      ${theme.breakpoints.down('sm')} {
        & .contributorLink.designApp {
          display: none;
        }
      }
      margin: 0.5em 0 0 0.5em;
      flex-direction: row;
      align-items: center;
    }
  }
`;

export const NameRow = styled(Box)`
  ${theme.breakpoints.up('sm')} {
    display: inline-flex;
    align-items: center;
  }

  & .name {
    font-size: 0.9rem;
    margin-right: 5px;
    word-break: break-all;
    &.inactive {
      color: #bbb;
    }
  }

  & .shareTime {
    color: #bbb;
    font-size: 0.7rem;
    word-break: break-all;
    ${theme.breakpoints.up('sm')} {
      line-height: 1;
    }
  }
`;

export const Subheader = styled(ListSubheader)`
  line-height: 16px;
  font-weight: 500;
  background: none;
  color: ${palette.primary.main};
`;

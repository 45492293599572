import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { Alert, Button, SubTitle, TextInput, When } from '../../common';
import CountrySelect from '../../common/CountrySelect';
import { updateProfile } from '../../../api';
import { yup, handleError, buildSubmitHandler } from '../../../utils';
import phoneUtil from '../../../utils/phoneNumber';
import queryData from '../../../utils/queryData';
import Transition from '../../common/Transition';

const profileHasChanged = (current, initial) =>
  current.firstName !== initial.firstName ||
  current.lastName !== initial.lastName ||
  current.businessName !== initial.businessName ||
  current.phoneNumber !== initial.phoneNumber ||
  current.country !== initial.country;

const getFinalValues = (values) => {
  const { phoneNumber, country, ...formFields } = values;
  const parsedNumber = phoneUtil.parse(phoneNumber, country);
  const accountPhone = phoneUtil.getE164Format(parsedNumber);
  return { ...formFields, phoneNumber: accountPhone };
};

const getInitialValues = (values) => {
  const { phoneNumber, ...profile } = values;
  let accountPhone = phoneNumber;
  let country = 'US';

  try {
    const parsedNumber = phoneUtil.parse(phoneNumber, country);
    const regionCode = phoneUtil.getRegionCode(parsedNumber);
    accountPhone = phoneUtil.getNationalFormat(parsedNumber);
    if (regionCode) {
      country = regionCode;
    }
  } catch (err) {
    // parsing failed just use initial values;
  }

  return { ...profile, phoneNumber: accountPhone, country };
};

const Profile = ({ firstName, lastName, businessName, phoneNumber }) => {
  const [isUpdated, setIsUpdated] = useState(false);
  const queryClient = useQueryClient();
  const { mutate } = useMutation(({ values }) => updateProfile(getFinalValues(values)), {
    onSuccess: (data) => {
      queryData.updateUser(data, queryClient);
      setIsUpdated(true);
    },
    onError: handleError,
  });
  const inputRefs = useRef({});
  const setInputRef = (element) => {
    if (element != null) {
      inputRefs.current[element.name] = element;
    }
  };

  const {
    errors,
    values,
    handleSubmit,
    handleChange,
    touched,
    resetForm,
    isSubmitting,
    initialValues,
  } = useFormik({
    initialValues: getInitialValues({ firstName, lastName, businessName, phoneNumber }),
    validationSchema: yup.createSchema({
      firstName: yup.firstName,
      lastName: yup.lastName,
      businessName: yup.businessName,
      country: yup.country,
      phoneNumber: yup.phoneNumber,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: buildSubmitHandler(mutate),
  });

  const isChanged = profileHasChanged(values, initialValues);
  const changeHandler = (event) => {
    setIsUpdated(false);
    handleChange(event);
  };

  return (
    <>
      <SubTitle mb={2}>Profile</SubTitle>
      <form onSubmit={handleSubmit}>
        <When
          it={errors.general}
          render={() => (
            <Transition>
              <Alert severity="error" noBorder>
                {errors.general}
              </Alert>
            </Transition>
          )}
        />
        <When it={isUpdated && !isChanged}>
          <Transition timeout={4000} callback={() => setIsUpdated(false)}>
            <Alert severity="success" noBorder>
              Account updated!
            </Alert>
          </Transition>
        </When>
        <TextInput
          variant="outlined"
          inputRef={setInputRef}
          type="text"
          label="First Name"
          name="firstName"
          value={values.firstName}
          onChange={changeHandler}
          error={touched.firstName && !!errors.firstName}
          helperText={touched.firstName && errors.firstName}
        />
        <TextInput
          inputRef={setInputRef}
          variant="outlined"
          type="text"
          label="Last Name"
          name="lastName"
          value={values.lastName}
          onChange={changeHandler}
          error={touched.lastName && !!errors.lastName}
          helperText={touched.lastName && errors.lastName}
        />
        <TextInput
          inputRef={setInputRef}
          variant="outlined"
          type="text"
          label="Business Name"
          name="businessName"
          value={values.businessName}
          onChange={changeHandler}
          error={touched.businessName && !!errors.businessName}
          helperText={touched.businessName && errors.businessName}
        />

        <SubTitle mb={2}>Phone</SubTitle>

        <CountrySelect
          name="country"
          label="Country"
          value={values.country}
          onChange={changeHandler}
        />

        <TextInput
          inputRef={setInputRef}
          variant="outlined"
          type="text"
          label="Phone Number"
          name="phoneNumber"
          value={values.phoneNumber}
          onChange={changeHandler}
          error={touched.phoneNumber && !!errors.phoneNumber}
          helperText={touched.phoneNumber && errors.phoneNumber}
        />

        <Box mb={2}>
          <When it={!isUpdated || isChanged}>
            <Button
              type="submit"
              style={{ marginTop: 6, marginBottom: 6 }}
              disabled={!isChanged || isSubmitting}
            >
              Update Profile
            </Button>
          </When>
          <When it={isChanged && !isSubmitting}>
            <Button
              style={{ marginLeft: 4, marginTop: 6, marginBottom: 6 }}
              variant="text"
              type="button"
              onClick={() => {
                resetForm();
                setIsUpdated(false);
              }}
            >
              Cancel
            </Button>
          </When>
        </Box>
      </form>
    </>
  );
};

Profile.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  businessName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
};

export default Profile;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Button, When } from '../../common';
import ReasonList from './ReasonList';
import FeatureList from './FeatureList';
import { SubscriptionPanel } from './Styled';

const MonthLabel = () => (
  <Typography component="em" className="monthLabel">
    /mo
  </Typography>
);

const MonthlyPrice = ({ price, onClick }) => (
  <>
    <Typography className="monthlyPrice">
      {`$${price}`}
      <MonthLabel />
    </Typography>
    <Button color="primary" variant="outlined" onClick={onClick} fullWidth>
      Subscribe Monthly
    </Button>
  </>
);

MonthlyPrice.propTypes = {
  onClick: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
};

const YearlyPrice = ({ price, periodTotal, onClick }) => (
  <>
    <Typography className="yearlyPrice">
      {`$${price}`}
      <MonthLabel />
    </Typography>
    <Button onClick={onClick} fullWidth className="yearSubButton">
      Subscribe Yearly
    </Button>
    <Typography className="yearTotal">${periodTotal.toFixed(0)} Billed Yearly</Typography>
  </>
);

YearlyPrice.propTypes = {
  onClick: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  periodTotal: PropTypes.number.isRequired,
};

const SubscriptionPlan = ({ plan, onChoosePlan }) => {
  const chooseYearly = () => {
    onChoosePlan(plan, 'yearly');
  };
  const chooseMonthly = () => {
    onChoosePlan(plan, 'monthly');
  };

  return (
    <SubscriptionPanel>
      <Typography variant="h3" className="planName">
        {plan.name}
      </Typography>
      <Box className="priceBox">
        <YearlyPrice
          price={plan.yearly.price}
          periodTotal={plan.yearly.periodTotal}
          onClick={chooseYearly}
          planName={plan.name}
        />
        <MonthlyPrice price={plan.monthly.price} onClick={chooseMonthly} />
      </Box>
      <When it={plan.reasons}>
        <ReasonList reasons={plan.reasons} />
      </When>
      <When it={plan.features}>
        <FeatureList features={plan.features} />
      </When>
    </SubscriptionPanel>
  );
};

SubscriptionPlan.defaultProps = {
  onChoosePlan: undefined,
};

SubscriptionPlan.propTypes = {
  onChoosePlan: PropTypes.func,
  plan: PropTypes.shape({
    name: PropTypes.string,
    monthly: PropTypes.shape({
      price: PropTypes.number,
    }),
    yearly: PropTypes.shape({
      price: PropTypes.number,
      periodTotal: PropTypes.number,
    }),
    reasons: ReasonList.propTypes.reasons,
    features: FeatureList.propTypes.features,
  }).isRequired,
};

export default SubscriptionPlan;

import { useQuery } from 'react-query';
import { getBlogs } from '../api';
import { BLOG_DOMAINS } from '../constants/queryData';
import useUser from './useUser';

const defaults = {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
  staleTime: Infinity,
};
/**
 * @param {number?} userId - Optional. Defaults to current user.
 * @param {object?} settings - Optional. Override default query settings.
 */
const useBlogs = (userId, settings = {}) => {
  const { user } = useUser();
  const { data: blogs = [], ...rest } = useQuery(
    [BLOG_DOMAINS, userId || user.id],
    () => getBlogs(userId || user.id),
    {
      ...defaults,
      ...settings,
    }
  );

  return { blogs, ...rest };
};

export default useBlogs;

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { Alert, Button, Link, SubTitle, TextInput, When } from '../common';
import { login } from '../../api';
import { ACCOUNT, PASSWORD_FORGOT, SIGNUP } from '../../constants/routePaths';
import { yup, buildSubmitHandler, formikErrorFocus, handleError } from '../../utils';
import queryData from '../../utils/queryData';
import PasswordInput from '../common/PasswordInput';
import FormFooter from '../common/FormFooter';
import Transition from '../common/Transition';
import { logoutUser } from '../../utils/user';

const fieldNames = ['emailAddress', 'password'];

const LoginForm = ({ autoLoggedOut }) => {
  const location = useLocation();
  const history = useHistory();
  const sameSession = sessionStorage.getItem('sameSession');
  const queryClient = useQueryClient();
  const { from } = location.state || { from: { pathname: ACCOUNT } };

  useEffect(() => {
    if (autoLoggedOut) {
      logoutUser();
    }
  }, [autoLoggedOut]);

  const { mutate } = useMutation(({ values }) => login(values), {
    onSuccess: (user) => {
      queryData.updateUser(user, queryClient);
      history.replace(from);
    },
    onError: handleError,
  });

  const inputRefs = useRef({});
  const setInputRef = (element) => {
    if (element != null) {
      inputRefs.current[element.name] = element;
    }
  };

  const { errors, values, handleSubmit, handleChange, touched, isSubmitting } = useFormik({
    initialValues: {
      emailAddress: '',
      password: '',
    },
    validationSchema: yup.createSchema({
      emailAddress: yup.emailAddress,
      password: yup.loginPassword,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: buildSubmitHandler(mutate),
  });

  useEffect(() => {
    formikErrorFocus(fieldNames, errors, inputRefs.current);
  }, [errors]);

  return (
    <>
      <SubTitle cardTitle variant="h6" mb={4}>
        Account Sign In
      </SubTitle>
      <form onSubmit={handleSubmit}>
        <When
          it={errors.general}
          render={() => (
            <Transition>
              <Alert noBorder severity="error">
                {errors.general}
              </Alert>
            </Transition>
          )}
        />
        <When it={!!autoLoggedOut && !!sameSession}>
          <Transition>
            <Alert severity="info" noBorder>
              Your session timed out.
            </Alert>
          </Transition>
        </When>
        <TextInput
          inputRef={setInputRef}
          type="text"
          label="Your email"
          name="emailAddress"
          value={values.emailAddress}
          onChange={(e) => {
            e.target.value = e.target.value.trim();
            handleChange(e);
          }}
          error={touched.emailAddress && !!errors.emailAddress}
          helperText={touched.emailAddress && errors.emailAddress}
        />
        <PasswordInput
          inputRef={setInputRef}
          type="password"
          label="Your password"
          name="password"
          required={false}
          value={values.password}
          onChange={handleChange}
          error={touched.password && !!errors.password}
          helperText={touched.password && errors.password}
        />
        <Box mb={3}>
          <Button type="submit" fullWidth loading={isSubmitting} variant="contained">
            Continue
          </Button>
        </Box>
        <FormFooter>
          <Link component={RouterLink} to={SIGNUP} underline="hover">
            Create a New Account
          </Link>
          <Link component={RouterLink} to={PASSWORD_FORGOT} underline="hover">
            Lost Password?
          </Link>
        </FormFooter>
      </form>
    </>
  );
};

LoginForm.propTypes = {
  autoLoggedOut: PropTypes.bool.isRequired,
};

export default LoginForm;

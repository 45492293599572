import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { countries } from './countryalphaoptions';

const countryItems = countries.map(({ diagraph, cc, name }) => (
  <option key={diagraph} value={diagraph}>
    {`${name} (+${cc})`}
  </option>
));

const CountrySelect = ({ label, name, value, onBlur, onChange }) => (
  <TextField
    select
    style={{ textAlign: 'left' }}
    label={label}
    name={name}
    variant="outlined"
    value={value}
    onBlur={onBlur}
    onChange={onChange}
    SelectProps={{
      native: true,
    }}
  >
    {countryItems}
  </TextField>
);

CountrySelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

CountrySelect.defaultProps = {
  label: 'Country',
  value: '',
  onBlur: undefined,
  onChange: undefined,
};

export default CountrySelect;

import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

const StyledTimeoutContentWrapper = styled(Box, {
  shouldForwardProp: (name) => name !== 'visible',
})`
  height: 0;
  overflow-y: hidden;
  transition: height 0.3s ease;
  ${({ visible, height }) => (visible ? `height: ${height}px;` : '')}
`;

const WrapperChildren = forwardRef((props, ref) => (
  <Box className="wrapper-children" ref={ref}>
    {props.children}
  </Box>
));
WrapperChildren.displayName = 'WrapperChildren';

const Transition = ({ timeout, callback, children, mb }) => {
  const [visible, setVisible] = useState(false);
  const [ref, setRef] = useState();

  useEffect(() => {
    let timeoutId;
    if (timeout && callback) {
      timeoutId = setTimeout(callback, timeout);
    }
    return () => clearTimeout(timeoutId);
  });

  useEffect(() => {
    setVisible(true);
    return () => setVisible(false);
  }, []);

  const height = (ref?.clientHeight || 0) + mb;

  return (
    <StyledTimeoutContentWrapper visible={visible} height={height}>
      <WrapperChildren ref={setRef}>{children}</WrapperChildren>
    </StyledTimeoutContentWrapper>
  );
};

WrapperChildren.propTypes = {
  children: PropTypes.element.isRequired,
};

Transition.defaultProps = {
  timeout: undefined,
  callback: undefined,
  mb: 16,
};

Transition.propTypes = {
  timeout: PropTypes.number,
  callback: PropTypes.func,
  children: PropTypes.element.isRequired,
  mb: PropTypes.number,
};

export default Transition;

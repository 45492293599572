import React from 'react';
import styled from '@emotion/styled';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import { Button } from '../../../common';
import { palette } from '../../../../theme';

export const QuickLinkButton = styled(Button)`
  width: 100%;
  min-width: 215px;
  line-height: 1;
  &.MuiButton-secondary {
    justify-content: flex-start;
    padding: 0 10% 0;
    whitespace: nowrap;
    border: 1px solid ${palette.primary.main};
  }
`;

export const disabledQuickLinkButtonStyle = {
  color: 'rgba(0, 0, 0, 0.12)',
  borderColor: 'rgba(0, 0, 0, 0.12)',
  '&.MuiButtonBase-root:hover': {
    bgcolor: 'transparent',
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
};

const DashboardQuickLink = ({ url, icon, title, label, sx }) => (
  <Link sx={{ width: '100%', ...sx }} underline="none" title={title} href={url} target="_blank">
    <QuickLinkButton startIcon={icon} type="submit" variant="primary">
      {label}
    </QuickLinkButton>
  </Link>
);

DashboardQuickLink.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  label: PropTypes.string,
  sx: PropTypes.shape({}),
};

DashboardQuickLink.defaultProps = {
  url: '',
  icon: null,
  title: '',
  label: '',
  sx: {},
};

export default DashboardQuickLink;

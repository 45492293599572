import React, { useRef } from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import PropTypes from 'prop-types';

import { Alert, Button, PasswordNoteCard, SubTitle, When } from '../index';
import { yup, handleError, buildSubmitHandler } from '../../../utils';
import PasswordInput from '../PasswordInput';
import Transition from '../Transition';

const PasswordUpdateForm = ({ onSubmit: updatePassword, actionTitle, onSuccess }) => {
  const initialValues = { password: '', passwordConfirmation: '' };
  const {
    mutate,
    isSuccess,
    reset: resetPasswordUpdate,
  } = useMutation(
    ({ values }) => {
      updatePassword(values);
    },
    {
      onSuccess: (data, { actions }) => {
        actions.resetForm();
        if (onSuccess) {
          onSuccess(true);
        }
      },
      onError: handleError,
    }
  );
  const inputRefs = useRef({});
  const setInputRef = (element) => {
    if (element != null) {
      inputRefs.current[element.name] = element;
    }
  };

  const { errors, values, handleSubmit, handleChange, touched } = useFormik({
    initialValues,
    validationSchema: yup.createSchema({
      password: yup.password,
      passwordConfirmation: yup.passwordConfirmation,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: buildSubmitHandler(mutate),
  });

  return (
    <form onSubmit={handleSubmit}>
      <SubTitle my={2}>{actionTitle} Password</SubTitle>
      <PasswordNoteCard
        password={values.password}
        passwordConfirmation={values.passwordConfirmation}
      />
      <When it={isSuccess}>
        <Transition timeout={4000} callback={resetPasswordUpdate}>
          <Alert severity="success" variant="outlined" noBorder>
            Password updated successfully.
          </Alert>
        </Transition>
      </When>

      <When it={errors.general}>
        <Transition>
          <Alert severity="error" variant="outlined" noBorder>
            {errors.general}
          </Alert>
        </Transition>
      </When>
      <PasswordInput
        inputRef={setInputRef}
        type="password"
        variant="outlined"
        label="New Password"
        name="password"
        value={values.password}
        onChange={handleChange}
        error={touched.password && !!errors.password}
        helperText={touched.password && errors.password}
      />
      <PasswordInput
        inputRef={setInputRef}
        type="password"
        variant="outlined"
        label="Confirm New Password"
        name="passwordConfirmation"
        value={values.passwordConfirmation}
        onChange={handleChange}
        error={touched.passwordConfirmation && !!errors.passwordConfirmation}
        helperText={touched.passwordConfirmation && errors.passwordConfirmation}
      />
      <Button type="submit" fullWidth style={{ marginBottom: '2em' }}>
        {`${actionTitle} Password`}
      </Button>
    </form>
  );
};

PasswordUpdateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  actionTitle: PropTypes.string,
};

PasswordUpdateForm.defaultProps = {
  actionTitle: 'Update',
  onSuccess: undefined,
};

export default PasswordUpdateForm;

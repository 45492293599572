import React, { useState } from 'react';
import { Box, Checkbox, FormGroup, FormControlLabel, Link, Typography } from '@mui/material';
import { Button } from '../../../common';
import useAcceptReferralTos from './hooks/useAcceptReferralTos';
import Links from '../../../../constants/links';

const ReferralTermsOfService = () => {
  const [state, setState] = useState({
    acceptedReferralTos: false,
  });
  const { mutate: acceptTOS, isLoading } = useAcceptReferralTos();

  const handleReferralTermsChange = (e) => {
    setState({ ...state, acceptedReferralTos: e.target.checked });
  };
  return (
    <>
      <Box
        mt={1}
        display="flex"
        justifyContent={{ xs: 'center', md: 'flex-start' }}
        data-testid="referral-terms-of-service"
      >
        <FormGroup align="center" row>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={state.acceptedReferralTos}
                onChange={handleReferralTermsChange}
                color="secondary"
                inputProps={{ 'aria-label': 'Terms of Service agreement checkbox' }}
              />
            }
            label={
              <Typography variant="body2" color="secondary">
                I agree to the{' '}
                <Link
                  target="_blank"
                  href={Links.REFERRAL_TOS}
                  title="Learn more"
                  color="brightBlue"
                  underline="hover"
                >
                  Terms and Conditions
                </Link>
              </Typography>
            }
          />
        </FormGroup>
      </Box>
      <Box
        mt={1}
        mb={4}
        textAlign={{
          xs: 'center',
          md: 'left',
        }}
      >
        <Button
          loading={isLoading}
          type="submit"
          color="primary"
          disabled={!state.acceptedReferralTos}
          onClick={() => acceptTOS(state.acceptedReferralTos)}
        >
          Create Referral Link
        </Button>
      </Box>
    </>
  );
};
export default ReferralTermsOfService;

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { ACCOUNT_PROFILE } from '../../../../constants/routePaths';
import { Alert } from '../../../common';
import WarningIcon from '../../../icons/WarningIcon';
import { palette } from '../../../../theme';

const ConfirmEmail = () => (
  <Box mt={3}>
    <Alert
      severity="warning"
      variant="outlined"
      icon={<WarningIcon w="1.35rem" h="1.35rem" display="flex" color={palette.warning.main} />}
      sx={{
        borderRadius: '1rem',
      }}
    >
      Please Confirm Your Email.{' '}
      <Link component={RouterLink} to={ACCOUNT_PROFILE}>
        Go To Profile
      </Link>
    </Alert>
  </Box>
);

export default ConfirmEmail;

import { useQuery } from 'react-query';
import { DESIGNER_SHOWCASE } from '../constants/queryData';
import { getDesigner } from '../api';

const defaults = {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
  staleTime: Infinity,
};

const useDesigner = () => {
  const { data: designer, ...rest } = useQuery(DESIGNER_SHOWCASE, getDesigner, defaults);
  return { ...rest, designer };
};

export default useDesigner;

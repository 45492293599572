import { useEffect, useState } from 'react';

const useImageLoaded = (url) => {
  const [loaded, setLoaded] = useState(false);
  const [img, setImg] = useState(false);

  useEffect(() => {
    if (url && !loaded && !img) {
      const imgElement = document.createElement('img');
      imgElement.id = url;
      imgElement.onload = () => {
        setLoaded(true);
        imgElement.remove();
      };
      imgElement.src = url;
      setImg(imgElement);
    }
    // eslint-disable-next-line
  }, [loaded, url]);

  return loaded;
};

export default useImageLoaded;

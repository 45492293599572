import { useQuery } from 'react-query';
import { getUserPayoutMethod } from '../api';
import { USER_PAYOUT_METHOD } from '../constants/queryData';

export default function usePayoutMethod(settings = {}) {
  const { data, ...rest } = useQuery([USER_PAYOUT_METHOD], getUserPayoutMethod, {
    ...settings,
  });
  return { userPayoutMethod: data?.userPayoutMethod || {}, ...rest };
}

import React, { useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, Card, CircularProgress, Grid } from '@mui/material';
import { useMutation } from 'react-query';

import { Alert, Button, Link, Instruction, ServicePage, SubTitle } from '../common';
import { emailConfirmation } from '../../api';
import { EMAIL_CONFIRMATION, LOGIN } from '../../constants/routePaths';
import PageContent from '../common/PageContent';
import OuterCardLogo from '../common/OuterCardLogo';

const locationUrl = new URL(document.location.href);
const confirmationSession = {
  token: locationUrl.searchParams.get('action'),
  tokenExpires: locationUrl.searchParams.get('exp'),
};

const UserEmailConfirm = () => {
  const history = useHistory();
  const { mutate, isError, isSuccess } = useMutation(({ token }) => emailConfirmation(token), {
    onError: ({ response }) => {
      // eslint-disable-next-line no-console
      console.log(response);
    },
  });

  useEffect(() => {
    history.replace(EMAIL_CONFIRMATION);
  }, [history]);

  useEffect(() => mutate(confirmationSession), [mutate]);

  return (
    <ServicePage>
      <PageContent maxWidth="xs">
        <OuterCardLogo />
        <Card>
          {!isSuccess && !isError && (
            <Grid container direction="row" justifyContent="center">
              <Grid item component={CircularProgress} />
            </Grid>
          )}
          {isError && (
            <>
              <SubTitle cardTitle>Confirmation Failed</SubTitle>
              <Alert centered noBorder severity="error" style={{ marginTop: 16 }}>
                Unable to confirm.
              </Alert>
              <Instruction>
                Looks like we had an issue please{' '}
                <Link component={RouterLink} to={LOGIN}>
                  log in
                </Link>{' '}
                to your account and request a new link.
              </Instruction>
            </>
          )}
          {isSuccess && (
            <>
              <SubTitle cardTitle>Confirmation Successful</SubTitle>
              <Alert centered noBorder severity="success" style={{ marginTop: 16 }}>
                Email address confirmed.
              </Alert>
              <Box my={2}>
                <Button component={RouterLink} to={LOGIN} fullWidth>
                  Sign In
                </Button>
              </Box>
            </>
          )}
        </Card>
      </PageContent>
    </ServicePage>
  );
};

export default UserEmailConfirm;

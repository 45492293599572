import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { palette } from '../../../theme';
import MessageContent from './MessageContent';

const Summary = styled(AccordionSummary)`
  display: flex;
  flex-direction: row;
  border-bottom: none;
  & .MuiAccordionSummary-content {
    max-width: calc(100% - 24px);
    flex-shrink: 1;
  }
  &.Mui-expanded {
    background-color: ${palette.background.default};
  }
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  margin-bottom: 0;
  &.Mui-expanded {
    margin-top: 0;
  }
`;

const MessageSubject = styled('div')`
  flex: '1';
  height: '1.3em';
  font-weight: 500;
  line-height: '1.4em';
  width: '1em';
  color: ${palette.primary.main};
  fontsize: '16px';
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
`;

const MessageDate = styled('div')`
  flex: '0 0 6.25em';
  color: ${palette.neutral.secondary};
  opacity: '0.5';
  align-self: 'center';
  margin-right: '10px';
  fontsize: '16px';
  white-space: nowrap;
`;

const formatDate = (dateString) =>
  new Date(dateString).toLocaleDateString([], { year: 'numeric', month: 'short', day: 'numeric' });

const Message = ({ id, createdAt, subject }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <StyledAccordion
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      onChange={(error, isExpanded) => setExpanded(isExpanded)}
      expanded={expanded}
      key={id}
    >
      <Summary expandIcon={<ExpandMoreIcon />}>
        <MessageDate>{formatDate(createdAt)}</MessageDate>
        <MessageSubject>{subject || ' - No Message Subject'}</MessageSubject>
      </Summary>
      <AccordionDetails>
        <MessageContent id={id} />
      </AccordionDetails>
    </StyledAccordion>
  );
};

Message.propTypes = {
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
};

export default Message;

import React from 'react';
import { Grid, CircularProgress, Card, Typography } from '@mui/material';

const LoadingIndicator = () => (
  <Card style={{ padding: '15px 20px' }}>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      py={1}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
      <Grid item>
        <Typography>Loading...</Typography>
      </Grid>
    </Grid>
  </Card>
);

export default LoadingIndicator;

import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import {
  ACCOUNT,
  ACCOUNT_REFERRAL,
  CREATE,
  CREATE_SECURE,
  EMAIL_CONFIRMATION,
  FAIL,
  EXPIRED,
  LOGIN,
  PASSWORD_FORGOT,
  PASSWORD_RESET,
  ROOT,
  SIGNUP,
  SSO,
  SUCCESS,
  AUTHORIZED_ROUTES,
  THIRD_PARTY_DELETE_STATUS,
} from '../constants/routePaths';
import { Create, Signup, Fail, CreateSecure } from './create';
import Expired from './create/Expired';
import Login from './login';
import EmailConfirm from './emailConfirm';
import ForgotPassword from './passwordForgot';
import ResetPassword from './passwordReset';
import SSOLogin from './sso';
import Account from './account';
import Referral from './account/subscription/ReferralCard/ReferralLanding';
import NotFound from './notFound';
import { useAuthorization } from '../api/session';
import Success from './create/Success';
import DeleteStatus from './account/thirdParty/DeleteStatus';

const PrivateRoute = ({ component: Component, authorized, path, redirect, exact = false }) => (
  <Route
    exact={exact}
    path={path}
    render={({ location }) =>
      authorized ? (
        <Component />
      ) : (
        <Redirect to={{ pathname: redirect, state: { from: location } }} />
      )
    }
  />
);

const AppRoutes = () => {
  const { isAuthorized, timedOut } = useAuthorization();
  return (
    <Router>
      <Switch>
        <Route path={LOGIN} render={() => <Login autoLoggedOut={timedOut} />} />
        <Route path={SSO} component={SSOLogin} />
        <Route path={PASSWORD_FORGOT} component={ForgotPassword} />
        <Route path={PASSWORD_RESET} component={ResetPassword} />
        <Route path={SIGNUP} component={Signup} />
        <Route path={CREATE_SECURE} component={CreateSecure} />
        <Route path={CREATE} component={Create} />
        <Route path={SUCCESS} component={Success} />
        <Route path={EMAIL_CONFIRMATION} component={EmailConfirm} />
        <Route path={THIRD_PARTY_DELETE_STATUS} component={DeleteStatus} />
        <Route path={FAIL} component={Fail} />
        <Route path={EXPIRED} component={Expired} />
        <Route path={ACCOUNT_REFERRAL} component={Referral} />
        {AUTHORIZED_ROUTES.map((route) => (
          <PrivateRoute
            key={route.path}
            authorized={isAuthorized}
            path={route.path}
            exact={route.exact}
            redirect={LOGIN}
            component={Account}
          />
        ))}
        <Route path={ROOT} render={() => <Redirect to={ACCOUNT} />} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

PrivateRoute.defaultProps = {
  exact: false,
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.array]).isRequired,
  authorized: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

export default AppRoutes;

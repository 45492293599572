/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider, Typography, Link, ListItem, ListItemIcon } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

import { palette } from '../../../theme';
import { When } from '../../common';
import { Header, PlanList } from './Styled';

const ReasonList = ({ reasons }) => (
  <>
    <Divider />
    <Header>Best choice if...</Header>
    <PlanList>
      {reasons.map((reason, reasonsIndex) => (
        <Fragment key={`fragment-${reasonsIndex}`}>
          <ListItem disableGutters className="reason">
            <ListItemIcon>
              <CheckCircleOutline sx={{ fill: palette.success.main }} />
            </ListItemIcon>
            <Typography variant="span" className="">
              {reason.link
                ? reason.link.map(({ href, text }, reasonIndex) =>
                    href ? (
                      <Link
                        href={href}
                        target="_blank"
                        underline="hover"
                        key={`link-${reasonIndex}`}
                      >
                        {text}
                      </Link>
                    ) : (
                      text
                    )
                  )
                : reason.text}
            </Typography>
          </ListItem>
          <When it={reasonsIndex < reasons.length - 1}>
            <Divider component="li" className="wordDivider" />
          </When>
        </Fragment>
      ))}
    </PlanList>
  </>
);

ReasonList.defaultProps = {
  reasons: [],
};

ReasonList.propTypes = {
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      html: PropTypes.bool,
      append: PropTypes.string,
    })
  ),
};

export default ReasonList;

import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { penniesToDollars } from '../../../utils/money';
import { StyledTable } from './components';
import { palette } from '../../../theme';
import { SUCCESS } from '../../../constants/paypalStatuses';
import { AffiliateTransactionStatuses, PaypalStatuses } from '../../../constants';
import { formatMonthDayYear } from '../../../utils/dateTime';

const { VOID } = AffiliateTransactionStatuses;

const AffiliateTransactions = ({ transactions }) =>
  transactions.length > 0 ? (
    <StyledTable aria-label="affiliate transactions table">
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Notes</TableCell>
          <TableCell align="right">Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map((t) => (
          <TableRow
            key={t.transactionId}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="left">{formatMonthDayYear(new Date(t.createDate))}</TableCell>
            <TableCell
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {t.notes}
              {t.status === VOID && (
                <Chip
                  label="VOID"
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ ml: 1, lineHeight: 1, fontSize: 12 }}
                />
              )}
              {t.amount < 0 && t.processorStatus !== SUCCESS ? (
                <Typography
                  component="span"
                  fontSize="small"
                  ml={1}
                  color={
                    !t.processorStatus || t.processorStatus === PaypalStatuses.PENDING
                      ? 'gray'
                      : 'error.main'
                  }
                >
                  ({t.processorStatus || 'PROCESSING'})
                </Typography>
              ) : null}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                color:
                  t.status === VOID
                    ? 'mediumGrey'
                    : t.amount < 0
                    ? 'error.main'
                    : 'success.secondary',
              }}
            >
              {penniesToDollars(t.amount)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  ) : (
    <Typography variant="body2" color={palette.darkGrey} fontStyle="italic" ml={1}>
      No data available
    </Typography>
  );

AffiliateTransactions.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      transactionId: PropTypes.number,
      amount: PropTypes.number,
      status: PropTypes.string,
      processorStatus: PropTypes.string,
      createDate: PropTypes.string,
      notes: PropTypes.string,
    })
  ),
};
AffiliateTransactions.defaultProps = {
  transactions: [],
};
export default AffiliateTransactions;

import React from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { blogHostingStatus, blogStatuses } from '../../../constants';
import Links from '../../../constants/links';
import { blogShape } from '../../../domain/PropTypes';

const BlogStatusMessage = ({ hasBlogPlan, hasBlogRecords, blog }) => {
  const blogReady =
    blog?.status === blogStatuses.AVAILABLE && blog?.hostingStatus === blogHostingStatus.ACTIVE;

  return (
    <Box mt={1}>
      {hasBlogPlan && !blog && !hasBlogRecords && (
        <Typography>
          It appears you have a subscription that includes a blog. You must submit your setup
          request before DNS can be provided. Learn how to submit a request{' '}
          <Link
            href={Links.BLOG_SETUP_HELP_DOC}
            target="_blank"
            rel="noopener noreferrer"
            title="Blog Setup Help Doc"
            underline="always"
          >
            here.
          </Link>
        </Typography>
      )}
      {hasBlogPlan && blog && !blogReady && (
        <Typography>
          We are setting up your blog. Our support team will contact you when your blog is ready.
        </Typography>
      )}
    </Box>
  );
};
BlogStatusMessage.propTypes = {
  hasBlogPlan: PropTypes.bool,
  hasBlogRecords: PropTypes.bool,
  blog: blogShape,
};
BlogStatusMessage.defaultProps = {
  hasBlogPlan: false,
  hasBlogRecords: false,
  blog: undefined,
};

export default BlogStatusMessage;

import React from 'react';
import { PropTypes } from 'prop-types';
import { palette } from '../../theme';

export const statusColors = {
  pending: palette.primary.main, // Pending
  success: palette.success.main, // Active
  error: palette.error.main, // Inactive
};
export default function DotIndicator({ status }) {
  return (
    <div
      title="statusDot"
      style={{
        width: '.5rem',
        height: '.5rem',
        borderRadius: '100%',
        backgroundColor: statusColors[status],
        marginLeft: '.5rem',
      }}
    />
  );
}

DotIndicator.propTypes = {
  status: PropTypes.string.isRequired,
};

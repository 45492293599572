import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Link, Box } from '@mui/material';
import Message from './Message';
import config from '../../../config';

const NoMessages = () => (
  <Box sx={{ padding: '15px 20px' }}>
    <Typography>No messages were found for your account.</Typography>
    <Typography>
      If you haven&apos;t already,&nbsp;
      <Link
        href={config.ADD_CONTACT_FORM_LEARN_URL}
        target="_blank"
        rel="noopener noreferrer"
        title="Contact Form Help Doc"
      >
        add a contact form to your website
      </Link>
      .
    </Typography>
  </Box>
);

const MessageList = ({ list }) =>
  list.map(({ id, createdAt, subject }) => (
    <Message key={id} id={id} createdAt={createdAt} subject={subject} />
  ));

const Messages = ({ list }) => (list.length === 0 ? <NoMessages /> : <MessageList list={list} />);

const ListShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
});

MessageList.propTypes = {
  list: PropTypes.arrayOf(ListShape).isRequired,
};

Messages.propTypes = {
  list: PropTypes.arrayOf(ListShape),
};

Messages.defaultProps = {
  list: [],
};

export default Messages;

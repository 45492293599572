function scrollTo(element, offset = 0) {
  const elemPosition = element.getBoundingClientRect().top;
  const offsetPosition = elemPosition - offset;
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
}

export default scrollTo;

import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import { TextInput } from '.';

const PasswordInput = ({
  name,
  label,
  helperText,
  inputRef,
  value,
  onChange,
  onBlur,
  error,
  variant,
  required,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextInput
      required={required}
      name={name}
      label={label}
      type={showPassword ? 'text' : 'password'}
      inputRef={inputRef}
      variant={variant}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: showPassword ? (
          <Visibility onClick={() => setShowPassword(false)} style={{ cursor: 'pointer' }} />
        ) : (
          <VisibilityOff onClick={() => setShowPassword(true)} style={{ cursor: 'pointer' }} />
        ),
      }}
    />
  );
};
PasswordInput.defaultProps = {
  required: true,
  variant: 'outlined',
  onBlur: null,
  error: null,
  helperText: null,
};

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  variant: PropTypes.string,
};

export default PasswordInput;

import React, { useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Container, LinearProgress, Grid } from '@mui/material';

import { PageTitle, ServicePage } from '../common';
import { sso } from '../../api';
import { ACCOUNT, LOGIN, PROTECTED_ROUTES } from '../../constants/routePaths';
import QueryParams from '../../constants/queryParams';
import StorageParams from '../../constants/storageParams';
import useSessionStorage from '../../hooks/useSessionStorage';

const SSOLogin = () => {
  const [, setReferrer] = useSessionStorage(StorageParams.SSO_SOURCE, false);
  const history = useHistory();
  const location = useLocation();
  const { token } = useParams();
  const { mutate } = useMutation(sso, {
    onSuccess: () => {
      let path = ACCOUNT;
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has(QueryParams.PATH)) {
        const queryPath = queryParams.get(QueryParams.PATH);
        if (PROTECTED_ROUTES[queryPath]) {
          path = queryPath;
        }
      }

      if (queryParams.has(QueryParams.SSO_SOURCE)) {
        setReferrer(queryParams.get(QueryParams.SSO_SOURCE));
      }
      history.replace(path);
    },
    onError: () => {
      history.replace(LOGIN);
    },
  });

  useEffect(() => mutate(token), [mutate, token]);

  return (
    <ServicePage>
      <Container maxWidth="xs">
        <PageTitle>Signing in...</PageTitle>
        <Grid container direction="row" justifyContent="center">
          <LinearProgress style={{ width: '100%' }} />
        </Grid>
      </Container>
    </ServicePage>
  );
};

export default SSOLogin;

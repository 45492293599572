import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Alert as MuiAlert } from '@mui/material';
import ErrorIcon from '@mui/icons-material/ReportProblemOutlined';
import { palette } from '../../theme';

const StyledAlert = styled(MuiAlert, { shouldForwardProp: (name) => name !== 'noBorder' })`
  margin-bottom: 24px;
  background-color: #fff;
  & .MuiAlert-icon {
    align-items: flex-start;
  }
  ${({ centered }) => (centered ? 'justify-content: center;' : '')}
  ${({ noBorder }) => (noBorder ? 'border: none;' : '')}
  ${({ severity }) => (severity === 'error' ? `color: ${palette.error.main};` : '')}
  ${({ transparent }) => (transparent ? 'background-color: transparent;' : '')}
`;

const Alert = React.forwardRef(({ children, severity, ...props }, ref) => {
  const modifiedProps = {};

  if (['error', 'warning'].includes(severity)) {
    modifiedProps.icon = <ErrorIcon />;
  }

  return (
    <StyledAlert {...modifiedProps} variant="outlined" severity={severity} {...props} ref={ref}>
      {children}
    </StyledAlert>
  );
});

Alert.displayName = 'Alert';

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
};

Alert.defaultProps = {
  severity: 'warning',
};

export default Alert;

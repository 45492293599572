import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useQueryParams from '../../../hooks/useQueryParams';
import { ACCOUNT_REFERRALS, PAYPAL_CALLBACK } from '../../../constants/routePaths';
import { syncPayPalUser } from '../../../api';
import { Instruction, SubTitle } from '../../common';
import WarningIcon from '../../icons/WarningIcon';
import Links from '../../../constants/links';
import { palette } from '../../../theme';
import { TAB_AFFILIATE_CREDITS } from '../referrals/ReferralTabs';

const PAYPAL_HOST = /https:\/\/.*paypal.com*/i;

const PayPalCallback = () => {
  const history = useHistory();
  const params = useQueryParams();
  const [paypalError, setPaypalError] = useState('');
  const {
    mutate: addPayPalEmail,
    isLoading,
    isSuccess,
    isError: isSystemError,
    error: systemError,
  } = useMutation((code) => syncPayPalUser(code));

  useEffect(() => {
    const isFromPayPal = PAYPAL_HOST.test(document.referrer);
    const authorizationCode = params.get('code');
    const authError = params.get('error_description');
    if (authError) {
      setPaypalError(authError);
    }
    if (authorizationCode && isFromPayPal && !authError) {
      addPayPalEmail(authorizationCode);
    }
    // clear code from url
    history.replace(PAYPAL_CALLBACK);
  }, [params, addPayPalEmail, history]);

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Box display="flex" flexDirection="column" alignItems="center">
        <SubTitle cardTitle mb={1.75}>
          {isLoading
            ? 'Finalizing Connection...'
            : isSuccess
            ? 'Success!'
            : isSystemError || paypalError
            ? 'Authentication Failed'
            : 'Page Expired'}
        </SubTitle>
        {isLoading && <Grid item justifySelf="center" component={CircularProgress} size={20} />}
        {(paypalError || isSystemError) && (
          <>
            <Box display="flex" alignItems="center" color={palette.error.main}>
              <WarningIcon w="1.3rem" h="1.3rem" display="flex" color={palette.error.main} />
              <Typography variant="body1" ml={1}>
                {paypalError || systemError?.message}
              </Typography>
            </Box>
            {isSystemError && (
              <Instruction>
                Please try again or&nbsp;
                <Link target="_blank" href={Links.HELP_URL}>
                  contact support
                </Link>
                &nbsp;if the issue persists.
              </Instruction>
            )}
          </>
        )}
        {isSuccess && (
          <>
            <Alert severity="success" sx={{ borderRadius: '1rem' }}>
              PayPal connected successfully
            </Alert>
          </>
        )}
        {!isLoading && (
          <Link
            mt={2}
            component={RouterLink}
            to={`${ACCOUNT_REFERRALS}?tab=${TAB_AFFILIATE_CREDITS}`}
            replace
            underline="hover"
            color="brightBlue"
          >
            Back to Referrals
          </Link>
        )}
      </Box>
    </Box>
  );
};
export default PayPalCallback;

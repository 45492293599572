import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const ShowitIcon = ({ sx }) => (
  <SvgIcon sx={sx}>
    <g transform="matrix(0.022499, 0, 0, 0.022499, -276.635132, -8.104136)">
      <g transform="matrix(8.33333,0,0,8.33333,11910.1,0)">
        <g transform="matrix(1,0,0,1,150.477,135.329)">
          <path d="M0,-56.201C-0.129,-50.872 -2.012,-45.455 -5.304,-40.949C-8.604,-36.434 -13.071,-33.181 -17.566,-32.027L-17.683,-31.997L-60.449,-31.997L-60.492,-32.859C-60.724,-37.703 -58.75,-43.272 -55.074,-48.141C-51.576,-52.775 -47.012,-56.125 -42.869,-57.102L-42.761,-57.128L0.022,-57.128L0,-56.201ZM-19.978,-23.278C-20.106,-17.947 -21.989,-12.532 -25.282,-8.025C-28.581,-3.51 -33.05,-0.259 -37.543,0.897L-37.661,0.927L-80.427,0.927L-80.468,0.065C-80.703,-4.78 -78.728,-10.348 -75.052,-15.219C-71.552,-19.849 -66.99,-23.2 -62.847,-24.179L-62.738,-24.203L-19.955,-24.203L-19.978,-23.278ZM-40.232,-92.105C-75.581,-92.105 -104.236,-63.449 -104.236,-28.1C-104.236,7.248 -75.581,35.904 -40.232,35.904C-4.884,35.904 23.772,7.248 23.772,-28.1C23.772,-63.449 -4.884,-92.105 -40.232,-92.105" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

ShowitIcon.propTypes = {
  sx: PropTypes.shape({
    fontSize: PropTypes.string,
    color: PropTypes.string,
  }),
};

ShowitIcon.defaultProps = {
  sx: {},
};

export default ShowitIcon;

import {
  apiErrorCodeMessageOverrides as errorMsgOverrides,
  GENERAL,
  OOPS,
  generalErrorLocation,
} from '../constants/errorMessages';

export const getMessageOverride = (name, code, message) => errorMsgOverrides[name] && errorMsgOverrides[name][code]
  ? errorMsgOverrides[name][code]
  : message;

export const createHandleError = (generalLocation = generalErrorLocation) => (
  error,
  { actions },
  specificErrorOverride
) => {
  actions.setSubmitting(false);
  const {
    response: { data, status },
  } = error || { response: {} };

  if (data.errors) {
    const { errors } = data;
    errors.forEach((subError) => {
      const { path, message, code } = subError;
      const name = (path && path[0]) || generalLocation;

      if (specificErrorOverride) {
        specificErrorOverride(subError);
      }

      actions.setFieldError(name, getMessageOverride(name, code, message));
    });
  }
  if (status === 500) {
    actions.setFieldError(generalLocation, GENERAL);
  }
  if (!error.response) {
    // eslint-disable-next-line no-console
    console.error(error);
    actions.setFieldError(generalLocation, OOPS);
  }
};

const handleError = createHandleError();

export default handleError;

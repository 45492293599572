import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import scrollTo from '../../../utils/scrollTo';
import SubscriptionPlans from './SubscriptionPlans';
import SubscriptionSummaryDiscount from './SubscriptionSummaryDiscount';
import useSubscriptionPlans from './useSubscriptionPlans';
import useChargebeeCheckout from '../../../hooks/useChargebeeCheckout';
import { Title, TogglePlanButton } from './Styled';
import { DISCOUNT_CODE } from '../../../constants/queryData';

const SubscriptionChooser = ({ onCheckoutComplete = undefined }) => {
  const containerRef = useRef();
  const queryClient = useQueryClient();
  const [standardPlans, highTrafficPlans] = useSubscriptionPlans();
  const [openCheckout, wasClosed, checkoutResult] = useChargebeeCheckout();
  const [plans, setPlans] = useState(standardPlans);
  const [chosenPlan, setChosenPlan] = useState(undefined);

  const choosePlan = (plan, period) => {
    setChosenPlan(plan[period]);
  };

  const onOpenCheckout = () => {
    const discount = queryClient.getQueryData(DISCOUNT_CODE) || {};
    openCheckout({ chargebeePlanId: chosenPlan?.planId, discount });
  };

  const showingStandard = plans === standardPlans;
  const togglePlans = () => {
    setPlans((currentPlans) => (currentPlans === standardPlans ? highTrafficPlans : standardPlans));
    scrollTo(containerRef.current, 64);
  };

  useEffect(() => {
    if (wasClosed && checkoutResult.completed && onCheckoutComplete && chosenPlan) {
      onCheckoutComplete({
        cbSubscriptionId: checkoutResult.subscription.externalId,
        price: chosenPlan.periodTotal,
      });
    }
  }, [wasClosed, checkoutResult, onCheckoutComplete, chosenPlan]);

  return (
    <Box
      id="subscription-chooser"
      ref={containerRef}
      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Title variant="h1">Subscribe To Showit</Title>
      {chosenPlan ? (
        <SubscriptionSummaryDiscount
          openCheckout={onOpenCheckout}
          chosenPlan={chosenPlan}
          setChosenPlan={setChosenPlan}
        />
      ) : (
        <>
          <SubscriptionPlans plans={plans} onChoosePlan={choosePlan} />
          <Box py={2}>
            <Grid container direction="column" alignContent="center" spacing={2}>
              <Grid item>
                <Typography variant="h5" align="center">
                  {showingStandard ? 'Need more for your blog?' : ''}
                </Typography>
              </Grid>
              <Grid item>
                <TogglePlanButton variant="outlined" onClick={togglePlans}>
                  {showingStandard ? 'View High-Traffic Blog Pricing' : 'View Standard Pricing'}
                </TogglePlanButton>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

SubscriptionChooser.defaultProps = {
  onCheckoutComplete: undefined,
};

SubscriptionChooser.propTypes = {
  onCheckoutComplete: PropTypes.func,
};

export default SubscriptionChooser;

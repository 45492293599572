import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { HelpOutline } from '@mui/icons-material';
import { ENTRI_CONFIG } from '../../../../constants/queryData';
import { filterDNSRecords, mapToEntriRecords, saveEntriEvent } from '../../../../utils/entriUtils';
import { getEntriAuthToken } from '../../../../api';
import { timestamp } from '../../../../utils/dateTime';
import { refreshToken } from '../../../../hooks/useEntriSupport';
import { dnsRecordShape } from '../../../../domain/PropTypes';
import CustomTooltip from '../../../common/Tooltip/Tooltip';
import { When } from '../../../common';

const tooltipText =
  'Follow step-by-step instructions to connect your domain to your Showit website';

export const ON_ENTRI_STEP_CHANGE = 'onEntriStepChange';
export const ON_ENTRI_CLOSE = 'onEntriClose';
export const ENTRI_STATUS_SUCCESS = 'FINISHED_SUCCESSFULLY';

const ConfigureDNSBtn = ({
  userId,
  domainId,
  dnsRecords,
  domainName,
  entriIsLoading,
  showEntriBtn,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const queryClient = useQueryClient();
  const [showTooltip, setShowTooltip] = useState(false);

  const openEntri = async () => {
    const { token, expires, appId } = await queryClient.fetchQuery(
      [ENTRI_CONFIG],
      getEntriAuthToken,
      {
        staleTime: Infinity,
      }
    );
    const filteredRecords = filterDNSRecords(dnsRecords);
    const isExpired = timestamp() >= expires;
    const config = {
      applicationId: appId,
      prefilledDomain: domainName,
      token: isExpired ? await refreshToken(queryClient) : token,
      dnsRecords: mapToEntriRecords(filteredRecords),
      userId,
    };
    const handleEvent = async (e) => {
      if (e.detail?.step === ENTRI_STATUS_SUCCESS) {
        // save event to db
        await saveEntriEvent({
          userId,
          domainId,
          dnsRecords: filteredRecords,
          queryClient,
        });
        window.removeEventListener(ON_ENTRI_STEP_CHANGE, handleEvent);
      }
    };
    const removeListeners = () => {
      window.removeEventListener(ON_ENTRI_STEP_CHANGE, handleEvent);
      window.removeEventListener(ON_ENTRI_CLOSE, removeListeners);
    };
    window.addEventListener(ON_ENTRI_STEP_CHANGE, handleEvent);
    window.addEventListener(ON_ENTRI_CLOSE, removeListeners);
    await window.entri.showEntri(config);
  };

  return (
    <Box display="flex" alignItems="center" {...props}>
      {entriIsLoading && <CircularProgress size={20} />}
      {showEntriBtn && (
        <CustomTooltip
          title={tooltipText}
          arrow
          placement="top"
          open={showTooltip}
          onClose={() => setShowTooltip(false)}
          onOpen={() => setShowTooltip(true)}
          leaveTouchDelay={0}
        >
          <span>
            <Button variant="contained" onClick={openEntri}>
              Configure DNS
            </Button>
            <When it={isMobile}>
              <IconButton onClick={() => setShowTooltip(true)}>
                <HelpOutline />
              </IconButton>
            </When>
          </span>
        </CustomTooltip>
      )}
    </Box>
  );
};
ConfigureDNSBtn.propTypes = {
  userId: PropTypes.number.isRequired,
  domainId: PropTypes.number.isRequired,
  dnsRecords: PropTypes.arrayOf(dnsRecordShape).isRequired,
  domainName: PropTypes.string.isRequired,
  entriIsLoading: PropTypes.bool.isRequired,
  showEntriBtn: PropTypes.bool,
};
ConfigureDNSBtn.defaultProps = {
  showEntriBtn: false,
};
export default ConfigureDNSBtn;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogContent, DialogContentText, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { fonts, palette } from '../../../../theme';

import { ChromeDesktopIcon } from '../../../common/Icons';

const ChromeRequiredModal = ({ open, handleClose }) => (
  <Dialog open={open} onClose={handleClose}>
    <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
      <IconButton onClick={handleClose} sx={{ margin: '5px 5px 0 0' }}>
        <Close sx={{ fontSize: '1.2rem', color: palette.primary.main }} />
      </IconButton>
    </Box>
    <DialogContent style={{ maxWidth: 280, padding: '0 34px 30px' }}>
      <Box textAlign="center">
        <ChromeDesktopIcon sx={{ height: '65%', width: '65%' }} />
        <DialogContentText
          sx={{
            fontWeight: 500,
            fontSize: '1.5em',
            fontFamily: fonts.atypText,
            color: palette.primary.main,
          }}
        >
          Chrome Required
        </DialogContentText>
        <DialogContentText
          textAlign="left"
          sx={{ fontSize: '0.9rem', margin: '0.5em auto 1em', color: palette.primary.main }}
        >
          To edit your website designs, sign into Showit using the Chrome browser on a desktop or
          laptop computer.
        </DialogContentText>
        <Button
          title="Close Notice"
          type="submit"
          variant="contained"
          onClick={handleClose}
          fullWidth
        >
          Close
        </Button>
      </Box>
    </DialogContent>
  </Dialog>
);

ChromeRequiredModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ChromeRequiredModal;

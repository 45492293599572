/**
 * List generated by passing diagraph to getCountryCode and setting the cc.
 * 

import { PhoneNumberUtil } from 'google-libphonenumber';

const getCountryCode = (diagraph) =>
  PhoneNumberUtil.getInstance().getCountryCodeForRegion(diagraph);

*/

export const countries = [
  { diagraph: 'US', cc: 1, name: 'United States' },
  { diagraph: 'CA', cc: 1, name: 'Canada' },
  { diagraph: 'AF', cc: 93, name: 'Afghanistan' },
  { diagraph: 'AX', cc: 358, name: 'Åland Islands' },
  { diagraph: 'AL', cc: 355, name: 'Albania' },
  { diagraph: 'DZ', cc: 213, name: 'Algeria' },
  { diagraph: 'AS', cc: 1, name: 'American Samoa' },
  { diagraph: 'AD', cc: 376, name: 'Andorra' },
  { diagraph: 'AO', cc: 244, name: 'Angola' },
  { diagraph: 'AI', cc: 1, name: 'Anguilla' },
  { diagraph: 'AQ', cc: 0, name: 'Antarctica' },
  { diagraph: 'AG', cc: 1, name: 'Antigua and Barbuda' },
  { diagraph: 'AR', cc: 54, name: 'Argentina' },
  { diagraph: 'AM', cc: 374, name: 'Armenia' },
  { diagraph: 'AW', cc: 297, name: 'Aruba' },
  { diagraph: 'AU', cc: 61, name: 'Australia' },
  { diagraph: 'AT', cc: 43, name: 'Austria' },
  { diagraph: 'AZ', cc: 994, name: 'Azerbaijan' },
  { diagraph: 'BS', cc: 1, name: 'Bahamas' },
  { diagraph: 'BH', cc: 973, name: 'Bahrain' },
  { diagraph: 'BD', cc: 880, name: 'Bangladesh' },
  { diagraph: 'BB', cc: 1, name: 'Barbados' },
  { diagraph: 'BY', cc: 375, name: 'Belarus' },
  { diagraph: 'BE', cc: 32, name: 'Belgium' },
  { diagraph: 'BZ', cc: 501, name: 'Belize' },
  { diagraph: 'BJ', cc: 229, name: 'Benin' },
  { diagraph: 'BM', cc: 1, name: 'Bermuda' },
  { diagraph: 'BT', cc: 975, name: 'Bhutan' },
  { diagraph: 'BO', cc: 591, name: 'Bolivia, Plurinational State of' },
  { diagraph: 'BQ', cc: 599, name: 'Bonaire, Sint Eustatius and Saba' },
  { diagraph: 'BA', cc: 387, name: 'Bosnia and Herzegovina' },
  { diagraph: 'BW', cc: 267, name: 'Botswana' },
  { diagraph: 'BV', cc: 0, name: 'Bouvet Island' },
  { diagraph: 'BR', cc: 55, name: 'Brazil' },
  { diagraph: 'IO', cc: 246, name: 'British Indian Ocean Territory' },
  { diagraph: 'BN', cc: 673, name: 'Brunei Darussalam' },
  { diagraph: 'BG', cc: 359, name: 'Bulgaria' },
  { diagraph: 'BF', cc: 226, name: 'Burkina Faso' },
  { diagraph: 'BI', cc: 257, name: 'Burundi' },
  { diagraph: 'KH', cc: 855, name: 'Cambodia' },
  { diagraph: 'CM', cc: 237, name: 'Cameroon' },
  { diagraph: 'CV', cc: 238, name: 'Cape Verde' },
  { diagraph: 'KY', cc: 1, name: 'Cayman Islands' },
  { diagraph: 'CF', cc: 236, name: 'Central African Republic' },
  { diagraph: 'TD', cc: 235, name: 'Chad' },
  { diagraph: 'CL', cc: 56, name: 'Chile' },
  { diagraph: 'CN', cc: 86, name: 'China' },
  { diagraph: 'CX', cc: 61, name: 'Christmas Island' },
  { diagraph: 'CC', cc: 61, name: 'Cocos (Keeling) Islands' },
  { diagraph: 'CO', cc: 57, name: 'Colombia' },
  { diagraph: 'KM', cc: 269, name: 'Comoros' },
  { diagraph: 'CG', cc: 242, name: 'Congo' },
  { diagraph: 'CD', cc: 243, name: 'Congo, the Democratic Republic of the' },
  { diagraph: 'CK', cc: 682, name: 'Cook Islands' },
  { diagraph: 'CR', cc: 506, name: 'Costa Rica' },
  { diagraph: 'CI', cc: 225, name: "Côte d'Ivoire" },
  { diagraph: 'HR', cc: 385, name: 'Croatia' },
  { diagraph: 'CU', cc: 53, name: 'Cuba' },
  { diagraph: 'CW', cc: 599, name: 'Curaçao' },
  { diagraph: 'CY', cc: 357, name: 'Cyprus' },
  { diagraph: 'CZ', cc: 420, name: 'Czech Republic' },
  { diagraph: 'DK', cc: 45, name: 'Denmark' },
  { diagraph: 'DJ', cc: 253, name: 'Djibouti' },
  { diagraph: 'DM', cc: 1, name: 'Dominica' },
  { diagraph: 'DO', cc: 1, name: 'Dominican Republic' },
  { diagraph: 'EC', cc: 593, name: 'Ecuador' },
  { diagraph: 'EG', cc: 20, name: 'Egypt' },
  { diagraph: 'SV', cc: 503, name: 'El Salvador' },
  { diagraph: 'GQ', cc: 240, name: 'Equatorial Guinea' },
  { diagraph: 'ER', cc: 291, name: 'Eritrea' },
  { diagraph: 'EE', cc: 372, name: 'Estonia' },
  { diagraph: 'ET', cc: 251, name: 'Ethiopia' },
  { diagraph: 'FK', cc: 500, name: 'Falkland Islands (Malvinas)' },
  { diagraph: 'FO', cc: 298, name: 'Faroe Islands' },
  { diagraph: 'FJ', cc: 679, name: 'Fiji' },
  { diagraph: 'FI', cc: 358, name: 'Finland' },
  { diagraph: 'FR', cc: 33, name: 'France' },
  { diagraph: 'GF', cc: 594, name: 'French Guiana' },
  { diagraph: 'PF', cc: 689, name: 'French Polynesia' },
  { diagraph: 'TF', cc: 0, name: 'French Southern Territories' },
  { diagraph: 'GA', cc: 241, name: 'Gabon' },
  { diagraph: 'GM', cc: 220, name: 'Gambia' },
  { diagraph: 'GE', cc: 995, name: 'Georgia' },
  { diagraph: 'DE', cc: 49, name: 'Germany' },
  { diagraph: 'GH', cc: 233, name: 'Ghana' },
  { diagraph: 'GI', cc: 350, name: 'Gibraltar' },
  { diagraph: 'GR', cc: 30, name: 'Greece' },
  { diagraph: 'GL', cc: 299, name: 'Greenland' },
  { diagraph: 'GD', cc: 1, name: 'Grenada' },
  { diagraph: 'GP', cc: 590, name: 'Guadeloupe' },
  { diagraph: 'GU', cc: 1, name: 'Guam' },
  { diagraph: 'GT', cc: 502, name: 'Guatemala' },
  { diagraph: 'GG', cc: 44, name: 'Guernsey' },
  { diagraph: 'GN', cc: 224, name: 'Guinea' },
  { diagraph: 'GW', cc: 245, name: 'Guinea-Bissau' },
  { diagraph: 'GY', cc: 592, name: 'Guyana' },
  { diagraph: 'HT', cc: 509, name: 'Haiti' },
  { diagraph: 'HM', cc: 0, name: 'Heard Island and McDonald Islands' },
  { diagraph: 'VA', cc: 39, name: 'Holy See (Vatican City State)' },
  { diagraph: 'HN', cc: 504, name: 'Honduras' },
  { diagraph: 'HK', cc: 852, name: 'Hong Kong' },
  { diagraph: 'HU', cc: 36, name: 'Hungary' },
  { diagraph: 'IS', cc: 354, name: 'Iceland' },
  { diagraph: 'IN', cc: 91, name: 'India' },
  { diagraph: 'ID', cc: 62, name: 'Indonesia' },
  { diagraph: 'IR', cc: 98, name: 'Iran, Islamic Republic of' },
  { diagraph: 'IQ', cc: 964, name: 'Iraq' },
  { diagraph: 'IE', cc: 353, name: 'Ireland' },
  { diagraph: 'IM', cc: 44, name: 'Isle of Man' },
  { diagraph: 'IL', cc: 972, name: 'Israel' },
  { diagraph: 'IT', cc: 39, name: 'Italy' },
  { diagraph: 'JM', cc: 1, name: 'Jamaica' },
  { diagraph: 'JP', cc: 81, name: 'Japan' },
  { diagraph: 'JE', cc: 44, name: 'Jersey' },
  { diagraph: 'JO', cc: 962, name: 'Jordan' },
  { diagraph: 'KZ', cc: 7, name: 'Kazakhstan' },
  { diagraph: 'KE', cc: 254, name: 'Kenya' },
  { diagraph: 'KI', cc: 686, name: 'Kiribati' },
  { diagraph: 'KP', cc: 850, name: "Korea, Democratic People's Republic of" },
  { diagraph: 'KR', cc: 82, name: 'Korea, Republic of' },
  { diagraph: 'KW', cc: 965, name: 'Kuwait' },
  { diagraph: 'KG', cc: 996, name: 'Kyrgyzstan' },
  { diagraph: 'LA', cc: 856, name: "Lao People's Democratic Republic" },
  { diagraph: 'LV', cc: 371, name: 'Latvia' },
  { diagraph: 'LB', cc: 961, name: 'Lebanon' },
  { diagraph: 'LS', cc: 266, name: 'Lesotho' },
  { diagraph: 'LR', cc: 231, name: 'Liberia' },
  { diagraph: 'LY', cc: 218, name: 'Libya' },
  { diagraph: 'LI', cc: 423, name: 'Liechtenstein' },
  { diagraph: 'LT', cc: 370, name: 'Lithuania' },
  { diagraph: 'LU', cc: 352, name: 'Luxembourg' },
  { diagraph: 'MO', cc: 853, name: 'Macao' },
  { diagraph: 'MK', cc: 389, name: 'Macedonia, the Former Yugoslav Republic of' },
  { diagraph: 'MG', cc: 261, name: 'Madagascar' },
  { diagraph: 'MW', cc: 265, name: 'Malawi' },
  { diagraph: 'MY', cc: 60, name: 'Malaysia' },
  { diagraph: 'MV', cc: 960, name: 'Maldives' },
  { diagraph: 'ML', cc: 223, name: 'Mali' },
  { diagraph: 'MT', cc: 356, name: 'Malta' },
  { diagraph: 'MH', cc: 692, name: 'Marshall Islands' },
  { diagraph: 'MQ', cc: 596, name: 'Martinique' },
  { diagraph: 'MR', cc: 222, name: 'Mauritania' },
  { diagraph: 'MU', cc: 230, name: 'Mauritius' },
  { diagraph: 'YT', cc: 262, name: 'Mayotte' },
  { diagraph: 'MX', cc: 52, name: 'Mexico' },
  { diagraph: 'FM', cc: 691, name: 'Micronesia, Federated States of' },
  { diagraph: 'MD', cc: 373, name: 'Moldova, Republic of' },
  { diagraph: 'MC', cc: 377, name: 'Monaco' },
  { diagraph: 'MN', cc: 976, name: 'Mongolia' },
  { diagraph: 'ME', cc: 382, name: 'Montenegro' },
  { diagraph: 'MS', cc: 1, name: 'Montserrat' },
  { diagraph: 'MA', cc: 212, name: 'Morocco' },
  { diagraph: 'MZ', cc: 258, name: 'Mozambique' },
  { diagraph: 'MM', cc: 95, name: 'Myanmar' },
  { diagraph: 'NA', cc: 264, name: 'Namibia' },
  { diagraph: 'NR', cc: 674, name: 'Nauru' },
  { diagraph: 'NP', cc: 977, name: 'Nepal' },
  { diagraph: 'NL', cc: 31, name: 'Netherlands' },
  { diagraph: 'NC', cc: 687, name: 'New Caledonia' },
  { diagraph: 'NZ', cc: 64, name: 'New Zealand' },
  { diagraph: 'NI', cc: 505, name: 'Nicaragua' },
  { diagraph: 'NE', cc: 227, name: 'Niger' },
  { diagraph: 'NG', cc: 234, name: 'Nigeria' },
  { diagraph: 'NU', cc: 683, name: 'Niue' },
  { diagraph: 'NF', cc: 672, name: 'Norfolk Island' },
  { diagraph: 'MP', cc: 1, name: 'Northern Mariana Islands' },
  { diagraph: 'NO', cc: 47, name: 'Norway' },
  { diagraph: 'OM', cc: 968, name: 'Oman' },
  { diagraph: 'PK', cc: 92, name: 'Pakistan' },
  { diagraph: 'PW', cc: 680, name: 'Palau' },
  { diagraph: 'PS', cc: 970, name: 'Palestine, State of' },
  { diagraph: 'PA', cc: 507, name: 'Panama' },
  { diagraph: 'PG', cc: 675, name: 'Papua New Guinea' },
  { diagraph: 'PY', cc: 595, name: 'Paraguay' },
  { diagraph: 'PE', cc: 51, name: 'Peru' },
  { diagraph: 'PH', cc: 63, name: 'Philippines' },
  { diagraph: 'PN', cc: 0, name: 'Pitcairn' },
  { diagraph: 'PL', cc: 48, name: 'Poland' },
  { diagraph: 'PT', cc: 351, name: 'Portugal' },
  { diagraph: 'PR', cc: 1, name: 'Puerto Rico' },
  { diagraph: 'QA', cc: 974, name: 'Qatar' },
  { diagraph: 'RE', cc: 262, name: 'Réunion' },
  { diagraph: 'RO', cc: 40, name: 'Romania' },
  { diagraph: 'RU', cc: 7, name: 'Russian Federation' },
  { diagraph: 'RW', cc: 250, name: 'Rwanda' },
  { diagraph: 'BL', cc: 590, name: 'Saint Barthélemy' },
  { diagraph: 'SH', cc: 290, name: 'Saint Helena, Ascension and Tristan da Cunha' },
  { diagraph: 'KN', cc: 1, name: 'Saint Kitts and Nevis' },
  { diagraph: 'LC', cc: 1, name: 'Saint Lucia' },
  { diagraph: 'MF', cc: 590, name: 'Saint Martin (French)' },
  { diagraph: 'PM', cc: 508, name: 'Saint Pierre and Miquelon' },
  { diagraph: 'VC', cc: 1, name: 'Saint Vincent and the Grenadines' },
  { diagraph: 'WS', cc: 685, name: 'Samoa' },
  { diagraph: 'SM', cc: 378, name: 'San Marino' },
  { diagraph: 'ST', cc: 239, name: 'Sao Tome and Principe' },
  { diagraph: 'SA', cc: 966, name: 'Saudi Arabia' },
  { diagraph: 'SN', cc: 221, name: 'Senegal' },
  { diagraph: 'RS', cc: 381, name: 'Serbia' },
  { diagraph: 'SC', cc: 248, name: 'Seychelles' },
  { diagraph: 'SL', cc: 232, name: 'Sierra Leone' },
  { diagraph: 'SG', cc: 65, name: 'Singapore' },
  { diagraph: 'SX', cc: 1, name: 'Sint Maarten (Dutch)' },
  { diagraph: 'SK', cc: 421, name: 'Slovakia' },
  { diagraph: 'SI', cc: 386, name: 'Slovenia' },
  { diagraph: 'SB', cc: 677, name: 'Solomon Islands' },
  { diagraph: 'SO', cc: 252, name: 'Somalia' },
  { diagraph: 'ZA', cc: 27, name: 'South Africa' },
  { diagraph: 'GS', cc: 0, name: 'South Georgia and the South Sandwich Islands' },
  { diagraph: 'SS', cc: 211, name: 'South Sudan' },
  { diagraph: 'ES', cc: 34, name: 'Spain' },
  { diagraph: 'LK', cc: 94, name: 'Sri Lanka' },
  { diagraph: 'SD', cc: 249, name: 'Sudan' },
  { diagraph: 'SR', cc: 597, name: 'Suriname' },
  { diagraph: 'SJ', cc: 47, name: 'Svalbard and Jan Mayen' },
  { diagraph: 'SZ', cc: 268, name: 'Swaziland' },
  { diagraph: 'SE', cc: 46, name: 'Sweden' },
  { diagraph: 'CH', cc: 41, name: 'Switzerland' },
  { diagraph: 'SY', cc: 963, name: 'Syrian Arab Republic' },
  { diagraph: 'TW', cc: 886, name: 'Taiwan' },
  { diagraph: 'TJ', cc: 992, name: 'Tajikistan' },
  { diagraph: 'TZ', cc: 255, name: 'Tanzania, United Republic of' },
  { diagraph: 'TH', cc: 66, name: 'Thailand' },
  { diagraph: 'TL', cc: 670, name: 'Timor-Leste' },
  { diagraph: 'TG', cc: 228, name: 'Togo' },
  { diagraph: 'TK', cc: 690, name: 'Tokelau' },
  { diagraph: 'TO', cc: 676, name: 'Tonga' },
  { diagraph: 'TT', cc: 1, name: 'Trinidad and Tobago' },
  { diagraph: 'TN', cc: 216, name: 'Tunisia' },
  { diagraph: 'TR', cc: 90, name: 'Turkey' },
  { diagraph: 'TM', cc: 993, name: 'Turkmenistan' },
  { diagraph: 'TC', cc: 1, name: 'Turks and Caicos Islands' },
  { diagraph: 'TV', cc: 688, name: 'Tuvalu' },
  { diagraph: 'UG', cc: 256, name: 'Uganda' },
  { diagraph: 'UA', cc: 380, name: 'Ukraine' },
  { diagraph: 'AE', cc: 971, name: 'United Arab Emirates' },
  { diagraph: 'GB', cc: 44, name: 'United Kingdom' },
  { diagraph: 'UM', cc: 0, name: 'United States Minor Outlying Islands' },
  { diagraph: 'UY', cc: 598, name: 'Uruguay' },
  { diagraph: 'UZ', cc: 998, name: 'Uzbekistan' },
  { diagraph: 'VU', cc: 678, name: 'Vanuatu' },
  { diagraph: 'VE', cc: 58, name: 'Venezuela, Bolivarian Republic of' },
  { diagraph: 'VN', cc: 84, name: 'Viet Nam' },
  { diagraph: 'VG', cc: 1, name: 'Virgin Islands, British' },
  { diagraph: 'VI', cc: 1, name: 'Virgin Islands, U.S.' },
  { diagraph: 'WF', cc: 681, name: 'Wallis and Futuna' },
  { diagraph: 'EH', cc: 212, name: 'Western Sahara' },
  { diagraph: 'YE', cc: 967, name: 'Yemen' },
  { diagraph: 'ZM', cc: 260, name: 'Zambia' },
  { diagraph: 'ZW', cc: 263, name: 'Zimbabwe' },
].filter(({ cc }) => cc !== 0); // 0 is not a valid country code and should not be displayed in the country drop-down.

export const findCC = (diagraph) =>
  (countries.find((country) => country.diagraph === diagraph) || {}).cc;

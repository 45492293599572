import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Card = styled(Paper)({
  '&.MuiPaper-elevation1': { marginBottom: '15px' },
  padding: '15px 20px',
  boxShadow: 'rgba(0, 0, 0, 0.2) 0 2px 5px',
  borderRadius: 24,
});

export default Card;

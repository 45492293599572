import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import config from '../../../config';

const { TOS_URL, PRIVACY_URL } = config;

const Footer = () => {
  const theme = useTheme();
  const styles = {
    footer: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      height: '150px',
    },
  };
  return (
    <Box sx={styles.footer}>
      <span style={{ color: theme.palette.primary.main }}>
        © 2008-{new Date().getFullYear()} Showit, Inc.
      </span>
      <span>
        &nbsp;&#8729;&nbsp;
        <Link href={PRIVACY_URL} underline="hover">
          Privacy Policy
        </Link>
        &nbsp;&#8729;&nbsp;
        <Link href={TOS_URL} underline="hover">
          Terms of Use
        </Link>
      </span>
    </Box>
  );
};

export default Footer;

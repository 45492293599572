import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { Typography, Box } from '@mui/material';
import { ActionLinkText } from '../../common';
import { palette } from '../../../theme';
import { COUPON, REFERRAL } from '../../../constants/discountCodeTypes';
import { useRemoveDiscount } from '../../../hooks/useDiscountCode';
import { DISCOUNT_CODE } from '../../../constants/queryData';
import { discountShape } from '../../../domain/PropTypes';

const CodeTag = ({ border, code }) => (
  <span
    style={{
      backgroundColor: palette.background.white,
      border: `1px solid ${border}`,
      borderRadius: '2px',
      padding: '0px 5px',
      fontWeight: 500,
    }}
  >
    {code}
  </span>
);
CodeTag.propTypes = {
  border: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

const DiscountInfo = ({ id, type, code, valid }) => {
  const queryClient = useQueryClient();
  const codeType = type === COUPON ? 'Coupon' : 'Referral';
  const { mutate: deleteReferral, isLoading: isRemoving } = useRemoveDiscount();

  const removeDiscount = () =>
    type === REFERRAL ? deleteReferral(id) : queryClient.setQueryData([DISCOUNT_CODE], {});

  return (
    <>
      <Box sx={{ padding: '0 1em' }}>
        <Typography
          variant="body2"
          alignment="center"
          sx={{ color: valid ? palette.success.main : palette.error.main }}
        >
          {valid ? (
            <>
              {codeType} code <CodeTag border={palette.success.main} code={code} /> applied.
              {type === REFERRAL && " You're getting a free month when you subscribe!"}
            </>
          ) : (
            <>
              {codeType} code <CodeTag border={palette.error.main} code={code} /> is not valid.
            </>
          )}
        </Typography>
        <Box color={palette.primary.main} sx={{ margin: '1rem 0', textAlign: 'center' }}>
          <ActionLinkText
            data-testid="remove-discount"
            display="inline"
            color="primary"
            disabled={isRemoving}
            onClick={removeDiscount}
          >
            Remov{isRemoving ? 'ing' : 'e'} code
          </ActionLinkText>
        </Box>
      </Box>
    </>
  );
};

DiscountInfo.propTypes = discountShape;

export default DiscountInfo;

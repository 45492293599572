import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Button } from '../../common';
import { CheckoutButton, Discount } from './Styled';
import useDiscountCode from '../../../hooks/useDiscountCode';
import DiscountManager from './DiscountManager';

const SubscriptionSummaryDiscount = ({ chosenPlan, openCheckout, setChosenPlan }) => {
  const { discount, isLoading, isSuccess } = useDiscountCode();

  return (
    <Discount>
      <Typography className="selection header">You have chosen</Typography>
      <Typography className="selection">
        Showit {chosenPlan.period === 'y' ? 'Annual' : 'Monthly'} Subscription
      </Typography>
      <Typography className="selection">
        ${chosenPlan.periodTotal}/{chosenPlan.period === 'y' ? 'year' : 'month'}
      </Typography>
      <Box className="inputs">
        <Button
          variant="text"
          size="small"
          className="chooseDifferentButton"
          onClick={() => setChosenPlan(undefined)}
        >
          Choose a different plan
        </Button>
        {isLoading && <CircularProgress size={18} sx={{ alignSelf: 'center', mt: 1, mb: 2.5 }} />}
        {isSuccess && <DiscountManager discount={discount} />}
        <CheckoutButton
          variant="contained"
          data-testid="checkout-button"
          type="submit"
          fullWidth
          disabled={isLoading}
          onClick={openCheckout}
        >
          Proceed to Checkout
        </CheckoutButton>
      </Box>
    </Discount>
  );
};

export default SubscriptionSummaryDiscount;

SubscriptionSummaryDiscount.defaultProps = {
  openCheckout: undefined,
  chosenPlan: {},
  setChosenPlan: undefined,
};

SubscriptionSummaryDiscount.propTypes = {
  openCheckout: PropTypes.func,
  chosenPlan: PropTypes.shape({
    planId: PropTypes.string,
    period: PropTypes.string,
    price: PropTypes.number,
    periodTotal: PropTypes.number,
  }),
  setChosenPlan: PropTypes.func,
};

export const PROTECTED_PREFIX = '/my';

export const ROOT = '/';
export const PASSWORD_FORGOT = '/password/forgot';
export const PASSWORD_RESET = '/password/reset';
export const SSO = '/sso/:token';
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const CREATE = '/create';
export const CREATE_SECURE = '/create/secure';
export const SUCCESS = '/success';
export const FAIL = '/fail';
export const EXPIRED = '/expired';
export const EMAIL_CONFIRMATION = '/email/confirmation';
export const PAYPAL_CALLBACK = '/auth/paypal/callback';
export const THIRD_PARTY_DELETE_STATUS = '/third-party/delete/status';
export const ACCOUNT = '/home';
export const ACCOUNT_MESSAGES = `${PROTECTED_PREFIX}/messages`;
export const ACCOUNT_PROFILE = `${PROTECTED_PREFIX}/profile`;
export const ACCOUNT_NOTIFICATIONS = `${PROTECTED_PREFIX}/notifications`;
export const ACCOUNT_SECURITY = `${PROTECTED_PREFIX}/security`;
export const ACCOUNT_THIRD_PARTY = `${PROTECTED_PREFIX}/third-party`;
export const ACCOUNT_SUBSCRIBE = '/subscribe';
export const ACCOUNT_SUBSCRIBE_SUCCESS = '/subscribe/success';
export const ACCOUNT_SUBSCRIPTION = `${PROTECTED_PREFIX}/subscription`;
export const ACCOUNT_DOMAINS = `${PROTECTED_PREFIX}/domains`;
export const ACCOUNT_REFERRALS = `${PROTECTED_PREFIX}/referrals`;
export const ACCOUNT_REFERRAL = `/r/:referralCode`;

export const PROTECTED_ROUTES = {
  [ACCOUNT]: true,
  [ACCOUNT_MESSAGES]: true,
  [ACCOUNT_PROFILE]: true,
  [ACCOUNT_NOTIFICATIONS]: true,
  [ACCOUNT_SUBSCRIPTION]: true,
  [ACCOUNT_SECURITY]: true,
  [ACCOUNT_SUBSCRIBE]: true,
  [ACCOUNT_SUBSCRIBE_SUCCESS]: true,
  [ACCOUNT_THIRD_PARTY]: true,
  [ACCOUNT_DOMAINS]: true,
  [ACCOUNT_REFERRALS]: true,
};

// Used at top level router to secure paths
export const AUTHORIZED_ROUTES = [
  { path: ACCOUNT, exact: true },
  { path: ACCOUNT_SUBSCRIBE, exact: false },
  { path: PAYPAL_CALLBACK },
  { path: PROTECTED_PREFIX, exact: false },
];

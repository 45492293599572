import { useQuery, useMutation, useQueryClient } from 'react-query';

import { getReferralCode, updateReferralCode } from '../../../../../api';
import { REFERRAL_CODE } from '../../../../../constants/queryData';

const defaults = {
  enabled: true,
  retry: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: true,
  refetchOnMount: true,
};

export default function useReferralCode(settings = defaults) {
  const { data, ...rest } = useQuery(REFERRAL_CODE, getReferralCode, { ...defaults, ...settings });

  const referralCode = data || {};
  return { referralCode, ...rest };
}

export const useUpdateReferralCode = () => {
  const queryClient = useQueryClient();
  return useMutation((update) => updateReferralCode(update), {
    onSuccess: (data) => {
      queryClient.setQueryData(REFERRAL_CODE, data);
    },
  });
};

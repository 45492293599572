import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Container, useMediaQuery } from '@mui/material';
import { PageTitle, When } from '../../common';
import SubscriptionCard from './SubscriptionCard';
import useSubscription from './SubscriptionCard/useSubscription';
import useUser from '../../../hooks/useUser';

const SubscriptionView = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useUser();
  const { subscription = {}, isSuccess } = useSubscription();

  return (
    <Container maxWidth="sm" disableGutters={isMobile}>
      <PageTitle align="left">Subscription</PageTitle>
      <When it={isSuccess}>
        <SubscriptionCard user={user} subscription={subscription} />
      </When>
    </Container>
  );
};

export default SubscriptionView;

import { useMutation, useQueryClient } from 'react-query';

import { acceptReferralTos } from '../../../../../api';
import { REFERRAL_CODE } from '../../../../../constants/queryData';

const useAcceptReferralTos = () => {
  const queryClient = useQueryClient();
  return useMutation(() => acceptReferralTos({ acceptedReferralTos: true }), {
    onSuccess: (data) => {
      queryClient.setQueryData(REFERRAL_CODE, data);
    },
  });
};
export default useAcceptReferralTos;

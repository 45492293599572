import React from 'react';
import { Container } from '@mui/material';

import { PageTitle } from '../../common';
import Instagram from './Instagram';

const ThirdParty = () => (
  <Container maxWidth="sm" data-testid="third-party-page" style={{ padding: 0 }}>
    <PageTitle align="left">Third Party Integrations</PageTitle>
    <Instagram />
  </Container>
);

export default ThirdParty;

import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { palette } from '../../../theme';

export const Statistic = styled(Box)`
  font-size: 0.8rem;
  text-align: center;
  font-weight: bold;
  flex: 1;
  padding: 0.75rem;
  span {
    font-size: 1.2rem;
  }
`;

export const StyledTable = styled(Table)`
  th {
    font-weight: bold;
  }
  th,
  td {
    padding: 0.5rem;
  }
`;

export const TabsRoot = styled(Tabs)({
  minHeight: 'auto',
  borderBottom: '1px solid black',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

export const StyledTab = styled(Tab)({
  color: 'black',
  minHeight: 'auto',
  textTransform: 'none',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  padding: '0.4rem 0.7rem',
  '&.Mui-selected': {
    backgroundColor: 'black',
    color: palette.neutral.main,
  },
});

export const WarningAlert = styled(Alert)({
  padding: '.15rem .5rem',
  marginBottom: '.5rem',
  borderRadius: '.5rem',
  border: 'none',
  background: palette.warning.lighter,
});

export const PayPalButton = styled(LoadingButton)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  backgroundColor: theme.palette.paypalGray,
  color: 'black',
  fontSize: 14,
  textTransform: 'uppercase',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#dcdcdc',
  },
}));

import React from 'react';
import Box from '@mui/material/Box';
import DiscountInfo from './DiscountInfo';
import DiscountInput from './DiscountInput';
import { discountShape } from '../../../domain/PropTypes';

const DiscountManager = ({ discount }) => (
  <Box mt={2} textAlign="left" display="flex" alignItems="flex-start" justifyContent="center">
    {discount.code ? <DiscountInfo {...discount} /> : <DiscountInput />}
  </Box>
);

DiscountManager.propTypes = {
  discount: discountShape,
};
DiscountManager.defaultProps = {
  discount: {},
};
export default DiscountManager;

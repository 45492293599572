/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import { useQuery } from 'react-query';
import styled from '@emotion/styled';
import { MESSAGE } from '../../../constants/queryData';
import { getMessageContent } from '../../../api';
import { formatDate } from '../../../utils/dateTime';

const Content = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  whiteSpace: 'pre-wrap',
});

const MessageContent = ({ id }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    [MESSAGE, id],
    () => getMessageContent(id),
    {
      staleTime: Infinity,
      retry: 2,
    }
  );

  return isLoading ? (
    <CircularProgress size={20} thickness={3.5} color="primary" />
  ) : isError ? (
    <span>A problem occurred while loading this message</span>
  ) : isSuccess && data ? (
    <Content>
      {`Submitted: ${formatDate(data.timestamp)}\n`}
      {`From IP Address: ${data.requestIp}\n\n`}
      {data?.fields?.find(({ name }) => name === 'body')?.value}
    </Content>
  ) : null;
};

MessageContent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default MessageContent;

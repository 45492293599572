import { useQuery } from 'react-query';
import { getReferralStats } from '../../../../../api';
import { REFERRAL_STATS } from '../../../../../constants/queryData';

export default function useReferralStats(opts = {}) {
  const { data, ...rest } = useQuery(REFERRAL_STATS, getReferralStats, {
    refetchOnWindowFocus: false,
    ...opts,
  });
  const { pendingReferrals = [], subscriptionCredits = [], affiliateCredits = [] } = data || {};
  return { pendingReferrals, subscriptionCredits, affiliateCredits, ...rest };
}

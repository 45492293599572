import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { fonts, palette } from '../../../../theme';
import DesignAppSvg from '../../../../assets/design_app_minimal.svg';

const ArrowSvgStyle = styled.svg`
  max-height: 20px;
  width: 100%;

  & .arrowPiece {
    stroke-width: 3px;
    stroke: ${palette.primary.main};
  }
`;

// eslint-disable-next-line
const ArrowSvg = ({ strokeWidth = 3 }) => {
  const [ref, setRef] = useState();
  const [clampedWidth, setClampedWidth] = useState(20);
  const [height, setHeight] = useState(20);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref) {
      setClampedWidth(Math.max(20, ref?.clientWidth));
      setHeight(ref?.clientHeight);
    }
  });

  const offset = strokeWidth / 2;

  return (
    <ArrowSvgStyle
      viewBox={`0 0 ${clampedWidth} 20`}
      className="expandingArrow"
      ref={setRef}
      strokeWidth={strokeWidth}
    >
      <line
        className="arrowPiece arrowBase"
        x1="0"
        y1={height / 2}
        x2={clampedWidth - offset}
        y2={height / 2}
      />
      <line
        className="arrowPiece arrowTop"
        x1={clampedWidth - 12}
        y1={offset}
        x2={clampedWidth - offset}
        y2={height / 2 + 1}
      />
      <line
        className="arrowPiece arrowBottom"
        x1={clampedWidth - offset}
        y1={height / 2 - 1}
        x2={clampedWidth - 12}
        y2={height - offset}
      />
    </ArrowSvgStyle>
  );
};

const StyledDesignAppButton = styled(Button)`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border: 1px solid;
  border-radius: 24px;

  & .appImage {
    width: 100%;
  }

  & .textContainer {
    padding: 8px 3em;
    border-top: 1px solid ${palette.primary.main};
    width: 100%;
    margin-top: -2px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & .label {
      white-space: nowrap;
      margin-right: 1em;
      font-size: 0.875rem;
    }

    & .expandingArrow {
    }
  }
`;

// eslint-disable-next-line
const DesignAppButton = ({ openDesignApp }) => (
  <StyledDesignAppButton title="Go to the Showit Design App" type="submit" onClick={openDesignApp}>
    <img className="appImage" src={DesignAppSvg} alt="Design App Simplified" />
    <div className="textContainer">
      <Typography className="label" fontFamily={fonts.maison}>
        Open Design App
      </Typography>
      <ArrowSvg />
    </div>
  </StyledDesignAppButton>
);

export default DesignAppButton;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Comp = styled('p')((props) => ({
  // color: theme.palette.mediumGrey,
  ...props,
}));

const Instruction = ({ children, ...props }) => <Comp {...props}>{children}</Comp>;

export default Instruction;

Instruction.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ReferralCodeManager from './ReferralCodeManager';
import useReferralCode from './hooks/useReferralCode';
import ReferralHelpText from './ReferralHelpText';
import ReferralTermsOfService from './ReferralTermsOfService';

const ReferralCard = () => {
  const { referralCode, isLoading, isSuccess, isError } = useReferralCode();
  const acceptedReferralTos = !!referralCode?.acceptedReferralTos;
  return (
    <Box px="8px" my={2}>
      {isLoading && <CircularProgress size={18} />}
      {isError && (
        <Typography color="error" my={2}>
          An error occurred
        </Typography>
      )}
      {isSuccess && (
        <>
          <ReferralHelpText acceptedReferralTos={acceptedReferralTos} />
          {acceptedReferralTos ? (
            <ReferralCodeManager referralCode={referralCode} />
          ) : (
            <ReferralTermsOfService />
          )}
        </>
      )}
    </Box>
  );
};
export default ReferralCard;

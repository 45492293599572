import { StatusCodes } from 'http-status-codes';
import isWithinInterval from 'date-fns/isWithinInterval';
import addDays from 'date-fns/addDays';

export const isDomainSecure = ({ isShowit, statusCode, protocol, domainName, url }) =>
  !!isShowit &&
  statusCode === StatusCodes.OK &&
  protocol === 'https:' &&
  (url || '').includes(domainName);

export const isRecordPending = (record) => {
  const now = Date.now();
  const { configureDate } = record || {};
  const configureDateUTC = configureDate ? new Date(configureDate).getTime() : null;
  return configureDate
    ? isWithinInterval(Math.max(now, configureDateUTC), {
        start: configureDateUTC,
        end: addDays(new Date(configureDate), 2).getTime(),
      })
    : false;
};

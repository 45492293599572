import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Divider, CircularProgress, Snackbar, Box } from '@mui/material';
import { Instagram as IGLogo } from '@mui/icons-material';
import { ACCOUNT_THIRD_PARTY } from '../../../constants/routePaths';
import {
  getThirdPartyAccounts,
  getInstagramConnectionLink,
  finalizeInstagramConnection,
} from '../../../api';
import {
  THIRD_PARTY_ACCOUNTS,
  DEFAULT_QUERY_SETTINGS,
  THIRD_PARTY_HOSTS,
} from '../../../constants/queryData';
import { SubTitle, Button, When, Alert } from '../../common';
import ThirdPartyAccount from './ThirdPartyAccount';
import Transition from '../../common/Transition';

const Instagram = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();
  const [connectionStatus, setConnectionStatus] = useState({ status: 'idle', message: '' });
  const [newConnectionId, setNewConnectionId] = useState('');

  const { mutate } = useMutation(
    ({ code, state }) => finalizeInstagramConnection({ code, state, type: 'instagram' }),
    {
      onSuccess: ({ integrationId }, { shortCode }) => {
        setConnectionStatus({ status: 'success', message: 'Account Connection Successful' });
        queryClient.invalidateQueries([THIRD_PARTY_ACCOUNTS]);
        setNewConnectionId(integrationId);
        localStorage.setItem(shortCode, 'success');
      },
      onError: (err, { shortCode }) => {
        setConnectionStatus({
          status: 'error',
          message: err.error || 'There was an issue connecting your account',
        });
        localStorage.setItem(shortCode, 'error');
      },
    }
  );
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    const shortCode = `ig.code.${code?.slice(0, 50)}`;
    const codeAlreadyUsed = code ? localStorage.getItem(shortCode) : false;
    const isFromInstagram = THIRD_PARTY_HOSTS.some((host) => document.referrer.match(host));
    if (code && state && isFromInstagram && (!codeAlreadyUsed || codeAlreadyUsed !== 'success')) {
      setConnectionStatus({
        status: 'loading',
        message: 'Finalizing your connection',
      });
      mutate({ code, state, shortCode });
    } else if (code && state) {
      history.push({
        pathname: ACCOUNT_THIRD_PARTY,
        search: '',
      });
    }
  }, [mutate, history, location.search]);

  // Get the third party accounts
  const {
    data: accounts,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(THIRD_PARTY_ACCOUNTS, getThirdPartyAccounts, DEFAULT_QUERY_SETTINGS);

  return (
    <Box>
      <SubTitle style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
        <IGLogo fontSize="medium" />
        &nbsp; Instagram
      </SubTitle>
      <p>
        Connect your Instagram account(s) with Showit so that you can display your Instagram media
        on your site.
      </p>
      <Divider style={{ margin: '1.5em 0' }} />
      <When it={isLoading}>
        <CircularProgress
          thickness={5}
          style={{ width: '1.35em', height: '1.35em', marginBottom: '1.25rem' }}
        />
      </When>
      <When it={isError}>
        <Transition>
          <Alert severity="error">Error Retrieving Accounts</Alert>
        </Transition>
      </When>
      <When
        it={
          !isLoading &&
          isSuccess &&
          accounts !== undefined &&
          accounts.filter((acc) => !!acc.status).length > 0
        }
      >
        <>
          <h3>Connected Accounts</h3>
          {accounts &&
            accounts
              .filter((account) => !!account.status)
              .map((account, i) => (
                <ThirdPartyAccount
                  setConnectionStatus={setConnectionStatus}
                  newConnectionId={newConnectionId}
                  setNewConnectionId={setNewConnectionId}
                  key={account.integrationId}
                  account={account}
                  i={i}
                />
              ))}
        </>
      </When>
      <Button
        style={{ display: 'flex' }}
        onClick={() =>
          getInstagramConnectionLink()
            .then((res) => window.open(res.authorizationUrl, '_self'))
            .catch(() =>
              setConnectionStatus({
                status: 'error',
                message: 'There was an error connecting to Instagram',
              })
            )
        }
      >
        <IGLogo fontSize="medium" /> &nbsp;Connect Instagram
      </Button>
      <Snackbar
        open={connectionStatus.status !== 'idle'}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setConnectionStatus({
            status: 'idle',
            message: '',
          });
        }}
      >
        <Alert
          severity={
            // eslint-disable-next-line
            connectionStatus.status === 'success'
              ? 'success'
              : connectionStatus.status === 'error'
              ? 'error'
              : 'info'
          }
        >
          {connectionStatus.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Instagram;

import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Card, Container, Link, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import styled from '@emotion/styled';

import {
  Alert,
  Button,
  Checkbox,
  ListSelect,
  PasswordNoteCard,
  ServicePage,
  SubTitle,
  TextInput,
  When,
} from '../common';
import OuterCardLogo from '../common/OuterCardLogo';
import CountrySelect from '../common/CountrySelect';
import heardFrom from './heardFromOptions';
import industries from './industryOptions';
import config from '../../config';
import { create } from '../../api';
import { FAIL, SIGNUP, SUCCESS } from '../../constants/routePaths';
import { yup, buildSubmitHandler, formikErrorFocus, handleError } from '../../utils';
import phoneUtil from '../../utils/phoneNumber';
import PasswordInput from '../common/PasswordInput';
import theme from '../../theme';
import Transition from '../common/Transition';
import { REFERRAL_CODE_KEY } from '../../constants';

const FormContainer = styled(Box)`
  ${theme.breakpoints.up('sm')} {
    width: 80%;
    margin: 0 auto;
  }
`;

const { TOS_URL } = config;

const validationSchema = yup.createSchema({
  firstName: yup.firstName,
  lastName: yup.lastName,
  businessName: yup.businessName,
  industry: yup.industry,
  currentWebsite: yup.currentWebsite,
  country: yup.country,
  phoneNumber: yup.phoneNumber,
  heardFrom: yup.heardFrom,
  pleaseExplain: yup.pleaseExplain,
  password: yup.password,
  passwordConfirmation: yup.passwordConfirmation,
  acceptedTos: yup.acceptedTos,
});

const fieldNames = [
  'emailAddress',
  'firstName',
  'lastName',
  'businessName',
  'industry',
  'country',
  'phoneNumber',
  'currentWebsite',
  'heardFrom',
  'pleaseExplain',
  'password',
  'passwordConfirmation',
  'referralCode',
  'acceptedTos',
];

const getFinalValues = (values) => {
  const { phoneNumber, country, ...formFields } = values;
  const parsedNumber = phoneUtil.parse(phoneNumber, country);
  const accountPhone = phoneUtil.getE164Format(parsedNumber);
  return { ...formFields, phoneNumber: accountPhone };
};

const onError = (history) => {
  const accountRejectionOverride = ({ code }) => {
    if (code === 'AccountRejected') {
      history.replace(FAIL);
    }
  };

  return (values, formikActions) => handleError(values, formikActions, accountRejectionOverride);
};

/**
 * Gets the value of a specified cookie by name.
 *
 * @param {string} name The name of the cookie to retrieve.
 * @returns {string} The value of the cookie, or an empty string if the cookie does not exist.
 */
const getCookie = (name) => {
  const cookies = decodeURIComponent(document.cookie).split('; ');
  const cookieValue = cookies.find((cookie) => cookie.startsWith(`${name}=`));
  return cookieValue ? cookieValue.split('=')[1] : '';
};

const Create = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { emailAddress, returnUrl, returnUrlButtonText } = state || {};
  const { mutate } = useMutation(({ values }) => create(getFinalValues(values)), {
    onSuccess: () => {
      history.replace(SUCCESS, { returnUrl, returnUrlButtonText });
    },
    onError: onError(history),
  });
  const referralCode = localStorage.getItem(REFERRAL_CODE_KEY);
  const inputRefs = useRef({});
  const setInputRef = (element) => {
    if (element != null) {
      inputRefs.current[element.name] = element;
    }
  };

  const { errors, values, handleSubmit, handleChange, touched, isSubmitting, handleBlur } =
    useFormik({
      initialValues: {
        emailAddress,
        firstName: '',
        lastName: '',
        businessName: '',
        industry: '',
        country: 'US',
        phoneNumber: '',
        currentWebsite: '',
        referralCode: referralCode || '',
        heardFrom: '', // ## <-- Chuck preselected here.
        pleaseExplain: '', // ## <-- Chuck prefilled here.
        password: '',
        passwordConfirmation: '',
        acceptedTos: false,

        source: getCookie('sct_source'),
        medium: getCookie('sct_medium'),
        campaign: getCookie('sct_campaign'),
        term: getCookie('sct_term'),
        content: getCookie('sct_content'),
      },
      enableReinitialize: true,
      validationSchema,
      validateOnBlur: true,
      validateOnChange: false,
      onSubmit: buildSubmitHandler(mutate),
    });

  useEffect(() => {
    if (!emailAddress) {
      history.push(SIGNUP);
    }
  }, [emailAddress, history]);

  useEffect(() => {
    // only focus on error inputs after user submits
    if (isSubmitting) {
      formikErrorFocus(fieldNames, errors, inputRefs.current);
    }
  }, [isSubmitting, errors]);

  return (
    <ServicePage>
      <Container maxWidth="sm">
        <OuterCardLogo mt={4} />
        <Card>
          <FormContainer>
            <form onSubmit={handleSubmit}>
              <SubTitle formHeader my={2}>
                Create your Account
              </SubTitle>
              <When it={!!errors.general}>
                <Transition>
                  <Alert severity="error" variant="outlined" noBorder>
                    {errors.general}
                  </Alert>
                </Transition>
              </When>
              <TextInput
                required
                inputRef={setInputRef}
                name="firstName"
                label="First Name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
              />
              <TextInput
                required
                inputRef={setInputRef}
                name="lastName"
                label="Last Name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
              />
              <TextInput
                required
                inputRef={setInputRef}
                name="businessName"
                label="Business Name"
                value={values.businessName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.businessName && !!errors.businessName}
                helperText={touched.businessName && errors.businessName}
              />
              <ListSelect
                required
                native
                inputProps={{
                  name: 'industry',
                  inputRef: setInputRef,
                }}
                list={industries}
                label="Industry"
                value={values.industry}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.industry && !!errors.industry}
                helperText={touched.industry && errors.industry}
              />

              <TextInput
                inputRef={setInputRef}
                name="currentWebsite"
                label="Current Website"
                value={values.currentWebsite}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.currentWebsite && !!errors.currentWebsite && !!values.currentWebsite}
                helperText={touched.currentWebsite && errors.currentWebsite}
              />

              <ListSelect
                required
                native
                inputProps={{
                  name: 'heardFrom',
                  inputRef: setInputRef,
                }}
                list={heardFrom}
                name="heardFrom"
                label="I heard about Showit from"
                value={values.heardFrom}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.heardFrom && !!errors.heardFrom}
                helperText={touched.heardFrom && errors.heardFrom}
              />

              <TextInput
                inputRef={setInputRef}
                name="pleaseExplain"
                label="Please Explain"
                value={values.pleaseExplain}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.pleaseExplain && !!errors.pleaseExplain}
                helperText={touched.pleaseExplain && errors.pleaseExplain}
              />

              <CountrySelect
                name="country"
                label="Country"
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <TextInput
                required
                inputRef={setInputRef}
                name="phoneNumber"
                label="Phone Number"
                value={values.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.phoneNumber && !!errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
              />

              <SubTitle formHeader my={2}>
                Login Info
              </SubTitle>

              <TextInput
                required
                InputLabelProps={{ shrink: true }}
                inputRef={setInputRef}
                type="text"
                name="emailAddress"
                label="Email"
                value={values.emailAddress}
                onChange={(e) => {
                  e.target.value = e.target.value.trim();
                  handleChange(e);
                }}
                error={touched.emailAddress && !!errors.emailAddress}
                helperText={touched.emailAddress && errors.emailAddress}
              />

              <PasswordNoteCard
                password={values.password}
                passwordConfirmation={values.passwordConfirmation}
              />

              <PasswordInput
                name="password"
                label="Password"
                inputRef={setInputRef}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
              />

              <PasswordInput
                name="passwordConfirmation"
                label="Confirm Password"
                inputRef={setInputRef}
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.passwordConfirmation && !!errors.passwordConfirmation}
                helperText={touched.passwordConfirmation && errors.passwordConfirmation}
              />

              <Checkbox
                inputRef={setInputRef}
                name="acceptedTos"
                label={
                  <Typography>
                    Accept Showit&apos;s&nbsp;
                    <Link
                      href={TOS_URL}
                      target="_blank"
                      rel="noopener"
                      color="brightBlue"
                      underline="hover"
                    >
                      Terms of Service
                    </Link>
                  </Typography>
                }
                value={values.acceptedTos}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.acceptedTos && !!errors.acceptedTos}
                helperText={touched.acceptedTos && errors.acceptedTos}
              />

              <Button
                type="submit"
                fullWidth
                loading={isSubmitting}
                disabled={isSubmitting}
                style={{ margin: '1em 0' }}
              >
                SUBMIT
              </Button>
            </form>
          </FormContainer>
        </Card>
      </Container>
    </ServicePage>
  );
};

export default Create;

import React from 'react';
import { Container } from '@mui/material';

import PasswordUpdateForm from '../../common/PasswordUpdateForm';
import { updatePassword } from '../../../api';

const SecurityPage = () => (
  <Container
    maxWidth="xs"
    sx={{ justifyContent: 'start', display: 'flex', flexDirection: 'column' }}
  >
    <PasswordUpdateForm onSubmit={updatePassword} />
  </Container>
);

export default SecurityPage;

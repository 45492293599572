import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '../../common/Header';
import Card from '../../common/Card/Card';
import PageTitle from '../../common/PageTitle';
import When from '../../common/When';
import { getInstagramDeletionStatus } from '../../../api';
import DotIndicator from '../../common/DotIndicator';
import { DELETE_STATUS, INSTAGRAM } from '../../../constants/queryData';

const DeleteStatus = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const provider = queryParams.get('provider');
  const confirmationCode = queryParams.get('confirmationCode');
  // we don't know where the referral is coming from for sure
  // const isFromInstagram = THIRD_PARTY_HOSTS.some((host) => document.referrer.match(host));

  const {
    data: { message } = {},
    isLoading,
    isError,
    isSuccess,
  } = useQuery(DELETE_STATUS, () => getInstagramDeletionStatus({ provider, confirmationCode }), {
    enabled: provider === INSTAGRAM && !!confirmationCode,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return (
    <div>
      <Header />
      <Container maxWidth="sm">
        <PageTitle align="left">Account Deletion Status</PageTitle>

        <Card>
          <When it={isLoading}>
            <CircularProgress size="1.5rem" />
          </When>
          <When it={isError || !provider || provider !== INSTAGRAM || !confirmationCode}>
            <div
              style={{
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
                margin: '.75rem 0',
              }}
            >
              Status: Record not found. <DotIndicator status="error" />
            </div>
          </When>
          <When it={isSuccess}>
            <div
              style={{
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
                margin: '.75rem 0',
              }}
            >
              Status: {message}
              <DotIndicator status={message === 'Deleted' ? 'success' : 'pending'} />
            </div>
          </When>
        </Card>
      </Container>
    </div>
  );
};

export default DeleteStatus;

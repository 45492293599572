import React from 'react';
import PropTypes from 'prop-types';
import { getHours } from 'date-fns';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { CHARGEBEE_CODE, subscriptionStatuses } from '../../../constants';
import { Button, PageTitle, When } from '../../common';
import { palette } from '../../../theme';
import Notices from './notices';
import DashboardQuickLinks from './DashboardQuickLinks';
import useUser from '../../../hooks/useUser';
import AccountLinks from './AccountLinks/AccountLinks';
import useSubscription from '../subscription/SubscriptionCard/useSubscription';
import { NORMAL } from '../../../constants/userTypes';
import { ACCOUNT_REFERRALS } from '../../../constants/routePaths';
import AccountStatusNotice from './notices/AccountStatusNotice';
import FeaturedPosts from './FeaturedPosts';
import DomainPreview from '../domains/DomainPreview';
import useDomainData from '../../../hooks/useDomainData';

const ReferralCTA = ({ isDesktop = false }) => {
  const history = useHistory();
  const onSendInvite = () => {
    history.push(ACCOUNT_REFERRALS);
  };
  return (
    <Box
      display="flex"
      textAlign={{ xs: 'center', md: 'left' }}
      flexDirection={{ xs: 'column', md: 'row' }}
      py={4}
      sx={{
        borderTop: `1px solid ${palette.primary.main}`,
      }}
    >
      <Box>
        <Typography variant="h5" fontWeight={500}>
          Want a free month?
        </Typography>
        <Box width={isDesktop ? 'auto' : '216px'} m={{ xs: '0 auto', md: 0 }}>
          <Typography variant="body">
            Refer a friend to Showit and you&apos;ll both get a free month!
          </Typography>
        </Box>
      </Box>
      <Box pl={{ xs: 0, md: 5 }} mt={{ xs: 2, md: 0 }}>
        <Button onClick={onSendInvite} variant="outlined" style={{ padding: '0 3em' }}>
          Send Invite
        </Button>
      </Box>
    </Box>
  );
};

const getGreetingTime = () => {
  const date = new Date();
  const hours = getHours(date);

  let greetingTime;

  if (hours < 12) {
    greetingTime = 'Morning';
  } else if (hours < 18) {
    greetingTime = 'Afternoon';
  } else {
    greetingTime = 'Evening';
  }
  return greetingTime;
};

const Home = () => {
  const { user } = useUser();
  const { subscription = {}, isSuccess: isSubSuccess } = useSubscription();
  const { apexDomains, isSuccess } = useDomainData();
  const isDesktop = useMediaQuery('(min-width:960px)');
  return (
    <Container maxWidth="lg" data-testid="home-page">
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <PageTitle style={{ margin: 0 }}>
          Good {getGreetingTime()}
          {user?.firstName && `, ${user.firstName}`}!
        </PageTitle>
        <Notices user={user} />
      </Box>
      <Box display="flex" mt={4} flexDirection={{ xs: 'column', md: 'row' }}>
        {!isDesktop && <AccountStatusNotice user={user} />}
        {isDesktop && <AccountLinks isDesktop />}
        <DashboardQuickLinks />
        {isSuccess && apexDomains.length > 0 && (
          <DomainPreview user={user} apexDomains={apexDomains} isDesktop={isDesktop} />
        )}
        {!isDesktop && <AccountLinks />}
        {isDesktop && <AccountStatusNotice user={user} />}
      </Box>
      <When
        it={
          isSubSuccess &&
          subscription?.service === CHARGEBEE_CODE &&
          (subscription?.status === subscriptionStatuses.ACTIVE ||
            subscription?.status === subscriptionStatuses.IN_TRIAL) &&
          user?.userType === NORMAL
        }
      >
        <ReferralCTA isDesktop={isDesktop} />
      </When>
      <Box display="flex" mb={4} flexDirection={{ xs: 'column', md: 'row' }}>
        <FeaturedPosts />
      </Box>
    </Container>
  );
};

ReferralCTA.propTypes = {
  isDesktop: PropTypes.bool,
};

ReferralCTA.defaultProps = {
  isDesktop: false,
};

export default Home;

export default [
  '',
  'Photography',
  'Web Design',
  'Graphic Design',
  'Health & Wellness',
  'Business/Life Coach',
  'Podcasting',
  'Lifestyle/Influencer',
  'Social Media',
  'Marketing & Branding',
  'Film/Video',
  'Real Estate',
  'Retail',
  'Professional Service',
  'Hospitality',
  'Church/Religious Organization',
  'Non-Profit',
  'Other',
];

import { useQuery, useQueryClient } from 'react-query';
import { ENTRI_CONFIG, ENTRI_SUPPORT } from '../constants/queryData';
import { getEntriAuthToken } from '../api';
import { timestamp } from '../utils/dateTime';
import { loadEntriScript } from '../utils/entriUtils';

const defaults = {
  enabled: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
  staleTime: Infinity,
};
const SETUP_TYPE_AUTOMATIC = 'Automatic';
const SETUP_TYPE_MANUAL = 'Manual';

export const refreshToken = async (queryClient) => {
  const key = [ENTRI_CONFIG];
  await queryClient.refetchQueries(key);
  return queryClient.getQueryData(key).token;
};

/**
 * Determines whether Entri supports automatic DNS configuration for a domain
 * @param {{
 * domainId: number,
 * domainName: string,
 * }} params
 * @param {object} opts
 * @returns {{
 *  supported: boolean,
 * }}
 */
const useEntriSupport = ({ domainId, domainName }, opts = {}) => {
  const queryClient = useQueryClient();
  const { data, ...rest } = useQuery(
    [ENTRI_SUPPORT, domainId],
    // loads the entri script and returns the result of the callback
    async () =>
      loadEntriScript(async (entri) => {
        const { token, expires, appId } = await queryClient.fetchQuery(
          [ENTRI_CONFIG],
          getEntriAuthToken,
          {
            staleTime: Infinity,
          }
        );
        const isExpired = timestamp() >= expires;
        const { setupType } = await entri.checkDomain(domainName, {
          applicationId: appId,
          token: isExpired ? await refreshToken(queryClient) : token,
          dnsRecords: [],
        });
        return setupType === SETUP_TYPE_AUTOMATIC || setupType === SETUP_TYPE_MANUAL;
      }),
    {
      ...defaults,
      ...opts,
    }
  );
  return {
    supported: data,
    ...rest,
  };
};
export default useEntriSupport;

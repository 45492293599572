import gLibPhoneNumber, { PhoneNumberFormat as PNF } from 'google-libphonenumber';

const phoneUtil = gLibPhoneNumber.PhoneNumberUtil.getInstance();

const parse = (rawNumber, country = undefined) => phoneUtil.parse(rawNumber, country);

/**
 *
 * @param {*} parsedNumber - number object that is returned from parse method.
 */
const getE164Format = (parsedNumber) => phoneUtil.format(parsedNumber, PNF.E164);

const isValidNumberForRegion = (parsedNumber, country) => phoneUtil.isValidNumberForRegion(parsedNumber, country);

const getRegionFormat = (parsedNumber) => {
  const isUS = parsedNumber.getCountryCode() === 1;
  return phoneUtil.format(parsedNumber, isUS ? PNF.NATIONAL : PNF.INTERNATIONAL);
};

/**
 * @param {*} parsedNumber
 * @returns {string} Example: US
 */
const getRegionCode = (parsedNumber) => phoneUtil.getRegionCodeForNumber(parsedNumber);

const getNationalFormat = (parsedNumber) => phoneUtil.format(parsedNumber, PNF.NATIONAL);

export const yupVerifyPhoneNumber = (value, context) => {
  const { phoneNumber, country } = context.parent;
  try {
    const number = parse(phoneNumber, country);
    return isValidNumberForRegion(number, country);
  } catch (err) {
    return false;
  }
};

export default {
  parse,
  getE164Format,
  isValidNumberForRegion,
  getNationalFormat,
  getRegionFormat,
  getRegionCode,
};

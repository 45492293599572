/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, Link } from '@mui/material';
import { useIntercom } from 'react-use-intercom';

const CHANGE_MESSAGE = 'I would like to change my subscription ';

const ChangeNotice = () => {
  const { showNewMessages } = useIntercom();
  const handleNewMessages = () => showNewMessages(CHANGE_MESSAGE);
  return (
    <Box my={1}>
      Need to change your subscription?{' '}
      <Link component="button" onClick={handleNewMessages} underline="always">
        Contact Support
      </Link>
    </Box>
  );
};

export default ChangeNotice;

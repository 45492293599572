import React from 'react';
import TextInput from '@mui/material/TextField';
import PropTypes from 'prop-types';

const TextInputWithHasValue = (props) => {
  const { value } = props;
  // eslint-disable-next-line
  return <TextInput className={value ? 'hasValue' : ''} {...props} />;
};

TextInputWithHasValue.defaultProps = {
  value: '',
};

TextInputWithHasValue.propTypes = {
  value: PropTypes.string,
};

export default TextInputWithHasValue;

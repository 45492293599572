import { React, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import AccountSelect from '../../common/AccountSelect';
import { PageTitle, When } from '../../common';
import { ACTIVE } from '../../../constants/accountStatuses';
import useUser from '../../../hooks/useUser';
import useContributorAccess from '../../../hooks/useContributorAccess';
import ApexDomain from './ApexDomain/ApexDomain';
import useBlogs from '../../../hooks/useBlogs';
import useDomainData from '../../../hooks/useDomainData';

const Domains = () => {
  const { state } = useLocation();
  const { user } = useUser({
    refetchOnWindowFocus: 'always',
  });
  const [viewingUserId, setViewingUserId] = useState(state?.ownerId || user.id);

  const { apexDomains, isLoading, isError, isSuccess, error } = useDomainData(viewingUserId);
  const { blogs, isSuccess: blogsFetched } = useBlogs(viewingUserId);
  const switchAccountHandler = (event) => {
    setViewingUserId(Number(event.target.value));
  };

  const { accessibleAccounts = [] } = useContributorAccess();
  const userBlogType =
    viewingUserId === user.id
      ? user.blogType
      : accessibleAccounts.find(({ id }) => id === viewingUserId)?.blogType;

  return (
    <Container maxWidth="md" data-testid="third-party-page" style={{ padding: 0 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <PageTitle align="left">Domains</PageTitle>
        <When it={user.status === ACTIVE && !!accessibleAccounts.length}>
          <Box sx={{ maxWidth: '20rem', mb: '-2rem' }}>
            <AccountSelect onChange={switchAccountHandler} value={`${viewingUserId}`} />
          </Box>
        </When>
      </Stack>
      <When it={isLoading}>
        <CircularProgress size={25} />
      </When>
      <When it={isError}>
        <Typography color="error">
          {error?.response?.status === StatusCodes.UNAUTHORIZED
            ? 'Not authorized.'
            : 'There was an error loading your domain information.'}
        </Typography>
      </When>
      {blogsFetched &&
        apexDomains
          .sort((d) => (blogs.some((blog) => blog.domainId === d.domainId) ? -1 : 1))
          .map((apexDomain) => (
            <ApexDomain
              userId={Number(viewingUserId)}
              apexDomain={apexDomain}
              userBlogType={userBlogType}
              blogs={blogs}
              key={apexDomain.domainId}
            />
          ))}
      {isSuccess && apexDomains.length === 0 && (
        <Typography>No domain information available at this time.</Typography>
      )}
    </Container>
  );
};

export default Domains;

import { useQuery } from 'react-query';

import { getFeaturettes } from '../api/external';
import { FEATURETTES, DEFAULT_QUERY_SETTINGS } from '../constants/queryData';

export default (settings = {}) => {
  const { data: featurettes = [], ...rest } = useQuery(FEATURETTES, () => getFeaturettes(), {
    ...DEFAULT_QUERY_SETTINGS,
    ...settings,
  });
  return { featurettes: featurettes.map((featurette) => featurette?.acf), ...rest };
};

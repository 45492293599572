import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const DragAndDropIcon = ({ sx, style, thick = false, ...props }) => {
  const customStyle = thick
    ? {
        stroke: 'currentColor',
        strokeWidth: '1px',
      }
    : {};

  return (
    <SvgIcon sx={sx} style={{ ...customStyle, ...style }} {...props}>
      <path d="M23.65,11.65l-2.65-2.79c-.19-.19-.35-.13-.35,.15v2h-7.65V3.37h2c.27-.01,.34-.17,.15-.37L12.35,.35c-.19-.19-.51-.19-.71,0l-2.79,2.66c-.19,.19-.13,.35,.15,.35h2v7.63H3.35v-2c0-.28-.16-.34-.35-.15L.35,11.65c-.19,.19-.19,.51,0,.71l2.65,2.79c.19,.19,.35,.13,.35-.15v-2h7.65v7.65h-2c-.27,0-.34,.16-.15,.35l2.79,2.65c.19,.19,.51,.19,.71,0l2.79-2.65c.19-.19,.13-.35-.15-.35h-2v-7.65h7.65v2c0,.28,.16,.34,.35,.15l2.65-2.79c.19-.19,.19-.51,0-.71Z" />
    </SvgIcon>
  );
};

DragAndDropIcon.propTypes = {
  sx: PropTypes.shape({
    fontSize: PropTypes.string,
    color: PropTypes.string,
  }),
  style: PropTypes.shape({}),
  thick: PropTypes.bool,
};

DragAndDropIcon.defaultProps = {
  sx: {},
  style: {},
  thick: false,
};

export default DragAndDropIcon;

import React from 'react';

import OuterCardLogo from '../common/OuterCardLogo';
import { Card, Link, ServicePage, SubTitle } from '../common';
import CardText from '../common/CardText';
import PageContent from '../common/PageContent';
import Links from '../../constants/links';

const Expired = () => (
  <ServicePage>
    <PageContent maxWidth="xs">
      <OuterCardLogo />
      <Card>
        <SubTitle cardTitle>This link is no longer valid</SubTitle>
        <CardText>
          To finish creating your account and start your 14-day trial, please{' '}
          <Link href={Links.HELP_URL}>contact our support team</Link>.
        </CardText>
      </Card>
    </PageContent>
  </ServicePage>
);

export default Expired;

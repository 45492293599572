import React from 'react';
import { Container } from '@mui/material';

import { PageTitle } from '../../common';
import EmailForm from './EmailForm';
import UserForm from './UserForm';
import useUser from '../../../hooks/useUser';
import ContributorAccess from './ContributorAccess';

const Profile = () => {
  const { user } = useUser({
    enabled: true,
    refetchOnWindowFocus: 'always',
  });

  return (
    <Container maxWidth="sm">
      <PageTitle align="left">Account</PageTitle>
      <UserForm
        firstName={user.firstName}
        lastName={user.lastName}
        businessName={user.businessName}
        phoneNumber={user.phoneNumber}
      />
      <EmailForm emailAddress={user.emailAddress} confirmed={user.emailConfirmed} />
      <ContributorAccess user={user} />
    </Container>
  );
};

export default Profile;

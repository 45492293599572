import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import PayPalIcon from '../../icons/PayPalIcon';
import { PayPalButton } from './components';
import { getPayPalAuthUrl } from '../../../api';

const LogInWithPayPal = ({ setSnackbar }) => {
  const {
    mutate: openPayPal,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: getPayPalAuthUrl,
    onSuccess: (authUrl) => window.open(authUrl, '_self'),
    onError: () =>
      setSnackbar({
        message: 'An error occurred.',
        status: 'error',
      }),
  });

  return (
    <PayPalButton
      onClick={openPayPal}
      loading={isLoading || isSuccess}
      loadingIndicator="Authorizing..."
    >
      <PayPalIcon />
      &nbsp;Log in with PayPal
    </PayPalButton>
  );
};
LogInWithPayPal.propTypes = {
  setSnackbar: PropTypes.func.isRequired,
};
export default LogInWithPayPal;

import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Skeleton, Typography, Box, Card, useMediaQuery } from '@mui/material';
import Navbar from './navbar';
import MenuDrawer from './drawer';
import Domains from './domains';
import Home from './home';
import Profile from './profile';
import MessagesPage from './messages/MessagesPage';
import ThirdParty from './thirdParty';
import Security from './security';
import Intercom from './intercom';
import Notifications from './notifications';
import Subscribe from './subscribe';
import Subscription from './subscription';
import SubscribeCompleted from './subscribe/Completed';
import { NotFound, Footer, When } from '../common';
import {
  ACCOUNT,
  ACCOUNT_MESSAGES,
  ACCOUNT_NOTIFICATIONS,
  ACCOUNT_PROFILE,
  ACCOUNT_SECURITY,
  ACCOUNT_SUBSCRIBE,
  ACCOUNT_SUBSCRIPTION,
  ACCOUNT_THIRD_PARTY,
  ACCOUNT_SUBSCRIBE_SUCCESS,
  ACCOUNT_DOMAINS,
  ACCOUNT_REFERRALS,
  PAYPAL_CALLBACK,
} from '../../constants/routePaths';
import useUser from '../../hooks/useUser';
import { useAuthorization } from '../../api/session';
import SingleMessagePage from './messages/SingleMessagePage';
import ReferralsView from './referrals/ReferralsView';
import PayPalCallback from './auth/PayPalCallback';

const AccountContainer = styled('div')({
  display: 'flex',
  flexGrow: 0,
  flexDirection: 'column',
});

const Routes = () => (
  <Switch>
    <Route path={ACCOUNT} exact component={Home} />
    <Route path={ACCOUNT_PROFILE} exact component={Profile} />
    <Route path={ACCOUNT_MESSAGES} exact component={MessagesPage} />
    <Route path={`${ACCOUNT_MESSAGES}/:id`} exact component={SingleMessagePage} />
    <Route path={ACCOUNT_NOTIFICATIONS} exact component={Notifications} />
    <Route path={ACCOUNT_SECURITY} exact component={Security} />
    <Route path={ACCOUNT_DOMAINS} exact component={Domains} />
    <Route path={ACCOUNT_REFERRALS} exact component={ReferralsView} />
    <Route path={ACCOUNT_SUBSCRIBE} exact component={Subscribe} />
    <Route path={ACCOUNT_SUBSCRIPTION} exact component={Subscription} />
    <Route path={ACCOUNT_THIRD_PARTY} exact component={ThirdParty} />
    <Route path={ACCOUNT_SUBSCRIBE_SUCCESS} exact component={SubscribeCompleted} />
    <Route path={PAYPAL_CALLBACK} exact component={PayPalCallback} />
    <Route render={() => <NotFound linkTo={ACCOUNT} linkText="Return to Account." />} />
  </Switch>
);

const Loading = () => (
  <Container maxWidth="sm">
    <Skeleton />
    <Skeleton variant="rectangular" height={200} />
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </Container>
);

const Account = () => {
  const { isLoading, isError, isSuccess, user } = useUser({ enabled: true });
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const { isAdmin } = useAuthorization();
  const isDesktop = useMediaQuery('(min-width:960px)');
  const toggleDrawerVisibility = () => setDrawerVisibility(!drawerVisibility);
  const onClose = () => setDrawerVisibility(false);
  const PageBox = styled(Box)`
    background: white;
  `;
  const WrapperComponent = isDesktop ? Card : PageBox;

  return (
    <AccountContainer>
      <Container disableGutters={!isDesktop} maxWidth="lg">
        <Box mt={{ xs: 0, md: 5 }}>
          <WrapperComponent>
            <Navbar toggleDrawerVisibility={toggleDrawerVisibility} />
            <MenuDrawer open={drawerVisibility} onClose={onClose} />
            <Box
              p={2}
              mt={{ xs: 2, md: 3 }}
              sx={{ minHeight: 'calc(100vh - 310px)', display: 'flex' }}
            >
              <When it={isLoading}>
                <Loading />
              </When>
              <When it={isError}>
                <Typography color="error">
                  A problem occurred while loading your account information
                </Typography>
              </When>
              {isSuccess ? (
                isAdmin ? (
                  <Routes />
                ) : (
                  <Intercom user={user}>
                    <Routes />
                  </Intercom>
                )
              ) : null}
            </Box>
          </WrapperComponent>
        </Box>
        <Footer />
      </Container>
    </AccountContainer>
  );
};

export default Account;

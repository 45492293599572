import React from 'react';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReferralCard from '../subscription/ReferralCard';
import { NORMAL } from '../../../constants/userTypes';
import useUser from '../../../hooks/useUser';
import useSubscription from '../subscription/SubscriptionCard/useSubscription';
import { PageTitle, SubscribeButton } from '../../common';
import Links from '../../../constants/links';
import ReferralTabs from './ReferralTabs';
import { ACTIVE } from '../../../constants/accountStatuses';
import { ACCOUNT_SUBSCRIBE, ACCOUNT_SUBSCRIPTION } from '../../../constants/routePaths';
import { CHARGEBEE_CODE } from '../../../constants';

// must be marked as an "active affiliate" OR is a NORMAL, ACTIVE user
const isQualified = ({ userType, status, affiliateStatus, service }) =>
  affiliateStatus || (userType === NORMAL && status === ACTIVE && service === CHARGEBEE_CODE);

const ReferralsView = () => {
  const { user } = useUser();
  const { subscription, isSuccess } = useSubscription();

  const hasSubscription = !!subscription?.id;
  const qualifies = isQualified({
    affiliateStatus: user.affiliateStatus,
    userType: user.userType,
    status: user.status,
    service: subscription?.service,
  });

  return (
    <Container maxWidth="sm">
      <PageTitle align="left">Referrals</PageTitle>
      {qualifies ? (
        <>
          <ReferralCard />
          <ReferralTabs />
        </>
      ) : isSuccess ? (
        <Box>
          <Typography variant="body2" mb={2}>
            {hasSubscription
              ? 'You do not qualify for the '
              : 'You must have an active subscription to qualify for the '}
            <Link
              target="_blank"
              rel="noreferrer"
              href={Links.REFERRAL_HELP_URL}
              title="Learn more about the Referral Program"
              underline="always"
            >
              referral program.
            </Link>
          </Typography>
          {(!hasSubscription || subscription?.service === CHARGEBEE_CODE) && (
            <SubscribeButton to={hasSubscription ? ACCOUNT_SUBSCRIPTION : ACCOUNT_SUBSCRIBE}>
              {hasSubscription ? 'Resubscribe' : 'Subscribe Now'}
            </SubscribeButton>
          )}
        </Box>
      ) : null}
    </Container>
  );
};
export default ReferralsView;

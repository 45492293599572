import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { dnsRecordShape } from '../../../../../domain/PropTypes';
import WarningIcon from '../../../../icons/WarningIcon';
import { DNS_SHOULD_NOT_EXIST, DNS_UNKNOWN } from '../../../../../constants/domainConstants';
import { isRecordPending } from '../../../../../utils/domainTools';
import { DNS_PROPAGATING, CONTACT_SUPPORT, ISSUING_SSL, ALERT_TYPES } from './alertTypes';

const getAlertType = ({ dnsValid, dnsPending, sslEnabled, isBlogDomain }) =>
  !dnsValid && dnsPending
    ? DNS_PROPAGATING
    : dnsValid && !sslEnabled && isBlogDomain
    ? CONTACT_SUPPORT // dns is valid and domain has a blog record associated with its apex
    : dnsValid && !sslEnabled && !isBlogDomain
    ? ISSUING_SSL // dns is valid and the domain is NOT a blog domain
    : null;

const DomainAlert = ({ dnsRecords, dnsValid, sslEnabled, isBlogDomain }) => {
  const dnsPending =
    dnsRecords.length > 0 &&
    dnsRecords.every(
      (record) =>
        record.expectedValue === DNS_SHOULD_NOT_EXIST ||
        record.expectedValue === DNS_UNKNOWN ||
        isRecordPending(record)
    );

  const [alert, setAlert] = useState(
    getAlertType({ dnsValid, sslEnabled, dnsPending, isBlogDomain })
  );

  useEffect(() => {
    setAlert(getAlertType({ dnsValid, sslEnabled, dnsPending, isBlogDomain }));
  }, [dnsValid, sslEnabled, dnsPending, isBlogDomain]);

  const { title, desc } = ALERT_TYPES[alert] || {};
  if (!alert || !title || !desc) {
    return null;
  }
  return (
    <Alert
      icon={alert === CONTACT_SUPPORT ? <WarningIcon w="1.5rem" h="1.5rem" display="flex" /> : null}
      severity={alert === CONTACT_SUPPORT ? 'warning' : 'info'}
      sx={{
        mb: 2,
        borderRadius: '1rem',
        border: '1px solid',
      }}
    >
      <AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>
      {desc()}
    </Alert>
  );
};

DomainAlert.propTypes = {
  dnsRecords: PropTypes.arrayOf(dnsRecordShape).isRequired,
  dnsValid: PropTypes.bool.isRequired,
  sslEnabled: PropTypes.bool.isRequired,
  isBlogDomain: PropTypes.bool.isRequired,
};

export default DomainAlert;

export const OOPS = 'Oops! There was an issue.';
export const DOH =
  'Doh! Something went wrong while retrieving your information. Please try again in a few minutes.';
export const GENERAL = 'A problem occurred while performing the action. Please try again later.';
export const SESSION_ERROR_LOGOUT =
  'There was a problem with your session. Please sign back in to continue.';
export const LOGIN_INVALID =
  'Your email or password is incorrect. Try again or click Lost Password below.';
export const PASSWORD_MISSING = 'Please enter a password.';
export const PASSWORD_NOT_CONFIRMED = 'Please confirm your password.';
export const PASSWORD_DOES_NOT_MATCH = 'Passwords do not match. Please re-enter your password.';
export const PASSWORD_SHORT = 'Password must be at least 12 characters.';
export const PASSWORD_WEAK = 'Please create a stronger password.';
export const EMAIL_MISSING = 'Please enter your email address.';
export const EMAIL_DOES_NOT_MATCH = 'Emails do not match. Please re-enter your email.';
export const EMAIL_NOT_CONFIRMED = 'Please confirm your email.';
export const EMAIL_BAD = 'This is not a valid email format.';
export const EMAIL_IN_USE =
  'This is email address is already used for a Showit account. Sign in with this address or choose a different email address to create a new account.';
export const FIRSTNAME_MISSING = 'Please enter your First Name.';
export const FIRSTNAME_SHORT = 'First name is too short.';
export const LASTNAME_MISSING = 'Please enter your Last Name.';
export const LASTNAME_SHORT = 'Last name is too short.';
export const BUSINESS_MISSING = 'Please enter your Business Name.';
export const INVALID_DOMAIN = 'Please enter a valid domain.';
export const INDUSTRY_MISSING = 'Please choose the Industry you are in.';
export const HEARDFROM_MISSING = 'Please tell us how you heard about Showit';
export const PHONENUMBER_MISSING = 'Please enter your Phone Number.';
export const PHONENUMBER_SHORT = 'Phone number seems too short.';
export const PHONENUMBER_LONG = 'Phone number seems too long.';
export const PHONENUMBER_BAD = 'Invalid Phone Number';
export const CONFIRMATIONCODE_SIZE = 'Codes are six digits.';
export const CONFIRMATIONCODE_MISSING = 'Code required.';
export const COUNTRY_MISSING = 'Please select country.';
export const TOS_MISSING = "To create your account, please accept Showit's terms of service.";
export const USER_CONFIRM_LINK_INVALID =
  'Email confirmation link seems invalid. If you received a User Email Confirmation email from us please check the link provided.';
export const ERROR_ROUTING_FALLBACK =
  'Error routing was called but no useful error data was provided.';
export const CHARGEBEE_SUBSCRIPTION_ERROR =
  'A problem occurred while getting your subscription information.';

export const generalErrorLocation = 'general';

const general = {
  EmailInUse: EMAIL_IN_USE,
  EmailConfirmationMismatch: EMAIL_DOES_NOT_MATCH,
  InvalidLoginValue: LOGIN_INVALID,
  // DuplicateAccountProperties: duplicate values entered in create form.
  // InvalidPassword: Password may not be the same as current password.
  // DuplicateContactMethod: The current phone number is already associated with the account.
  AuthorizationError: SESSION_ERROR_LOGOUT,
};

const emailAddress = {
  InvalidInput: EMAIL_BAD,
};

export const apiErrorCodeMessageOverrides = {
  general,
  emailAddress,
};

export const CONTACTMETHODS = 'contactmethods';
export const MESSAGE = 'message';
export const MESSAGES = 'messages';
export const USER = 'user';
export const THIRD_PARTY_ACCOUNTS = 'thirdPartyAccounts';
export const APP_INFO = 'appInfo';
export const DOMAIN_DATA = 'domainData';
export const DNS = 'dns';
export const ENTRI_CONFIG = 'entriConfig';
export const ENTRI_SUPPORT = 'entriSupport';
export const DOMAINS_ANALYSIS = 'domainsAnalysis';
export const BLOG_DOMAINS = 'blogDomains';
export const SUBSCRIPTION = 'subscription';
export const DISCOUNT_CODE = 'discountCode';
export const REFERRAL_CODE = 'referralCode';
export const REFERRAL = 'referral';
export const REFERRALS = 'referrals';
export const REFERRAL_STATS = 'referralStats';
export const AFFILIATE_TRANSACTIONS = 'affiliateTransactions';
export const USER_PAYOUT_METHOD = 'userPayoutMethod';
export const DELETE_STATUS = 'deleteStatus';
export const SIGNUP_DATA = 'signupData';
export const PASSWORD_RESET_SESSION_VERIFY = 'passwordResetSessionVerify';
export const FEATURETTES = 'featurettes';
export const DESIGNER_SHOWCASE = 'designerShowcase';
export const BLOG_POSTS = 'blogPosts';
export const DOMAIN_STATUS = 'domainStatus';
export const CONTRIBUTOR_ACCESS = 'contributorAccess';

export const THIRD_PARTY_HOSTS = [/https:\/\/.*instagram.com.*/i];
export const INSTAGRAM = 'instagram';

export const DEFAULT_QUERY_SETTINGS = {
  enabled: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: true,
  refetchOnMount: true,
  staleTime: Infinity,
};

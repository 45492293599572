/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@mui/material';

const menuFromList = (list) => {
  let menuItems = null;

  if (list && list.length > 0) {
    menuItems = list.map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ));
  }
  return menuItems;
};

const optionsFromList = (list) => {
  let menuItems = null;

  if (list && list.length > 0) {
    menuItems = list.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ));
  }
  return menuItems;
};

const ListSelect = ({ list, native = false, ...props }) => {
  const menuItems = useMemo(
    () => (native ? optionsFromList(list) : menuFromList(list)),
    [list, native]
  );
  return (
    <TextField
      select
      {...props}
      SelectProps={{
        native,
      }}
    >
      {menuItems}
    </TextField>
  );
};

ListSelect.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  native: PropTypes.bool.isRequired,
};

export default ListSelect;

import config from '../config';

let cbInstance = null;
let sections = {};
const getChargebee = (lazyLoad = true) => {
  if (lazyLoad && cbInstance === null && window.Chargebee) {
    cbInstance = window.Chargebee.init({
      site: config.CHARGEBEE_SITE,
      enableFBQTracking: true
    });
    sections = window.Chargebee.getPortalSections();
  }
  return { cbInstance, sections };
};

export default getChargebee;

import React, { useState } from 'react';
import { Box, Grid, List, ListItem } from '@mui/material';
import { useMutation } from 'react-query';
import PropTypes from 'prop-types';
import { SubTitle, When } from '../../../common';
import config from '../../../../config';
import useBrowserInfo from '../../../../hooks/useBrowserInfo';
import useBlogs from '../../../../hooks/useBlogs';
import Links from '../../../../constants/links';
import { DragAndDropIcon, EducationIcon, ShopIcon, WordPressIcon } from '../../../common/Icons';
import DashboardQuickLink, { QuickLinkButton } from './DashboardQuickLink';
import { getAppLogin } from '../../../../api';
import ChromeRequiredModal from './ChromeRequiredModal';
import { blogHostingStatus, blogStatuses } from '../../../../constants';
import DesignAppButton from './DesignAppButton';
import { palette } from '../../../../theme';

const iconSize = { fontSize: '1.1em' };

const GridItem = ({ children, showBlogLink }) => {
  const sm = showBlogLink ? 6 : null;
  const md = showBlogLink ? 3 : 4;
  return (
    <Grid item xs={12} sm={sm} md={md}>
      {children}
    </Grid>
  );
};

const DashboardQuickLinks = () => {
  const { designAppSupported } = useBrowserInfo();
  const { blogs } = useBlogs();
  const { mutate } = useMutation(getAppLogin, {
    onSuccess: (data) => {
      window.open(`${config.DESIGN_APP_URL}?admin_token=${data.token}`, '_blank');
    },
  });
  const [open, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);

  const firstActiveBlog = blogs.find(
    (blog) =>
      blog.status === blogStatuses.AVAILABLE &&
      blog.hostingStatus === blogHostingStatus.ACTIVE &&
      !!blog.domainName
  );
  const blogDomainName = firstActiveBlog?.domainName;
  const showBlogLink = !!blogDomainName;

  return (
    <Box flex="1" px={{ xs: 0, md: 4 }} mb={3}>
      <SubTitle homeHeader style={{ textAlign: 'left' }} mb={2}>
        Quick Links
      </SubTitle>
      <List sx={{ paddingTop: 0 }}>
        <ListItem disableGutters>
          {designAppSupported ? (
            <DesignAppButton openDesignApp={mutate} />
          ) : (
            <QuickLinkButton
              title="Chrome Required"
              startIcon={<DragAndDropIcon thick style={iconSize} />}
              type="submit"
              variant="primary"
              onClick={() => setOpenModal(true)}
              sx={{ backgroundColor: `${palette}80` }}
            >
              Design App
            </QuickLinkButton>
          )}
          <ChromeRequiredModal open={open} handleClose={handleClose} />
        </ListItem>
        <When it={showBlogLink}>
          <ListItem disableGutters>
            <DashboardQuickLink
              icon={<WordPressIcon style={iconSize} />}
              title="Go to your Wordpress blog admin"
              url={`http://${blogDomainName}/wp-admin`}
              label="Blog Admin"
            />
          </ListItem>
        </When>
        <ListItem disableGutters>
          <DashboardQuickLink
            icon={<ShopIcon style={iconSize} />}
            title="Go to the Showit Design Market"
            url={Links.DESIGN_MARKET_URL}
            label="Design Market"
          />
        </ListItem>
        <ListItem disableGutters>
          <DashboardQuickLink
            icon={<EducationIcon style={iconSize} />}
            title="Go to the Showit Design Training Courses"
            url={Links.TRAINING_COURSES_URL}
            label="Training Courses"
          />
        </ListItem>
      </List>
    </Box>
  );
};

GridItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  showBlogLink: PropTypes.bool,
};

GridItem.defaultProps = {
  showBlogLink: false,
};

export default DashboardQuickLinks;

/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, ListItem, ListItemIcon } from '@mui/material';
import { Check } from '@mui/icons-material';

import { Header, PlanList } from './Styled';

const FeatureList = ({ features }) => (
  <>
    <Header>Features</Header>
    <PlanList>
      {features.map((feature, featuresIndex) => (
        <ListItem className="reason" key={`listItem-${featuresIndex}`} disableGutters>
          <ListItemIcon>
            <Check />
          </ListItemIcon>
          {feature.link ? (
            <span>
              {feature.link.map(({ href, text }, featureIndex) =>
                href ? (
                  <Link href={href} target="_blank" underline="hover" key={`link-${featureIndex}`}>
                    {text}
                  </Link>
                ) : (
                  text
                )
              )}
            </span>
          ) : (
            feature.text
          )}
        </ListItem>
      ))}
    </PlanList>
  </>
);

FeatureList.defaultProps = {
  features: [],
};

FeatureList.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      html: PropTypes.bool,
    })
  ),
};

export default FeatureList;

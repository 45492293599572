import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { AppBar, Box, Grid, Hidden, IconButton, Link, useMediaQuery } from '@mui/material';
import { Menu } from '@mui/icons-material';
import ShowitLogo from '../../../assets/showit-logo.svg';
import ShowitLogoNoText from '../../../assets/showit-logo-no-text.svg';
import {
  ACCOUNT,
  ACCOUNT_MESSAGES,
  ACCOUNT_PROFILE,
  ACCOUNT_THIRD_PARTY,
  ACCOUNT_SUBSCRIPTION,
  LOGIN,
  ACCOUNT_DOMAINS,
  ACCOUNT_REFERRALS,
} from '../../../constants/routePaths';
import { logoutUser } from '../../../utils/user';

const activeStyle = {
  textDecoration: 'underline',
};

const MenuBar = () =>
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item>
        <Link to={ACCOUNT} component={NavLink} underline="hover" activeStyle={activeStyle}>
          Home
        </Link>
      </Grid>
      <Grid item>
        <Link to={ACCOUNT_PROFILE} component={NavLink} underline="hover" activeStyle={activeStyle}>
          Account
        </Link>
      </Grid>
      <Grid item>
        <Link
          to={ACCOUNT_SUBSCRIPTION}
          component={NavLink}
          underline="hover"
          activeStyle={activeStyle}
        >
          Subscription
        </Link>
      </Grid>
      <Grid item>
        <Link
          to={ACCOUNT_REFERRALS}
          component={NavLink}
          underline="hover"
          activeStyle={activeStyle}
        >
          Referrals
        </Link>
      </Grid>
      <Grid item>
        <Link to={ACCOUNT_MESSAGES} component={NavLink} underline="hover" activeStyle={activeStyle}>
          Messages
        </Link>
      </Grid>
      <Grid item>
        <Link
          to={ACCOUNT_THIRD_PARTY}
          component={NavLink}
          underline="hover"
          activeStyle={activeStyle}
        >
          Integrations
        </Link>
      </Grid>
      <Grid item>
        <Link to={ACCOUNT_DOMAINS} component={NavLink} underline="hover" activeStyle={activeStyle}>
          Domains
        </Link>
      </Grid>
      <Grid item>
        <Link to={LOGIN} component={NavLink} onClick={logoutUser} underline="hover">
          Sign Out
        </Link>
      </Grid>
    </Grid>

const Navbar = ({ toggleDrawerVisibility }) => {
  const isDesktop = useMediaQuery('(min-width:960px)');
  const styles = {
    img: {
      width: isDesktop ? '130px' : '35px',
      height: 'auto',
    },
    iconButton: { marginRight: '-10px' },
    menu: {
      fontSize: '35px',
    },
  };

  return (
    <AppBar position="sticky" sx={{ paddingTop: '1em' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'center', md: 'flex-end' }}
      >
        <Link component={NavLink} to={ACCOUNT} underline="hover" activeStyle={activeStyle}>
          <img
            style={styles.img}
            src={isDesktop ? ShowitLogo : ShowitLogoNoText}
            alt="Showit Logo"
          />
        </Link>
        <Hidden mdDown>
          <MenuBar />
        </Hidden>
        <Hidden mdUp>
          <IconButton
            sx={styles.iconButton}
            edge="end"
            aria-label="menu"
            onClick={() => toggleDrawerVisibility()}
            size="lg"
          >
            <Menu sx={styles.menu} />
          </IconButton>
        </Hidden>
      </Box>
    </AppBar>
  );
};

Navbar.propTypes = {
  toggleDrawerVisibility: PropTypes.func,
};

Navbar.defaultProps = {
  toggleDrawerVisibility: null,
};

export default memo(Navbar);

import { useQuery } from 'react-query';
import { CONTRIBUTOR_ACCESS, DEFAULT_QUERY_SETTINGS } from '../constants/queryData';
import { getContributorAccess } from '../api';

export default function useContributorAccess() {
  const { data, ...rest } = useQuery(
    CONTRIBUTOR_ACCESS,
    getContributorAccess,
    DEFAULT_QUERY_SETTINGS
  );
  return {
    ...(data || {
      contributors: [],
      accessibleAccounts: [],
    }),
    ...rest,
  };
}

import React from 'react';
import { render } from 'react-dom';
import * as session from './api/session';

import App from './components/app';

function renderApp() {
  render(<App />, document.getElementById('root'));
}

session.set({
  token: localStorage.getItem('sessionToken'),
  tokenExpires: localStorage.getItem('sessionExpires'),
});

renderApp();

if (module.hot) {
  module.hot.accept(renderApp);
}

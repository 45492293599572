import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, List, ListItem, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ChatIcon from '@mui/icons-material/Chat';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PowerIcon from '@mui/icons-material/Power';
import SecurityIcon from '@mui/icons-material/Security';
import NotificationIcon from '@mui/icons-material/Notifications';
import { SubTitle } from '../../../common';
import {
  ACCOUNT_MESSAGES,
  ACCOUNT_NOTIFICATIONS,
  ACCOUNT_PROFILE,
  ACCOUNT_SECURITY,
  ACCOUNT_SUBSCRIPTION,
  ACCOUNT_THIRD_PARTY,
} from '../../../../constants/routePaths';
import { palette } from '../../../../theme';

const links = [
  { to: ACCOUNT_PROFILE, Icon: PersonIcon, text: 'Account Settings' },
  { to: ACCOUNT_SUBSCRIPTION, Icon: AutorenewIcon, text: 'Subscription' },
  { to: ACCOUNT_SECURITY, Icon: SecurityIcon, text: 'Security Settings' },
  { to: ACCOUNT_MESSAGES, Icon: ChatIcon, text: 'Contact Form Messages' },
  { to: ACCOUNT_THIRD_PARTY, Icon: PowerIcon, text: 'Third-Party Integrations' },
  { to: ACCOUNT_NOTIFICATIONS, Icon: NotificationIcon, text: 'Notification Settings' },
];

const AccountLink = ({ Icon, text, to }) => (
  <Link to={to}>
    <Box
      display="flex"
      alignItems="center"
      color={palette.primary.main}
      sx={{
        '&:hover': { color: palette.secondary.main },
      }}
    >
      <Icon fontSize="medium" sx={{ marginRight: '.25em' }} />
      <Typography variant="body2" fontSize="1.1em" color="inherit">
        {text}
      </Typography>
    </Box>
  </Link>
);

const containerDesktopStyles = {
  borderRight: `1px solid ${palette.primary.main}`,
};
const containerMobileStyles = {
  borderTop: `1px solid ${palette.primary.main}`,
  padding: '1em 2em 1em 0',
};

const AccountLinks = ({ isDesktop = false }) => (
  <Box flex="1" sx={isDesktop ? containerDesktopStyles : containerMobileStyles} pb={3}>
    <SubTitle homeHeader style={{ textAlign: 'left' }} mb={2}>
      Manage Your Account
    </SubTitle>
    <List disablePadding={!isDesktop}>
      {links.map(({ to, Icon, text }) => (
        <ListItem key={to}>
          <AccountLink to={to} Icon={Icon} text={text} />
        </ListItem>
      ))}
    </List>
  </Box>
);

AccountLinks.defaultProps = {
  isDesktop: false,
};

AccountLinks.propTypes = {
  isDesktop: PropTypes.bool,
};

AccountLink.propTypes = {
  Icon: PropTypes.oneOf([
    PersonIcon,
    ChatIcon,
    AutorenewIcon,
    PowerIcon,
    SecurityIcon,
    NotificationIcon,
  ]).isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default AccountLinks;

import { useQuery } from 'react-query';
import { getReferrals } from '../api';
import { REFERRALS } from '../constants/queryData';
import { PAGINATION_LIMIT } from '../constants';

/**
 * Returns referral records with the applied filter
 * @param {Array} status An array of referral statuses by which to filter by
 * @param {number} offset Used for pagination, default to 0
 * @returns {{ referrals: Array, total: number, ...reactQueryProps }}
 */
export default function useReferrals({ status, offset }, settings = {}) {
  const { data, ...rest } = useQuery(
    [REFERRALS, status, offset],
    () => getReferrals({ status, offset, limit: PAGINATION_LIMIT }),
    {
      keepPreviousData: true,
      ...settings,
    }
  );
  const { referrals = [], total } = data || {};
  return { referrals, total, ...rest };
}

import { authorized, notAuthorized, refreshSession } from './requestor';
import { set } from './session';

const setOwnerId = (ownerId) => (ownerId ? { params: { ownerId } } : {});
const catch404 = (error) => (error?.response?.status === 404 ? {} : Promise.reject(error));

const sso = refreshSession;
const login = async (credentials) =>
  notAuthorized({
    method: 'POST',
    url: '/login',
    data: credentials,
  }).then(({ data }) => {
    const { token, tokenExpires, ...rest } = data;

    set({ token, tokenExpires });

    return rest;
  });

const signup = async (payload) => notAuthorized.post('/signup', payload).then(({ data }) => data);
const create = async (data) => notAuthorized.post('/create', data);
const forgotPassword = async (data) => notAuthorized.post('/password/forgot', data);

// maxmind bypass
const getSignupData = async (token) =>
  notAuthorized({
    method: 'GET',
    url: '/signup',
    headers: { Authorization: `Bearer ${token}` },
  }).then(({ data }) => data);
const createSecure = async (token, data) =>
  notAuthorized({
    method: 'POST',
    url: '/create/bypass',
    headers: { Authorization: `Bearer ${token}` },
    data,
  });

const sessionVerify = async (token) =>
  notAuthorized({
    method: 'GET',
    url: '/session/verify',
    headers: { Authorization: `Bearer ${token}` },
  }).then(({ data }) => data);

const resetPassword = async (token, data) =>
  notAuthorized({
    method: 'POST',
    url: '/password/reset',
    headers: { Authorization: `Bearer ${token}` },
    data,
  });

const emailConfirmation = async (token) =>
  notAuthorized({
    method: 'GET',
    url: '/email/confirmation',
    headers: { Authorization: `Bearer ${token}` },
  });

const logout = async () =>
  authorized({
    method: 'GET',
    url: '/logout',
    transformResponse: [() => set()],
  });

const getAccount = async () => authorized.get('/account').then(({ data }) => data);
const getAppLogin = async () => authorized.get('/login/app').then(({ data }) => data);
const getContributorAppLogin = async (ownerUserId) =>
  authorized.post('/login/app/contributor', { ownerUserId }).then(({ data }) => data);
const updateProfile = async (updates) =>
  authorized.post('/profile', updates).then(({ data }) => data);
const updatePrimaryEmail = async (updates) =>
  authorized.post('/email/update', updates).then(({ data }) => data);
const updatePassword = async (data) => authorized.post('/password/update', data);

const getContactMethods = async () => authorized.get('/contactmethods').then(({ data }) => data);
const addContactMethod = async (method) => authorized.post('/contactmethods', method);
const updateContactMethod = async (method) => {
  const { type, id } = method;
  return authorized.put(`/contactmethods/${type}/${id}`, method);
};
const deleteContactMethod = async ({ type, id }) =>
  authorized.delete(`/contactmethods/${type}/${id}`);
const confirmContactMethod = async (data) => authorized.post('/contactmethods/confirm', data);
const resendConfirmation = async (method) => {
  const { type, id } = method;
  return authorized.put(`/contactmethods/${type}/${id}`, method);
};

const defaultParams = { offset: 0, limit: 10 };
const getMessages = async (params = defaultParams) =>
  authorized.get('/messages', { params: { ...defaultParams, ...params } }).then(({ data }) => data);
const getMessageContent = async (messageId) =>
  authorized.get(`/messages/${messageId}`).then(({ data }) => data);

const getSubscriptionStatus = async () => authorized.get('/subscription').then(({ data }) => data);
const getChargebeePortalSession = async () =>
  authorized.post('/integrations/chargebee/portal', {}).then(({ data }) => data);
const getChargebeeCheckout = async ({ planId, discount }) =>
  authorized
    .post('/integrations/chargebee/checkout/open', {
      planId,
      code: discount.code,
      codeType: discount.type,
    })
    .then(({ data }) => data);
const completeChargebeeCheckout = async (hostedPageId) =>
  authorized
    .post('/integrations/chargebee/checkout/close', { hostedPageId })
    .then(({ data }) => data);

const syncChargebeeSubscription = async (userId) =>
  authorized.post('/integrations/chargebee/subscription/sync', { userId }).then(({ data }) => data);

// Entri DNS
export const getEntriAuthToken = async () =>
  authorized.get('/integrations/entri/token').then(({ data }) => data);
/**
 * @param {{
 *  domainId: number, // root domain id
 *  dnsIds: number[],
 * }} payload
 */
export const finishEntriConfiguration = async ({ userId, ...payload }) =>
  authorized
    .post('/integrations/entri/complete', payload, setOwnerId(userId))
    .then(({ data }) => data);

// Social Grid
const getThirdPartyAccounts = async () =>
  authorized.get('/socialgrid/sources').then(({ data }) => data);
const getInstagramConnectionLink = async () =>
  authorized.get('/socialgrid/new/instagram').then(({ data }) => data);
const finalizeInstagramConnection = async ({ code, state }) =>
  authorized.post('/socialgrid/finalize', { code, state }).then(({ data }) => data);
const updateInstagramConnection = async (update) =>
  authorized.patch(`/socialgrid/source/${update.integrationId}`, update).then(({ data }) => data);
const getInstagramDeletionStatus = async ({ provider, confirmationCode }) =>
  notAuthorized
    .get(`/userintegration/status/${provider}/${confirmationCode}`)
    .then(({ data }) => data);

// Contributor Access
const getContributorAccess = async () =>
  authorized.get('/contributor-access').then(({ data }) => data);
const addContributor = async (emailAddress) =>
  authorized.post('/contributor-access', { emailAddress }).then(({ data }) => data);
const updateContributor = async ({ id, status }) =>
  authorized.patch(`/contributor-access/${id}`, { status }).then(({ data }) => data);
const removeContributor = async ({ id }) =>
  authorized.delete(`/contributor-access/${id}`).then(({ data }) => data);

// Referral/Affiliate Program
// As a referrer, get/accept/patch my referral code and get my referrals
const getReferralCode = async () =>
  authorized
    .get('/referral-code')
    .then(({ data }) => data)
    .catch(catch404);
const acceptReferralTos = async ({ acceptedReferralTos }) =>
  authorized.post('/referral-code', { acceptedReferralTos }).then(({ data }) => data);
const updateReferralCode = async ({ referralCodeId, code }) =>
  authorized.patch(`/referral-code/${referralCodeId}`, { code }).then(({ data }) => data);
export const getReferrals = async ({ status, limit = 25, offset = 0 }) =>
  authorized
    .get(`/referrals`, {
      params: { status: status.join(','), limit, offset },
    })
    .then(({ data }) => data);

// As a referee, get/post/delete my referral record
const getReferral = async () =>
  authorized
    .get('/referral')
    .then(({ data }) => data)
    .catch(catch404);
const addReferral = async (code) => authorized.post('/referral', { code }).then(({ data }) => data);
const deleteReferral = async (referralId) =>
  authorized.delete(`/referral/${referralId}`).then(({ data }) => data);
export const getReferralStats = async () =>
  authorized.get('/referral-stats').then(({ data }) => data);
export const getAffiliateTransactions = async ({ offset, limit }) =>
  authorized.get('/affiliate-transactions', { params: { offset, limit } }).then(({ data }) => data);

const verifyDiscountCode = async (code) =>
  authorized.post('/verify-discount-code', { code }).then(({ data }) => data);

// PayPal
export const getPayPalAuthUrl = async () =>
  authorized.get('/integrations/paypal/auth-url').then(({ data }) => data.authUrl);
export const syncPayPalUser = async (authorizationCode) =>
  authorized
    .post('/integrations/paypal/user/sync', {
      authorizationCode,
    })
    .then(({ data }) => data);

// User Payout Methods (only PayPal for now)
export const getUserPayoutMethod = async () =>
  authorized.get('/user-payout-methods').then(({ data }) => data);
export const deletePayoutMethod = async (payoutMethodId) =>
  authorized.delete(`/user-payout-methods/${payoutMethodId}`).then(({ data }) => data);

const getAccountDomains = async (ownerId) =>
  authorized.get('/domains', setOwnerId(ownerId)).then(({ data }) => data);
export const getAccountDNS = async (ownerId) =>
  authorized.get('/dns', setOwnerId(ownerId)).then(({ data }) => data);
export const getAccountDomainsStatus = async (ownerId) =>
  authorized.get('/account/domains-status', setOwnerId(ownerId)).then(({ data }) => data);

const getDomainStatus = async (domainId) =>
  authorized.get(`/domains/${domainId}/status`).then(({ data }) => data);
const getDomainDNSRecords = async (domainId) =>
  authorized.get(`/domains/${domainId}/dns`).then(({ data }) => data);

const scanDNS = async (domainId, ownerId) =>
  authorized.get(`/domains/${domainId}/scan-dns`, setOwnerId(ownerId)).then(({ data }) => data);

const siteCheck = async (domainId) =>
  authorized.get(`/domains/${domainId}/check-status`).then(({ data }) => data);

const getBlogs = async (ownerId) =>
  authorized.get('/blogs', setOwnerId(ownerId)).then(({ data }) => data);

export {
  sso,
  login,
  signup,
  create,
  getSignupData,
  createSecure,
  forgotPassword,
  sessionVerify,
  resetPassword,
  emailConfirmation,
  logout,
  getAccount,
  getAppLogin,
  getContributorAppLogin,
  updateProfile,
  updatePrimaryEmail,
  updatePassword,
  getContactMethods,
  addContactMethod,
  updateContactMethod,
  deleteContactMethod,
  confirmContactMethod,
  resendConfirmation,
  getMessages,
  getMessageContent,
  getThirdPartyAccounts,
  getInstagramConnectionLink,
  finalizeInstagramConnection,
  updateInstagramConnection,
  getInstagramDeletionStatus,
  getSubscriptionStatus,
  getChargebeePortalSession,
  getChargebeeCheckout,
  completeChargebeeCheckout,
  syncChargebeeSubscription,
  getContributorAccess,
  addContributor,
  updateContributor,
  removeContributor,
  getReferralCode,
  acceptReferralTos,
  updateReferralCode,
  getReferral,
  addReferral,
  deleteReferral,
  verifyDiscountCode,
  getAccountDomains,
  getDomainDNSRecords,
  scanDNS,
  siteCheck,
  getDomainStatus,
  getBlogs,
};

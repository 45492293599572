import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { INACTIVE, TRIAL, TRIAL_ENDED } from '../../../../constants/accountStatuses';
import { user as UserType } from '../../../../domain/PropTypes';
import AccountInTrial from './AccountInTrial';
import AccountInactive from './AccountInactive';
import AccountTrialEnded from './AccountTrialEnded';
import theme, { palette } from '../../../../theme';
import { SubTitle } from '../../../common';

export const AccountNoticeMap = {
  [INACTIVE]: AccountInactive,
  [TRIAL]: AccountInTrial,
  [TRIAL_ENDED]: AccountTrialEnded,
};

const AccountStatusContainer = styled(Box)`
  flex: 1;
  ${theme.breakpoints.down('md')} {
    border-bottom: 1px solid ${palette.primary.main};
    margin-bottom: 1em;
  }
  ${theme.breakpoints.up('md')} {
    border-left: 1px solid ${palette.primary.main};
  }
`;

const AccountStatusNotice = ({ user }) => {
  const { status } = user;
  const NoticeComp = AccountNoticeMap[status];

  if (!NoticeComp) return null;

  return (
    <AccountStatusContainer px={{ xs: 0, md: 3 }}>
      <SubTitle style={{ textAlign: 'left' }} mb={2} mt={{ xs: 3, md: 0 }}>
        Account Status
      </SubTitle>
      <NoticeComp user={user} />
    </AccountStatusContainer>
  );
};

AccountStatusNotice.propTypes = {
  user: UserType.isRequired,
};

export default AccountStatusNotice;

import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { When } from '../../../common';
import { palette } from '../../../../theme';
import referralStatuses from '../../../../constants/referralStatuses';

const Referrals = ({ referrals = [] }) => (
  <Box>
    {referrals.map((referral) => (
      <Box
        key={referral.id}
        display="flex"
        justifyContent="space-between"
        borderBottom={`1px dashed ${palette.background.default}`}
        py={1}
      >
        <Typography variant="body2">{referral.name}</Typography>
        <Typography variant="body2">
          <When it={referral.status === referralStatuses.QUALIFYING}>
            <span style={{ color: palette.mediumGrey, fontStyle: 'italic' }}>
              Pending first subscription payment
            </span>
          </When>
          <When it={referral.status === referralStatuses.APPLYING_CREDIT}>
            <span style={{ color: palette.success.main }}>Applying credit...</span>
          </When>
          <When it={referral.status === referralStatuses.CREDIT_APPLIED}>
            <span style={{ color: palette.success.main }}>
              Monthly credit applied {format(new Date(referral.lastUpdate), 'MMM dd, yyyy')}
            </span>
          </When>
          <When it={referral.status === referralStatuses.DISQUALIFIED}>
            <span style={{ color: palette.error.main }}>Subscription cancelled</span>
          </When>
        </Typography>
      </Box>
    ))}
    {referrals.length === 0 && (
      <Typography variant="body2" color={palette.darkGrey} fontStyle="italic" ml={1} mt={1}>
        No data available
      </Typography>
    )}
  </Box>
);

Referrals.propTypes = {
  referrals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string || null,
      status: PropTypes.number,
      lastUpdate: PropTypes.string,
    })
  ),
};
Referrals.defaultProps = {
  referrals: [],
};

export default Referrals;

/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { ACCOUNT_SUBSCRIBE } from '../../constants/routePaths';

const SubscribeLink = ({ children = 'Subscribe', to = ACCOUNT_SUBSCRIBE, ...props }) => (
  <Link to={to} component={RouterLink} underline="hover" {...props}>
    {children}
  </Link>
);

SubscribeLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

export default SubscribeLink;

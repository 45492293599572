import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { When } from '../../../common';
import WordpressIcon from '../../../common/Icons/WordpressIcon';
import { DragAndDropIcon } from '../../../common/Icons';

const ContributorLink = forwardRef((props, ref) => {
  const { app, wp, ...rest } = props;
  const text = app ? 'Design App' : wp ? 'Blog' : '';
  const className = app ? ' designApp' : wp ? ' wp' : '';
  const icon = app ? <DragAndDropIcon /> : wp ? <WordpressIcon /> : null;

  return (
    <When it={text?.length > 0}>
      <Button
        startIcon={icon}
        ref={ref}
        className={`contributorLink${className}`}
        {...rest}
        underline="hover"
        variant="text"
      >
        <Box display="flex" alignItems="center">
          {text}&nbsp;
        </Box>
      </Button>
    </When>
  );
});

ContributorLink.displayName = 'ContributorLink';

ContributorLink.propTypes = {
  app: PropTypes.bool,
  wp: PropTypes.bool,
};

ContributorLink.defaultProps = {
  app: false,
  wp: false,
};

export default ContributorLink;

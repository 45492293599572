import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import usePayoutMethod from '../../../hooks/usePayoutMethod';
import PayPalEmail from './PayPalEmail';

const PayoutMethod = () => {
  const { userPayoutMethod, isSuccess, isError } = usePayoutMethod();
  const { email, userPayoutMethodId } = userPayoutMethod;
  return (
    <Box>
      {isError && (
        <Typography variant="body2" color="error" textAlign="center" my={1}>
          Failed to retrieve your payout method
        </Typography>
      )}
      {isSuccess && (
        <Box my={1}>
          <PayPalEmail email={email} userPayoutMethodId={userPayoutMethodId} />
        </Box>
      )}
    </Box>
  );
};

export default PayoutMethod;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Dialog, List, ListItem } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '../../../common';
import phoneUtil from '../../../../utils/phoneNumber';
import { palette } from '../../../../theme';

const DialogButton = styled(Button)`
  width: 100%;
  min-width: 215px;
  line-height: 1;
  &.MuiButton-secondary {
    justify-content: flex-start;
    padding: 0 10% 0;
    whitespace: nowrap;
    border: 1px solid ${palette.primary.main};
  }
`;

const DeleteDialog = ({ type, value, open, onClose, confirmDelete }) => {
  const number = useMemo(() => phoneUtil.getRegionFormat(phoneUtil.parse(value)), [value]);
  const titleText = `Delete ${type === 'sms' ? 'SMS' : type} number ${number}?`;
  const contentText = `This will remove ${number} from your notifications. You will be required to go through the setup process again to add ${number} to your notifications. Do you want to Continue?`;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions flex="1" px={{ xs: 0, md: 2 }} mb={3}>
        <List sx={{ width: '100%', paddingTop: 0 }}>
          <ListItem>
            <DialogButton
              onClick={() => {
                confirmDelete();
                onClose();
              }}
            >
              Yes, remove it.
            </DialogButton>
          </ListItem>
          <ListItem>
            <DialogButton variant="text" onClick={onClose}>
              No, keep it.
            </DialogButton>
          </ListItem>
        </List>
      </DialogActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
};

export default DeleteDialog;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, Typography } from '@mui/material';
import styled from '@emotion/styled';

import { Button, Instruction, ServicePage, SubTitle } from '../common';
import PageContent from '../common/PageContent';
import OuterCardLogo from '../common/OuterCardLogo';
import { palette } from '../../theme';
import { LOGIN } from '../../constants/routePaths';

const SuccessCard = styled(Card)`
  background-color: ${palette.black};

  p,
  p a,
  .CardTitle {
    color: ${palette.background.default};
    text-align: center;
  }

  p,
  .CardSubtitle {
    font-weight: 300;
  }

  .CardTitle {
    font-size: 1.5rem;
    margin-top: 1em;
  }

  .CardSubtitle {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 1em;
  }

  p {
    & a {
      text-decoration-color: ${palette.background.default};
    }
    margin-bottom: 1.5em;
  }
`;

const Success = () => {
  const history = useHistory();

  return (
    <ServicePage>
      <PageContent maxWidth="xs">
        <OuterCardLogo />
        <SuccessCard>
          <SubTitle cardTitle className="CardTitle">
            Welcome to the club!
          </SubTitle>
          <Typography className="CardSubtitle">
            Your new Showit account has been created successfully
          </Typography>
          <Box>
            <Instruction>
              Now you can sign in to Showit and start creating the website you&apos;ve been dreaming
              of
            </Instruction>
            <Button
              onClick={() => history.push(LOGIN)}
              fullWidth
              underline="none"
              variant="inverted"
            >
              Sign In
            </Button>
          </Box>
        </SuccessCard>
      </PageContent>
    </ServicePage>
  );
};

export default Success;

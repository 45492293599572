import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { formatDistance } from 'date-fns';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import { Box, Snackbar, Divider, Stack, Typography, Button as MUIButton } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useHistory } from 'react-router-dom';
import { SubTitle, Instruction, TextInput, When, Alert, Button } from '../../../common';
import { CONTRIBUTOR_ACCESS } from '../../../../constants/queryData';
import { ACTIVE } from '../../../../constants/accountStatuses';
import { user as UserType } from '../../../../domain/PropTypes';
import { yup } from '../../../../utils';
import {
  addContributor,
  updateContributor,
  removeContributor,
  getContributorAppLogin,
} from '../../../../api';
import { ADV_BLOG, BASIC_BLOG } from '../../../../constants/blogTypes';
import WPLoginBtn from './WPLoginBtn';
import Links from '../../../../constants/links';
import { contributorStatuses } from '../../../../constants';
import useBrowserInfo from '../../../../hooks/useBrowserInfo';
import config from '../../../../config';
import CustomTooltip from '../../../common/Tooltip/Tooltip';
import { ContributorList, NameRow, Subheader } from './Styled';
import ContributorButtons from './ContributorButtons';
import SnackbarAlert from './SnackBarAlert';
import ContributorLink from './ContributorLink';
import { ACCOUNT_DOMAINS } from '../../../../constants/routePaths';
import useContributorAccess from '../../../../hooks/useContributorAccess';

const validateEmail = (newShareEmail) =>
  yup
    .createSchema({
      emailAddress: yup.emailAddress,
    })
    .isValid({ emailAddress: newShareEmail });

const getTimeDifference = (time) => formatDistance(new Date(time), new Date(), { addSuffix: true });

const ContributorAccess = ({ user }) => {
  const [newShareEmail, setNewShareEmail] = useState('');
  const [validShareEmail, setValidShareEmail] = useState(false);
  const [contributorStatus, setContributorStatus] = useState({ status: 'idle', message: '' });
  const { designAppSupported } = useBrowserInfo();
  const queryClient = useQueryClient();

  const { mutate: getAppUrl } = useMutation(getContributorAppLogin, {
    onSuccess: (data) => {
      window.open(`${config.DESIGN_APP_URL}?admin_token=${data.token}`, '_blank');
    },
    onError: () => {
      setContributorStatus({
        status: 'error',
        message: 'Unauthorized',
      });
      queryClient.invalidateQueries(CONTRIBUTOR_ACCESS);
    },
  });

  // Get contributor access
  const { contributors = [], accessibleAccounts = [], isLoading, isError } = useContributorAccess();

  // Add a new contributor
  const { mutate: addContributorAccess } = useMutation((email) => addContributor(email), {
    onSuccess: (res, email) => {
      queryClient.invalidateQueries([CONTRIBUTOR_ACCESS]);
      setNewShareEmail('');
      setContributorStatus({
        status: 'success',
        message: `Successfully shared contributor access with ${email}`,
      });
    },
    onError: (err) => {
      setContributorStatus({
        status: 'error',
        message: err.response.data.errors
          ? err.response.data.errors[0].message
          : 'Error sharing contributor access',
      });
    },
  });

  const onAddContributorAccess = () => {
    const tryingToAddSelf = newShareEmail.toLowerCase() === user.emailAddress;
    const userAlreadyHasAccess = !!contributors.find(
      ({ contributorName }) => newShareEmail.toLowerCase() === contributorName
    );

    if (tryingToAddSelf || userAlreadyHasAccess) {
      setNewShareEmail('');
      setContributorStatus({
        status: 'error',
        message: tryingToAddSelf
          ? 'You cannot grant yourself contributor access'
          : 'User is already a contributor, click the resume access button to resume access if it has been paused',
      });
      return;
    }

    addContributorAccess(newShareEmail);
  };

  // Disconnect a contributor
  const { mutate: updateContributorAccess } = useMutation(
    (contributor) => updateContributor(contributor),
    {
      onSuccess: (res, { status, contributorName }) => {
        queryClient.invalidateQueries([CONTRIBUTOR_ACCESS]);
        setContributorStatus({
          status: 'success',
          message: `Successfully ${
            status === 1 ? 'resumed' : 'paused'
          } contributor access for ${contributorName}`,
        });
      },
      onError: (err) => {
        setContributorStatus({
          status: 'error',
          message: err.message,
        });
      },
    }
  );

  // Disconnect a contributor
  const { mutate: removeContributorAccess } = useMutation((id) => removeContributor({ id }), {
    onSuccess: () => {
      queryClient.invalidateQueries([CONTRIBUTOR_ACCESS]);
      setContributorStatus({
        status: 'success',
        message: `Successfully removed contributor access`,
      });
    },
    onError: (err) => {
      setContributorStatus({
        status: 'error',
        message: err.message,
      });
    },
  });

  useEffect(() => {
    validateEmail(newShareEmail).then((valid) => setValidShareEmail(valid));
  }, [newShareEmail]);

  const history = useHistory();

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="column">
        <SubTitle my={2} width="100%">
          Contributor Access
        </SubTitle>
        <Typography variant="p" mb={2}>
          Invite additional Showit users or designers to contribute and/or manage your website
          (requires all users to have an active Showit Subscription). Adding access for a user will
          give them permission to make design changes to any of your site designs (including
          irreversible changes) through the Showit design app.
          <br />
          <Link
            target="_blank"
            href={Links.CONTRIBUTOR_ACCESS_LEARN_URL}
            title="Learn More"
            underline="always"
          >
            Learn more about Contributor Access.
          </Link>
        </Typography>
        <When it={user.status === ACTIVE && !!accessibleAccounts.length}>
          <ContributorList
            disablePadding
            dense
            subheader={
              <Subheader component="div" className="subheader">
                Shared With You
              </Subheader>
            }
          >
            {accessibleAccounts.map((account) => {
              const onRemove = () => updateContributorAccess({ id: account.id, status: 2 });
              const name = `${account.firstName} ${account.lastName}`;
              const shareTime = `( ${account.ownerEmail} ) - Shared ${getTimeDifference(
                account.lastUpdate
              )}`;
              return (
                <ListItem className="contributor" key={account.id}>
                  <>
                    <Box className="content">
                      <Box className="nameAndButtonRow">
                        <NameRow className="nameRow">
                          <Typography className="name">{name}</Typography>
                          <Typography className="shareTime">{shareTime}</Typography>
                        </NameRow>
                        <ContributorButtons className="mobile" onRemove={onRemove} />
                        <ContributorButtons className="desktop" onRemove={onRemove} />
                      </Box>
                      <Stack className="appButtons" spacing={1} direction="row">
                        {designAppSupported ? (
                          <ContributorLink app onClick={() => getAppUrl(account.ownerUserId)} />
                        ) : (
                          <CustomTooltip
                            placement="top"
                            arrow
                            disableFocusListener
                            disableTouchListener
                            title="Chrome Required"
                          >
                            <span>
                              <ContributorLink app disabled />
                            </span>
                          </CustomTooltip>
                        )}
                        {(account.blogType === BASIC_BLOG || account.blogType === ADV_BLOG) &&
                          account.blogDomainName && <WPLoginBtn {...account} />}
                        <>
                          <MUIButton
                            underline="hover"
                            onClick={() =>
                              history.push(ACCOUNT_DOMAINS, { ownerId: account.ownerUserId })
                            }
                          >
                            <LanguageIcon />
                            <span style={{ width: '5px' }} />
                            DNS
                          </MUIButton>
                        </>
                      </Stack>
                    </Box>
                  </>
                </ListItem>
              );
            })}
          </ContributorList>
        </When>
        <When it={user.status === ACTIVE && !!accessibleAccounts.length && !!contributors.length}>
          <Divider variant="middle" />
        </When>
        <When it={!!contributors.length && user.status === ACTIVE}>
          <ContributorList
            dense
            disablePadding
            subheader={
              <Subheader component="div" className="subheader">
                Sharing Access With
              </Subheader>
            }
          >
            {contributors.map((contributor) => {
              const isActive = contributor.status === contributorStatuses.ACTIVE;
              const onPause = () =>
                updateContributorAccess({
                  ...contributor,
                  status: isActive ? contributorStatuses.INACTIVE : contributorStatuses.ACTIVE,
                });
              const onRemove = () => removeContributorAccess(contributor.id);
              const shareTime = `- ${isActive ? 'Shared' : 'Paused'} ${getTimeDifference(
                contributor.lastUpdate
              )}`;

              return (
                <ListItem className="contributor" key={`sharedTo${contributor.id}`}>
                  <>
                    <Box className="content">
                      <Box className="nameAndButtonRow">
                        <NameRow>
                          <Typography className={`name${isActive ? ' active' : ' inactive'}`}>
                            {contributor.contributorName}
                          </Typography>
                          <Typography className="shareTime">{shareTime}</Typography>
                        </NameRow>
                        <ContributorButtons
                          isActive={isActive}
                          className="mobile"
                          onPause={onPause}
                          onRemove={onRemove}
                          pauseable
                        />
                      </Box>
                      <When it={user.blogType === BASIC_BLOG}>
                        <Typography className="blogInfo">
                          Contributor will also have access to your blog admin
                        </Typography>
                      </When>
                      <When it={user.blogType === ADV_BLOG}>
                        <Link
                          target="_blank"
                          className="blogInfo"
                          href={Links.SHARING_ADV_BLOG_ACCESS_LEARN_URL}
                          title="Learn how to share blog admin access"
                          underline="hover"
                        >
                          Learn how to share blog admin access.
                        </Link>
                      </When>
                    </Box>
                    <ContributorButtons
                      isActive={isActive}
                      className="desktop"
                      onPause={onPause}
                      onRemove={onRemove}
                      pauseable
                    />
                  </>
                </ListItem>
              );
            })}
          </ContributorList>
        </When>
        <When it={!isLoading && isError}>
          <Alert severity="error">Error retrieving accounts with design access</Alert>
        </When>
      </Box>

      <When it={user.status === ACTIVE}>
        <Box
          title={
            !newShareEmail
              ? "Enter an Showit user's email to share design access with."
              : !validShareEmail
              ? 'Enter a valid email address'
              : `Share design access with ${newShareEmail}`
          }
        >
          <TextInput
            type="email"
            label="Email"
            variant="outlined"
            disabled={user.status !== ACTIVE}
            name="setNewShareEmail"
            value={newShareEmail}
            onKeyDown={(e) => e.key === 'Enter' && onAddContributorAccess()}
            onChange={({ target }) => setNewShareEmail(target.value)}
          />
          <Button disabled={!validShareEmail} type="button" onClick={onAddContributorAccess}>
            Share Design Access
          </Button>
        </Box>
      </When>
      <When it={user.status !== ACTIVE}>
        <Box>
          <Instruction>This feature is only available to active subscribers</Instruction>
        </Box>
      </When>
      <Snackbar
        open={contributorStatus.status !== 'idle'}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setContributorStatus({
            status: 'idle',
            message: '',
          });
        }}
      >
        <SnackbarAlert contributorStatus={contributorStatus} />
      </Snackbar>
    </>
  );
};

ContributorAccess.propTypes = {
  user: UserType.isRequired,
};

export default ContributorAccess;

import React, { useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';

import phoneUtil from '../../../../utils/phoneNumber';
import { Button } from '../../../common';
import CountrySelect from '../../../common/CountrySelect';
import { addContactMethod } from '../../../../api';
import { CONTACTMETHODS } from '../../../../constants/queryData';
import { yup, createHandleError, buildSubmitHandler } from '../../../../utils';

const defaultValues = {
  country: 'US',
  phoneNumber: '',
};

const handleError = createHandleError('phoneNumber');

const AddNumberForm = ({ initialValues }) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    ({ values: { country, phoneNumber }, actions }) => {
      const number = phoneUtil.parse(phoneNumber, country);
      const e164 = phoneUtil.getE164Format(number);
      return addContactMethod({
        type: 'sms',
        value: e164,
      }).then(({ data: newContactMethod }) => ({ newContactMethod, actions }));
    },
    {
      onSuccess: ({ newContactMethod, actions }) => {
        actions.setSubmitting(false);
        actions.resetForm();
        queryClient.setQueryData(CONTACTMETHODS, (cache) => [newContactMethod, ...cache]);
      },
      onError: handleError,
    }
  );

  const phoneNumberRef = useRef();

  const { errors, values, touched, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: initialValues || defaultValues,
    validationSchema: yup.createSchema({ country: yup.country, phoneNumber: yup.phoneNumber }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: buildSubmitHandler(mutate),
  });

  useEffect(() => {
    if (errors.phoneNumber) {
      phoneNumberRef.current.focus();
    }
  }, [errors]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <CountrySelect
          name="country"
          label="Country"
          value={values.country}
          onChange={handleChange}
        />
        <TextField
          inputRef={phoneNumberRef}
          variant="standard"
          name="phoneNumber"
          label="Phone Number"
          value={values.phoneNumber}
          onChange={handleChange}
          error={touched.phoneNumber && !!errors.phoneNumber}
          helperText={touched.phoneNumber && errors.phoneNumber}
        />
        <Box height="1rem" />
        <Button type="submit" variant="contained" loading={isSubmitting} disabled={isSubmitting}>
          Send Confirmation Code
        </Button>
      </form>
    </Box>
  );
};

AddNumberForm.propTypes = {
  initialValues: PropTypes.shape({
    country: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  }),
};

AddNumberForm.defaultProps = {
  initialValues: defaultValues,
};

export default AddNumberForm;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled, Tooltip, tooltipClasses } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.tertiary.main,
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.tertiary.main,
  },
}));

export default CustomTooltip;

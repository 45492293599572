import React, { useState } from 'react';
import { Container, Box, Pagination, Skeleton, Button } from '@mui/material';
import { useQuery } from 'react-query';
import NotificationIcon from '@mui/icons-material/Notifications';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { getMessages } from '../../../api';
import { ACCOUNT_NOTIFICATIONS } from '../../../constants/routePaths';
import { Card, PageTitle } from '../../common';
import { MESSAGES } from '../../../constants/queryData';
import Messages from '../../common/Messages/Messages';

const MESSAGES_PER_PAGE = 10;

const NotificationBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const MessagesSkeleton = () =>
  [1, 2, 3, 4, 5, 6].map((i) => (
    <Card key={i} style={{ marginBottom: 1 }}>
      <Skeleton variant="rounded" />
    </Card>
  ));

const MessagesPage = () => {
  const [offset, setOffset] = useState(0);
  const history = useHistory();
  const { data, isLoading, isError, isSuccess } = useQuery(
    [MESSAGES, offset],
    () => getMessages({ offset }),
    {
      keepPreviousData: true,
    }
  );
  return (
    <Container maxWidth="md">
      <Box
        mb={3}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <PageTitle align="left">Messages</PageTitle>
        <Button variant="outlined" onClick={() => history.push(ACCOUNT_NOTIFICATIONS)}>
          <NotificationBox>
            <NotificationIcon />
            &nbsp;Notification Settings
          </NotificationBox>
        </Button>
      </Box>

      {isLoading && <MessagesSkeleton />}
      {isError && <Card>Unable To Load Messages At This Time.</Card>}
      {isSuccess && data && (
        <>
          <Messages list={data.messages} />
          {data.meta.total > MESSAGES_PER_PAGE && (
            <Box mt={2} mb={3} display="flex" justifyContent="center">
              <Pagination
                shape="rounded"
                sx={{ textAlign: 'center' }}
                limit={MESSAGES_PER_PAGE}
                page={offset / MESSAGES_PER_PAGE + 1}
                count={Math.ceil(data.meta.total / MESSAGES_PER_PAGE)}
                onChange={(e, next) => setOffset((next - 1) * MESSAGES_PER_PAGE)}
              />
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default MessagesPage;
